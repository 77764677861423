export default {
  "fullscreen-modal": "cDz",
  "creation-form-modal": "cDa",
  "links-container": "cDH",
  "link-field": "cDO",
  "link": "cDm",
  "add-link": "cDp",
  "remove-link": "cDx",
  "flex-row": "cDw",
  "flex-item": "cDN",
  "unit-trigger": "cDv",
  "dynamic-placeholders": "cDo"
};
