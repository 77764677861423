/* import __COLOCATED_TEMPLATE__ from './page-loading.hbs'; */
import Component from '@glimmer/component';

import { MemberPlaceholder } from 'qonto/react/components/member/placeholder';

interface PageLoadingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PageLoading extends Component<PageLoadingSignature> {
  memberPlaceholder = MemberPlaceholder;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Member::PageLoading': typeof PageLoading;
  }
}
