/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

// @ts-expect-error
import { BUCKET_KEY } from 'qonto/constants/in-context-integrations';

interface InContextIntegrationsTriggerSignature {
  // The arguments accepted by the component
  Args: {
    onInsert: () => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InContextIntegrationsTrigger extends Component<InContextIntegrationsTriggerSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];
  @service declare abilities: Services['abilities'];

  @tracked isSideDrawerRendered = Boolean(window.sessionStorage.getItem(BUCKET_KEY));
  @tracked isSideDrawerVisible = Boolean(window.sessionStorage.getItem(BUCKET_KEY));

  get canAccessConnect() {
    return this.abilities.can('navigate connect');
  }

  get tooltipPosition() {
    // @ts-expect-error
    return this.args.tooltipPosition ?? 'bottom center';
  }

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.args.onInsert?.();
  }

  @action
  hideSideDrawer() {
    this.isSideDrawerVisible = false;
    window.sessionStorage.removeItem(BUCKET_KEY);
    // @ts-expect-error
    this.segment.track('ICI_sidedrawer_closed', { page: this.args.sideDrawerId });
    // @ts-expect-error
    this.args.onHide?.();
  }

  @action
  displaySideDrawer() {
    this.isSideDrawerRendered = true;
    setTimeout(() => {
      this.isSideDrawerVisible = true;
      // @ts-expect-error
      this.segment.track('ICI_trigger_clicked', { page: this.args.sideDrawerId });
    }, 0);
    // @ts-expect-error
    this.args.onDisplay?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::Trigger': typeof InContextIntegrationsTrigger;
  }
}
