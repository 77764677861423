export default {
  "company-logo": "cPb",
  "list-item": "cPy",
  "link": "cPU",
  "title-wrapper": "cPj",
  "title": "cPW body-2",
  "subtitle": "cPu caption",
  "truncated-text": "clS",
  "image-placeholder": "clc"
};
