/* import __COLOCATED_TEMPLATE__ from './prismic-dom.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface PrismicDomSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PrismicDomComponent extends Component<PrismicDomSignature> {
  @service declare sentry: Services['sentry'];

  @action
  // @ts-expect-error
  onUnknownTag(node) {
    // @ts-expect-error
    this.sentry.withScope(scope => {
      scope.setExtra('node', node);
      this.sentry.captureMessage(`Prismic: unknown tag '${node.type}'`);
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PrismicDom: typeof PrismicDomComponent;
  }
}
