/* import __COLOCATED_TEMPLATE__ from './indicator.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface OverviewFiltersIndicatorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OverviewFiltersIndicatorComponent extends Component<OverviewFiltersIndicatorSignature> {
  get indicatorType() {
    // @ts-expect-error
    return this.args.data?.indicatorType || 'inflows';
  }

  @action
  // @ts-expect-error
  onSubmitAdvancedFilters(filters, period) {
    // @ts-expect-error
    this.args.close({ filters, period });
  }

  @action
  onClose() {
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Filters::Indicator': typeof OverviewFiltersIndicatorComponent;
  }
}
