/* import __COLOCATED_TEMPLATE__ from './membership-card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { BooleanValue } from 'qonto/react/components/organization-profile/boolean-value';
import { CountryValue } from 'qonto/react/components/organization-profile/country-value';
import { Value } from 'qonto/react/components/organization-profile/value';

interface MembershipCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class MembershipCard extends Component<MembershipCardSignature> {
  booleanValue = BooleanValue;
  countryValue = CountryValue;

  value = Value;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  get formattedBirth() {
    // @ts-expect-error
    let { birthCity, birthdate } = this.args.kycKybUpdateMembershipChangeRequest;
    return `${dayjs(birthdate).format('DD/MM/YYYY')}, ${birthCity}`;
  }

  get address() {
    // @ts-expect-error
    let ocr = this.args.kycKybUpdateMembershipChangeRequest;

    if (!ocr.addressCountry || !ocr.addressFirstLine || !ocr.addressZipCode || !ocr.addressCity) {
      return false;
    }

    // @ts-expect-error
    let country = this.intl.formatCountry(ocr.addressCountry);
    return `${ocr.addressFirstLine}, ${ocr.addressZipCode} ${ocr.addressCity} ${country}`;
  }

  get shouldShowTin() {
    // @ts-expect-error
    return this.args.kycKybUpdateMembershipChangeRequest.needsTaxNumber;
  }

  get shouldShowFiscalInformation() {
    // @ts-expect-error
    return this.args.kycKybUpdateMembershipChangeRequest.missingFiscalInformation;
  }

  handleDeleteMembershipTask = dropTask(async () => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('organizations.profile.company-profile.modal-remove.title', {
        representativeFullname:
          // @ts-expect-error
          this.args.kycKybUpdateMembershipChangeRequest.membership.get('fullName'),
      }),
      description: this.intl.t('organizations.profile.company-profile.modal-remove.subtitle'),
      cancel: this.intl.t('organizations.profile.company-profile.modal-remove.button.cancel'),
      confirm: this.intl.t('organizations.profile.company-profile.modal-remove.button.remove'),
    });

    // @ts-expect-error
    if (result === 'confirm') {
      let kycKybUpdateProcess =
        // @ts-expect-error
        await this.args.kycKybUpdateMembershipChangeRequest.get('kycKybUpdateProcess');

      try {
        // @ts-expect-error
        this.args.kycKybUpdateMembershipChangeRequest.corporateOfficer = false;
        // @ts-expect-error
        this.args.kycKybUpdateMembershipChangeRequest.ubo = false;
        // @ts-expect-error
        this.args.kycKybUpdateMembershipChangeRequest.legalRepresentative = false;
        await kycKybUpdateProcess.save().then(() =>
          // @ts-expect-error
          this.args.kycKybUpdateMembershipChangeRequest.save().then(() => {
            this.segment.track('kycb_update_delete_membership');

            this.toastFlashMessages.toastInfo(
              this.intl.t(
                'on-boarding.capital-deposit.shareholders.shareholder-delete.success-toast'
              )
            );
          })
        );
      } catch (error) {
        // @ts-expect-error
        if ([401, 403].includes(error.status)) {
          this.sentry.captureException(error);
        }
        // @ts-expect-error
        this.args.kycKybUpdateMembershipChangeRequest.rollbackAttributes();
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationProfile::MembershipCard': typeof MembershipCard;
  }
}
