export default {
  "header-cell": "cDW",
  "checkbox-cell": "cDu",
  "title-col": "cVS",
  "internal-note-col": "cVc",
  "unit-col": "cVq",
  "price-col": "cVZ",
  "header-content": "cVR caption-bold",
  "active": "cVQ",
  "empty": "cVe",
  "align-checkbox": "cVB"
};
