/* import __COLOCATED_TEMPLATE__ from './terms-and-conditions-fields.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TextAreaField } from '@repo/design-system-kit';

interface TermsAndConditionsFieldsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class TermsAndConditionsFieldsComponent extends Component<TermsAndConditionsFieldsSignature> {
  textAreaField = TextAreaField;

  @service declare segment: Services['segment'];

  @action
  // @ts-expect-error
  updateDiscountConditions(text) {
    // @ts-expect-error
    this.args.settings.discountConditions = text;
    this.segment.track('invoice-creation_additional-details_discount-conditions_edited');
  }

  @action
  // @ts-expect-error
  updateLatePaymentPenalties(text) {
    // @ts-expect-error
    this.args.settings.latePaymentPenalties = text;
    this.segment.track('invoice-creation_additional-details_late-payment-penalties_edited');
  }

  @action
  // @ts-expect-error
  updateLegalFixedCompensation(text) {
    // @ts-expect-error
    this.args.settings.legalFixedCompensation = text;
    this.segment.track('invoice-creation_additional-details_legal-fixed-compensation_edited');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::TermsAndConditionsFields': typeof TermsAndConditionsFieldsComponent;
  }
}
