/* import __COLOCATED_TEMPLATE__ from './internal-note-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaFieldCustomLabelBridge } from '@repo/design-system-kit';

interface InternalNoteFieldSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InternalNoteFieldComponent extends Component<InternalNoteFieldSignature> {
  textAreaFieldCustomLabelBridge = TextAreaFieldCustomLabelBridge;
  @tracked showInput = false;

  @action deleteInternalNote() {
    // @ts-expect-error
    this.args.model.internalNote = undefined;
    this.showInput = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Products::CreationFormModal::InternalNoteField': typeof InternalNoteFieldComponent;
  }
}
