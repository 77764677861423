export default {
  "wrapper": "cJx",
  "header": "cJw",
  "fadeIn": "cJN",
  "header-content": "cJv",
  "with-cta": "cJo",
  "header-title": "cJn title-4",
  "advanced-filters-trigger": "cJi",
  "filters-button": "cJA",
  "filters-button-upsell": "cJY",
  "filters-upsell-item": "cJh",
  "filters-badge": "cJf",
  "chart-container": "cJK",
  "chart-legend-container": "cJG mb-24",
  "chart-watermark-container": "cJr",
  "chart-watermark": "cJb",
  "placeholder-title": "cJy",
  "placeholder-subtitle": "cJU",
  "advanced-filters": "cJj"
};
