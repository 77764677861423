export default {
  "container": "cdR",
  "form-container": "cdQ",
  "title": "cde",
  "title-border": "cdB",
  "tabs-container": "cdE",
  "preview-container": "cdd",
  "email-preview-container": "cdI",
  "close-button": "cdt btn btn--icon-only btn--tertiary btn--large"
};
