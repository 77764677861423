/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { BUCKET_KEY } from 'qonto/constants/in-context-integrations';
import { InContextIntegrationsSideDrawerError } from 'qonto/react/components/in-context-integrations/side-drawer/error';
import { InContextIntegrationsSideDrawerFooter } from 'qonto/react/components/in-context-integrations/side-drawer/footer';
import { Header } from 'qonto/react/components/in-context-integrations/side-drawer/header';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import styles from './side-drawer.strict-module.css';

interface InContextIntegrationsSideDrawerSignature {
  // The arguments accepted by the component
  Args: {
    isVisible?: boolean;
    itemsFilter?: any;
    itemsSort?: (a: any, b: any) => number;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InContextIntegrationsSideDrawer extends Component<InContextIntegrationsSideDrawerSignature> {
  inContextIntegrationsSideDrawerError = InContextIntegrationsSideDrawerError;

  styles = styles;
  inContextIntegrationsSideDrawerFooter = InContextIntegrationsSideDrawerFooter;

  Header = Header;

  @service declare connectManager: Services['connectManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare userManager: Services['userManager'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];

  @tracked buckets = [];
  @tracked selectedBucketId = window.sessionStorage.getItem(BUCKET_KEY);
  @tracked integrations = [];
  @tracked query = '';

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  page = 1;
  hasMorePages = false;

  constructor(owner: unknown, args: InContextIntegrationsSideDrawerSignature['Args']) {
    super(owner, args);
    if (this.args.itemsFilter) {
      this.loadIntegrationsTask.perform().catch(() => {});
    } else {
      this.loadBucketsTask.perform().catch(() => {});
    }
  }

  get displayBackArrow() {
    return this.buckets.length > 1 && this.selectedBucket;
  }

  get selectedBucket() {
    // @ts-expect-error
    return this.buckets.find(bucket => bucket.id === this.selectedBucketId) || null;
  }

  get sortedIntegrations() {
    return this.args.itemsSort
      ? this.integrations.toSorted(this.args.itemsSort)
      : this.integrations;
  }

  loadBucketsTask = dropTask(async () => {
    try {
      let response = await this.connectManager.getIntegrationBuckets({
        // @ts-expect-error
        location: this.args.sideDrawerId,
      });

      this.buckets = variation('feature--boolean-qontent-connect-hub')
        ? response
        : response.results;

      if (this.buckets.length === 1) {
        this.selectedBucketId = response.results?.[0]?.id ?? response?.[0]?.id;
      }

      if (this.selectedBucketId) {
        await this.loadIntegrationsTask.linked().perform();
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
  });

  loadIntegrationsTask = dropTask(async () => {
    try {
      let params = {
        // @ts-expect-error
        ...(this.args.itemsFilter || { bucketId: this.selectedBucket.id }),
        ...(this.query ? { query: this.query } : {}),
        page: this.page,
      };

      let response = await this.connectManager.getIntegrations(params);

      this.hasMorePages =
        (variation('feature--boolean-qontent-connect-hub')
          ? response.meta.total_pages
          : response.total_pages) > this.page;

      // @ts-expect-error
      this.integrations = [
        ...this.integrations,
        ...(variation('feature--boolean-qontent-connect-hub')
          ? // @ts-expect-error
            response.connect_integrations.map(integration => ({ integration }))
          : // @ts-expect-error
            response.results.map(integration => ({ integration: integration.data }))),
      ];
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
  });

  onLoadMoreTask = dropTask(async () => {
    if (!this.hasMorePages) return;

    this.page += 1;

    await this.loadIntegrationsTask.linked().perform();
  });

  onSearchTask = dropTask(async query => {
    this.page = 1;
    this.integrations = [];
    this.query = query;

    await this.loadIntegrationsTask.linked().perform();
  });

  @action
  back() {
    this.selectedBucketId = null;
    this.integrations = [];
    this.page = 1;

    window.sessionStorage.removeItem(BUCKET_KEY);

    this.segment.track('ICI_previous_clicked', {
      // @ts-expect-error
      page: this.args.sideDrawerId,
    });
  }

  @action
  // @ts-expect-error
  showIntegrations(bucket) {
    this.selectedBucketId = bucket.id;

    window.sessionStorage.setItem(BUCKET_KEY, bucket.id);

    this.loadIntegrationsTask.perform().catch(ignoreCancelation);

    this.segment.track('ICI_bucket_clicked', {
      // @ts-expect-error
      page: this.args.sideDrawerId,
      bucket: bucket.title,
    });
  }

  @action
  onClose() {
    if (this.buckets.length !== 1 && !this.args.itemsFilter) {
      this.selectedBucketId = null;
      this.integrations = [];
    }

    window.sessionStorage.removeItem(BUCKET_KEY);

    // @ts-expect-error
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::SideDrawer': typeof InContextIntegrationsSideDrawer;
  }
}
