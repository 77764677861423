/* import __COLOCATED_TEMPLATE__ from './new-badge.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface NewBadgeSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const NewBadge = templateOnlyComponent<NewBadgeSignature>();

export default NewBadge;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    NewBadge: typeof NewBadge;
  }
}
