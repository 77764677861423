/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface PowerSelectAccountsOptionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PowerSelectAccountsOption extends Component<PowerSelectAccountsOptionSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Accounts::Option': typeof PowerSelectAccountsOption;
  }
}
