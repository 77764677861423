/* import __COLOCATED_TEMPLATE__ from './numbering-category.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import {
  getNextInvoiceNumberPreview,
  getNextQuoteNumberPreview,
} from 'qonto/utils/invoices/preview';

interface InvoiceSettingsFormNumberingCategorySignature {
  // The arguments accepted by the component
  Args: {
    isManualNumberingDisabled: boolean;
    scrollToSelectorContent: {
      elementId: string;
      offsetTop: number;
    };
    settings: any;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsFormNumberingCategoryComponent extends Component<InvoiceSettingsFormNumberingCategorySignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get settings() {
    return this.args.settings;
  }

  get numberingMode() {
    return this.settings?.numberingMode;
  }

  get nextInvoiceNumber() {
    return getNextInvoiceNumberPreview(this.settings);
  }

  get nextQuoteNumber() {
    return getNextQuoteNumberPreview(this.settings);
  }

  get tooltipMessage() {
    if (this.args.isManualNumberingDisabled) {
      return this.intl.t(
        'receivable-invoices.settings-modal.numbering-settings.deactivated-tooltip'
      );
    }
  }

  @action
  handleNumberingUpdate() {
    this.settings.numberingMode = this.numberingMode === 'automatic' ? 'manual' : 'automatic';
    this.segment.track('invoice_number-settings_changed', { method: this.numberingMode });
  }

  @action
  updateInvoiceNumberingPattern(pattern: string) {
    this.args.settings.invoiceNumberingPattern = pattern;
    this.args.settings.invoiceNextNumberFormatted = this.nextInvoiceNumber;
  }

  @action
  updateInvoiceNextNumber(number: string) {
    this.settings.invoiceNextNumber = number;
    this.settings.invoiceNextNumber = number?.replace(/[^0-9]/g, '');
    this.settings.invoiceNextNumberFormatted = this.nextInvoiceNumber;
  }

  @action
  updateQuoteNumberingPattern(pattern: string) {
    this.settings.quoteNumberingPattern = pattern;
    this.settings.quoteNextNumberFormatted = this.nextQuoteNumber;
  }

  @action
  updateQuoteNextNumber(number: string) {
    this.settings.quoteNextNumber = number;
    this.settings.quoteNextNumber = number?.replace(/[^0-9]/g, '');
    this.settings.quoteNextNumberFormatted = this.nextQuoteNumber;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::NumberingCategory': typeof InvoiceSettingsFormNumberingCategoryComponent;
  }
}
