/* import __COLOCATED_TEMPLATE__ from './attachment-actions.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge, Button } from '@repo/design-system-kit';

import {
  type Feature,
  FEATURES,
} from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';

interface InvoiceSettingsFormTermsAndConditionsAttachmentActionsSignature {
  // The arguments accepted by the component
  Args: {
    settings: any;
    showPopup: (feature: Feature) => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsFormTermsAndConditionsAttachmentActionsComponent extends Component<InvoiceSettingsFormTermsAndConditionsAttachmentActionsSignature> {
  @service
  declare segment: Services['segment'];
  @service
  declare accountReceivableDocumentsUploadManager: Services['accountReceivableDocumentsUploadManager'];

  badge = Badge;
  button = Button;

  @action
  showAttachmentsPopup() {
    this.segment.track('invoice-attachment_add', { availability: 'not-available' });
    this.args.showPopup(FEATURES.ATTACHMENTS);
  }

  @action
  trackEvent() {
    this.segment.track('invoice-attachment_add', { availability: 'included' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::TermsAndConditions::AttachmentActions': typeof InvoiceSettingsFormTermsAndConditionsAttachmentActionsComponent;
  }
}
