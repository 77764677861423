export default {
  "wrapper": "csl",
  "header": "csX",
  "fadeIn": "csC",
  "header-title": "csk title-4",
  "transactions-container": "csJ",
  "placeholder-title": "css",
  "transaction-item-list-container": "csF",
  "transaction-item-list": "csg",
  "item": "csD"
};
