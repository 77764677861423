/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface InvoicesTableHeaderSignature {
  // The arguments accepted by the component
  Args: {
    isSelected?: boolean;
    isIndeterminate?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class InvoicesTableHeaderComponent extends Component<InvoicesTableHeaderSignature> {
  checkbox = Checkbox;

  onExportTask = dropTask(async () => {
    // @ts-expect-error
    await this.args.exportSelectedInvoicesTask.perform(this.args.selectedItemIds);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Invoices::Table::Header': typeof InvoicesTableHeaderComponent;
  }
}
