/* import __COLOCATED_TEMPLATE__ from './mileage-calculation-details.hbs'; */
import Component from '@glimmer/component';

import { MileageCalculationDetails } from 'qonto/react/mileage/components/modals/mileage-calculation-details';

interface MileageModalsMileageCalculationDetailsSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class MileageModalsMileageCalculationDetails extends Component<MileageModalsMileageCalculationDetailsSignature> {
  MileageCalculationDetails = MileageCalculationDetails;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mileage::Modals::MileageCalculationDetails': typeof MileageModalsMileageCalculationDetails;
  }
}
