export default {
  "mandate-list-item": "ctO",
  "selected": "ctm",
  "status-avatar": "ctp",
  "infos": "ctx",
  "info-title": "ctw body-1",
  "info-title-suspended": "ctN",
  "info-title-status-suspended": "ctv",
  "info-mandate-count": "cto body-2"
};
