export default {
  "sidebar-section": "ceY body-2",
  "sidebar-section-header": "ceh body-1 mb-4",
  "providers-logos": "cef",
  "risks": "ceK",
  "tag": "ceG tag",
  "external-link-icon": "cer",
  "disclaimer": "ceb",
  "divider": "cey"
};
