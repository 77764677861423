/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DateField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

// @ts-expect-error
import styles from './user-info.module.css';

const MEMBERSHIP_FIELDS = ['nationality'];

const USER_FIELDS = ['gender', 'birthCountry', 'birthCity', 'phoneNumber', 'birthdate'];

interface KYCUserInfoControllerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class KYCUserInfoController extends Component<KYCUserInfoControllerSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare userManager: Services['userManager'];
  @service declare sentry: Services['sentry'];

  @tracked enableValidation = false;

  dateField = DateField;

  styles = styles;

  get invite() {
    // @ts-expect-error
    return this.args.invite;
  }

  get currentUser() {
    return this.userManager.currentUser;
  }

  get membershipBirthday() {
    // @ts-expect-error
    let birthdate = this.currentUser?.birthdate;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  confirmTask = dropTask(async () => {
    let { validations: membershipValidation } = await this.invite.validate({
      on: MEMBERSHIP_FIELDS,
    });

    // @ts-expect-error
    let { validations: userValidation } = await this.currentUser.validate({
      on: USER_FIELDS,
    });

    if (membershipValidation.isInvalid || userValidation.isInvalid) {
      this.enableValidation = true;
      return;
    }

    try {
      // @ts-expect-error
      await this.currentUser.save();
      await this.invite.reload();

      // @ts-expect-error
      this.args.onSubmit();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      // @ts-expect-error
      if (errorInfo.shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  // @ts-expect-error
  updateBirthdate(value) {
    if (value) {
      // @ts-expect-error
      this.currentUser.birthdate = value.toString();
    }
  }

  @action
  // @ts-expect-error
  handleGenderSelection(gender) {
    // @ts-expect-error
    this.currentUser.gender = gender;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvitationsNewUser::UserInfo': typeof KYCUserInfoController;
  }
}
