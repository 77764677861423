export default {
  "bullet": "cks",
  "slice-0": "ckF",
  "slice-1": "ckg",
  "slice-2": "ckD",
  "slice-3": "ckV",
  "slice-4": "ckL",
  "slice-5": "ckT",
  "slice-6": "ckz",
  "slice-other": "cka",
  "slice-uncategorized": "ckH",
  "label": "ckO",
  "ellipsis": "ckm",
  "rest": "ckp",
  "previous-date-range": "ckx caption"
};
