/* import __COLOCATED_TEMPLATE__ from './mandate-list-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MandateListItemSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const MandateListItem = templateOnlyComponent<MandateListItemSignature>();

export default MandateListItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MandateListItem: typeof MandateListItem;
  }
}
