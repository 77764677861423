export default {
  "widgets-container": "ckj",
  "subtitle-container": "ckW",
  "indicator-container": "cku",
  "transactions-container": "cJS",
  "indicator": "cJc",
  "cashflow-container": "cJq",
  "cashflow": "cJZ",
  "donut-container": "cJR",
  "donut": "cJQ"
};
