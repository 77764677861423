/* import __COLOCATED_TEMPLATE__ from './appearance-category.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';

// @ts-expect-error
import TEMPLATE_VALUES from 'qonto/constants/env-helper';

interface InvoiceSettingsFormAppearanceCategorySignature {
  // The arguments accepted by the component
  Args: {
    settings: any;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsFormAppearanceCategoryComponent extends Component<InvoiceSettingsFormAppearanceCategorySignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  // @ts-expect-error
  @tracked formattedMaxSize = formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);

  get settings() {
    return this.args.settings;
  }

  get organization() {
    return this.organizationManager.organization;
  }

  /**
   * `organization.isDefaultAvatar` can be `null` when the avatar wasn't loaded or failed to load
   * because of this all checks should be strict
   */
  get hasDefaultAvatar() {
    return this.organization.isDefaultAvatar ?? true;
  }

  @action
  handleDropAvatar() {
    this.organization.reload();
    this.organization.set('avatar', null);
    this.organization.set('isDefaultAvatar', true);
    this.segment.track('company_logo_delete', { origin: 'invoice_creation' });
  }

  @action
  // @ts-expect-error
  handleAvatarUpload(file) {
    this.segment.track('company_logo_upload_clicked');
    this.organization.reload();
    this.organization.set('avatar', file);
    this.organization.set('isDefaultAvatar', false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::AppearanceCategory': typeof InvoiceSettingsFormAppearanceCategoryComponent;
  }
}
