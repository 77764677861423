/* import __COLOCATED_TEMPLATE__ from './link-to-flow.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface LinkToFlowSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class LinkToFlowComponent extends Component<LinkToFlowSignature> {
  @service declare flow: Services['flow'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare organizationManager: Services['organizationManager'];

  get initialStepId() {
    // @ts-expect-error
    let { name, stepId } = this.args;
    return stepId ?? this.flowLinkManager.initialStepIdFor(name);
  }

  get organizationSlug() {
    return this.organizationManager.organization.slug;
  }

  get query() {
    // @ts-expect-error
    return this.args.query ?? {};
  }

  @action
  clearSessionStorage() {
    this.flow.clearStorage();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LinkToFlow: typeof LinkToFlowComponent;
  }
}
