/* import __COLOCATED_TEMPLATE__ from './confirmation-dialog.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface OrganizationProfileConfirmationDialogSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OrganizationProfileConfirmationDialogComponent extends Component<OrganizationProfileConfirmationDialogSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationProfile::ConfirmationDialog': typeof OrganizationProfileConfirmationDialogComponent;
  }
}
