/* import __COLOCATED_TEMPLATE__ from './related-invoice.hbs'; */
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { INVOICING_STATUS_COLORS } from 'qonto/constants/receivable-invoice';

interface RelatedInvoiceSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class RelatedInvoiceComponent extends Component<RelatedInvoiceSignature> {
  tag = Tag;

  get status() {
    // @ts-expect-error
    let { status, displayedStatus } = this.args.document;
    let statusColors = INVOICING_STATUS_COLORS;

    return {
      displayedStatus,
      // @ts-expect-error
      color: statusColors[status],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::RelatedInvoice': typeof RelatedInvoiceComponent;
  }
}
