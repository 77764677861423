export default {
  "y-axis-placeholder": "cXA",
  "y-axis-navigable": "cXY",
  "y-axis-tick": "cXh",
  "x-axis-placeholder": "cXf",
  "x-axis-navigable": "cXK",
  "x-axis-tick-wrapper": "cXG",
  "x-axis-tick": "cXr",
  "chart-loading-renderer": "cXb"
};
