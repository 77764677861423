/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import {
  FACILE_INSURANCE_PRODUCT_LEGAL_TYPES,
  FACILE_INSURANCE_PRODUCT_LEGAL_TYPES_SUPERSCRIPT,
  // @ts-expect-error - file not yet migrated
} from 'qonto/constants/insurance-hub';

interface FooterSignature {
  // The arguments accepted by the component
  Args: {
    products?: any[];
    includeLegalNotice?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceHubFooterComponent extends Component<FooterSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  get organizationLegalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  get productIds() {
    // Returns the product ids as an array
    return (
      this.args.products?.reduce((accumulator, current) => [...accumulator, current.uid], []) ?? []
    );
  }

  get legalNotices() {
    let legalNotices: { text: string; superscript: number }[] = [];

    this.productIds.forEach((productId: string) => {
      switch (productId) {
        case FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.MULTI_RISK_FOR_PROFESSIONAL:
          legalNotices.push({
            text: this.intl.t('insurance-hub.footer.legal-notice-it.multi-risk-for-professional'),
            superscript: FACILE_INSURANCE_PRODUCT_LEGAL_TYPES_SUPERSCRIPT[productId],
          });
          break;
        case FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.INJURIES:
          legalNotices.push({
            text: this.intl.t('insurance-hub.footer.legal-notice-it.personal-protection'),
            superscript: FACILE_INSURANCE_PRODUCT_LEGAL_TYPES_SUPERSCRIPT[productId],
          });
          break;
        default:
          break;
      }
    });

    return legalNotices;
  }

  get showLegalNotice() {
    return (
      this.args.includeLegalNotice &&
      this.organizationLegalCountry === 'IT' &&
      Boolean(this.legalNotices.length)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Footer': typeof InsuranceHubFooterComponent;
  }
}
