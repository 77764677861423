export default {
  "header": "cdz",
  "center": "cda",
  "header-placeholder": "cdH",
  "placeholder": "cdO",
  "name-block": "cdm",
  "block": "cdp",
  "detail-block": "cdx",
  "border-top": "cdw"
};
