/* import __COLOCATED_TEMPLATE__ from './mandate-upload-preview-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MandateUploadPreviewModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const MandateUploadPreviewModal = templateOnlyComponent<MandateUploadPreviewModalSignature>();

export default MandateUploadPreviewModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MandateUploadPreviewModal: typeof MandateUploadPreviewModal;
  }
}
