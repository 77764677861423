/* import __COLOCATED_TEMPLATE__ from './org-list.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { companyCreationJsURL, registerJsURL, registerPartnersJsURL } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
// @ts-expect-error
import { sortByKey } from 'qonto/utils/sort-by-keys';

interface ListOrgaSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLUListElement;
}

export default class ListOrgaComponent extends Component<ListOrgaSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare userManager: Services['userManager'];
  @service declare store: Services['store'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  placeholderLine = SkeletonLoader.Line;

  companyCreationJsURL = companyCreationJsURL;
  registerPartnersJsURL = registerPartnersJsURL;
  registerJsURL = registerJsURL;

  // @ts-expect-error
  @reads('organizationManager.organization') currentOrganization;

  get companyCreationOrganizations() {
    let creationOrganizations = this.organizationManager.companyCreationOrganizations;
    if (creationOrganizations) {
      return [...creationOrganizations].sort(sortByKey('name'));
    }
  }

  get partnerOrganizations() {
    // @ts-expect-error
    let partnerOrganizations = this.organizationManager.partnerOrganizations;
    if (partnerOrganizations) {
      return [...partnerOrganizations].sort(sortByKey('legalName'));
    }
  }

  get registeringOrganizations() {
    // these are not an EmberArray since they're loaded through the network-manager
    let { registeringOrganizations } = this.organizationManager;
    if (registeringOrganizations) {
      return [...registeringOrganizations].sort(sortByKey('name'));
    }
  }

  get organizations() {
    return (
      // @ts-expect-error
      this.organizationManager.organizations
        // @ts-expect-error
        .filter(organization => {
          let isReportingRole = organization.membershipRole === ROLES.REPORTING;

          // @ts-expect-error
          switch (this.args.accountantHubMode) {
            case 'include':
              return isReportingRole;
            case 'exclude':
              return !isReportingRole;
            default:
              return true;
          }
        })
        // @ts-expect-error
        .sort((orgA, orgB) => orgA.name.localeCompare(orgB.name))
    );
  }

  @action
  trackOrgClick() {
    let origin = this.router.currentRouteName.includes('accounting-hub')
      ? 'accounting-hub'
      : 'organizations';
    this.segment.track('switch_organization', { origin });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NavigationDropdown::OrgList': typeof ListOrgaComponent;
  }
}
