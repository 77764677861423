/* import __COLOCATED_TEMPLATE__ from './dropdown-annual-turnover.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface DropdownAnnualTurnoverSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DropdownAnnualTurnover extends Component<DropdownAnnualTurnoverSignature> {
  @service declare annualTurnover: Services['annualTurnover'];
  @service declare intl: Services['intl'];

  get selectedValue() {
    // @ts-expect-error
    let code = this.args.organizationChangeRequest.annualTurnover;

    if (code) {
      let name = this.annualTurnoverOptionsTranslations[code];
      return { code, name };
    }
    return null;
  }

  get annualTurnoverOptionsTranslations() {
    let {
      options: [option1, option2, option3, option4, option5],
      translations: [translation1, translation2, translation3, translation4, translation5],
    } = this.annualTurnover.getAnnualTurnover(
      // @ts-expect-error
      this.args.organizationChangeRequest.kycKybUpdateProcess
        .get('organization')
        .get('legalCountry')
    );

    return {
      [option1]: translation1,
      [option2]: translation2,
      [option3]: translation3,
      [option4]: translation4,
      [option5]: translation5,
    };
  }

  get options() {
    return (
      this.annualTurnover
        .getAnnualTurnover(
          // @ts-expect-error
          this.args.organizationChangeRequest.kycKybUpdateProcess
            .get('organization')
            .get('legalCountry')
        )
        // @ts-expect-error
        .options.map(code => ({
          code,
          name: this.annualTurnoverOptionsTranslations[code],
        }))
    );
  }

  @action
  // @ts-expect-error
  handleUpdate(annualTurnoverObject) {
    // @ts-expect-error
    this.args.organizationChangeRequest.annualTurnover = annualTurnoverObject.code;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationProfile::DropdownAnnualTurnover': typeof DropdownAnnualTurnover;
  }
}
