/* import __COLOCATED_TEMPLATE__ from './cashflow.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import buildQuery from 'qonto/utils/statistics';

interface OverviewStatisticsCashflowSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewStatisticsCashflowComponent extends Component<OverviewStatisticsCashflowSignature> {
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  @tracked cashflows = [];

  get isLoading() {
    return this.queryCashflowsTask.isRunning && this.queryCashflowsTask.performCount === 1;
  }

  get isUpdating() {
    return this.queryCashflowsTask.isRunning && this.queryCashflowsTask.performCount > 1;
  }

  get isError() {
    return this.getCashflowTask.last?.isError;
  }

  queryCashflowsTask = dropTask(
    async (aggregations, period, side, filters, bankAccounts, comparisonPeriod) => {
      try {
        await this.getCashflowTask
          .linked()
          .perform(aggregations, period, side, filters, bankAccounts, comparisonPeriod);
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(errorInfo.error);
        }
      }
    }
  );

  getCashflowTask = dropTask(
    async (aggregations, period, side, filters, bankAccounts, comparisonPeriod) => {
      let promises = [this._getPeriodCashflow(aggregations, period, side, filters, bankAccounts)];

      if (comparisonPeriod) {
        promises.push(
          this._getPeriodCashflow(aggregations, comparisonPeriod, side, filters, bankAccounts)
        );
      }

      // @ts-expect-error
      this.cashflows = await all(promises);
    }
  );

  // @ts-expect-error
  _getPeriodCashflow(aggregations, period, side, filters, bankAccounts) {
    let isPeriod = period.interval || period.startDate || period.endDate;

    if (isPeriod) {
      let organizationId = this.organizationManager.organization.id;
      let query = buildQuery(organizationId, aggregations, period, side, bankAccounts, filters);

      // @ts-expect-error
      return this.store.adapterFor('transaction').getCashflow(query);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Statistics::Cashflow': typeof OverviewStatisticsCashflowComponent;
  }
}
