export default {
  "container": "cBQ",
  "header": "cBe",
  "content": "cBB",
  "info-list": "cBE",
  "info-label": "cBd body-2",
  "info-value": "cBI body-2",
  "provider": "cBt",
  "policy-container": "cBM",
  "policy-label": "cBP body-2",
  "policy-number": "cBl body-2",
  "copy-to-clipboard": "cBX",
  "button": "cBC btn btn--primary btn--stretch btn--large"
};
