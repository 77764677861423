/* import __COLOCATED_TEMPLATE__ from './invoices-receivable.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask } from 'ember-concurrency';

import { SORT_FILTERS, STATUS } from 'qonto/constants/receivable-invoice';
import { WidgetInvoices } from 'qonto/react/components/widget/overview/invoices';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface OverviewWidgetsInvoicesReceivableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewWidgetsInvoicesReceivable extends Component<OverviewWidgetsInvoicesReceivableSignature> {
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];

  widgetInvoices = WidgetInvoices;

  constructor(owner: unknown, args: OverviewWidgetsInvoicesReceivableSignature['Args']) {
    super(owner, args);
    this.fetchReceivableInvoicesTask.perform().catch(ignoreCancelation);
  }

  fetchReceivableInvoicesTask = restartableTask(async () => {
    try {
      // @ts-expect-error
      let response = await this.store.adapterFor('receivable-invoice').search({
        pagination: {
          page: 1,
          per_page: 3,
        },
        filters: [
          {
            field: 'status',
            value: [STATUS.UNPAID],
            operator: 'eq',
          },
        ],
        sorts: [
          {
            field: SORT_FILTERS.DUE_DATE,
            order: 'asc',
          },
        ],
      });

      return response.invoices;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      throw error;
    }
  });

  @action
  reload() {
    this.fetchReceivableInvoicesTask.perform().catch(ignoreCancelation);
  }

  get isError() {
    return this.fetchReceivableInvoicesTask.last?.isError;
  }

  get isLoading() {
    return this.fetchReceivableInvoicesTask.isRunning;
  }

  get invoices() {
    return this.fetchReceivableInvoicesTask.lastSuccessful?.value ?? [];
  }
}
