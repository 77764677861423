/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';

import { receivableInvoicesURL } from 'qonto/constants/hosts';
import { Share } from 'qonto/react/payment-links/components/payment-links/sidebar/share';

interface SidebarSignature {
  Element: HTMLElement;
  Args: {
    isResourceLoading?: boolean;
    paymentLink: {
      paymentInitiator: {
        resourceId: string;
      };
    };
  };
  Blocks: {
    default: [];
  };
}

export default class PaymentLinksSidebarComponent extends Component<SidebarSignature> {
  share = Share;

  get shareableLink() {
    return `${receivableInvoicesURL}/invoices/${this.args.paymentLink.paymentInitiator.resourceId}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Sidebar': typeof PaymentLinksSidebarComponent;
  }
}
