/* import __COLOCATED_TEMPLATE__ from './document-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

interface InvitationsNewUserDocumentSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvitationsNewUserDocumentSelection extends Component<InvitationsNewUserDocumentSelectionSignature> {
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;

  @action
  // @ts-expect-error
  onClick(documentType) {
    this.segment.track('join_team_information_poi_type_chosen', {
      poi_type: documentType,
    });
    // @ts-expect-error
    this.args.onSelect(documentType);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvitationsNewUser::DocumentSelection': typeof InvitationsNewUserDocumentSelection;
  }
}
