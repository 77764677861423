/* import __COLOCATED_TEMPLATE__ from './power-select-custom-trigger.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PowerSelectCustomTriggerSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PowerSelectCustomTrigger = templateOnlyComponent<PowerSelectCustomTriggerSignature>();

export default PowerSelectCustomTrigger;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PowerSelectCustomTrigger: typeof PowerSelectCustomTrigger;
  }
}
