export default {
  "account-container": "csh",
  "badge": "csf",
  "subtitle": "csK title-4",
  "accounts-filter": "csG",
  "account-filter-label": "csr",
  "status": "csb",
  "account-info": "csy",
  "balance": "csU",
  "revealed-balance": "csj",
  "hidden-balance": "csW",
  "mr-12": "csu",
  "disabled-selector": "cFS"
};
