export default {
  "fieldset": "cEp",
  "numbering-format": "cEx",
  "numbering-fields": "cEw",
  "input-settings": "cEN",
  "label-tooltip": "cEv",
  "tooltip-icon": "cEo",
  "next-invoice-number": "cEn",
  "spacing": "cEi"
};
