/* import __COLOCATED_TEMPLATE__ from './transactions.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface OverviewWidgetsTransactionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewWidgetsTransactionsComponent extends Component<OverviewWidgetsTransactionsSignature> {
  @service declare sentry: Services['sentry'];

  // This task exists only for error handling purposes
  transactionsTask = restartableTask(async () => {
    try {
      // @ts-expect-error
      return await this.args.transactionsTask.linked().perform();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get hasTransactions() {
    // @ts-expect-error
    return this.args.transactionsTask.lastSuccessful?.value?.transactions?.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::Transactions': typeof OverviewWidgetsTransactionsComponent;
  }
}
