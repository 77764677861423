export default {
  "section-item": "ckq",
  "sm": "ckZ",
  "xs": "ckR",
  "section-item-label": "ckQ body-2",
  "section-item-value": "cke body-2",
  "positive": "ckB",
  "negative": "ckE",
  "hidden": "ckd",
  "section-item-caption": "ckI caption"
};
