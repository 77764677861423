/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, SkeletonLoader } from '@repo/design-system-kit';

interface OverviewWidgetsLayoutSignature {
  // The arguments accepted by the component
  Args: {
    isAdvancedFiltersMode?: boolean;
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewWidgetsLayoutComponent extends Component<OverviewWidgetsLayoutSignature> {
  badgeHighlight = Badge;

  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  placeholderLine = SkeletonLoader.Line;

  elementId = guidFor(this);

  @tracked disabledOperatorProperties = [
    'activity_tag',
    'operation_method',
    'attachment_status',
    'vat_completeness',
  ];
  @tracked excludedProperties = [
    'note',
    'status',
    'qualified_for_accounting',
    'emitted_at',
    'updated_at',
    'settled_at',
    // @ts-expect-error
    ...(this.abilities.can('assign category') ? ['category_assignment_category_id'] : []),
  ];

  get formData() {
    // @ts-expect-error
    return this.args.advancedFilters
      ? // @ts-expect-error
        { id: this.elementId, query: { filter_group: this.args.advancedFilters } }
      : undefined;
  }

  get showFiltersButton() {
    return (
      // @ts-expect-error
      this.args.onAdvancedFilters &&
      !this.args.isAdvancedFiltersMode &&
      this.abilities.can('access or update overview')
    );
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.findTrial('advanced_dashboard');
  }

  @action onTrialClick() {
    if (this.trialInfo) {
      this.upsellManager.transitionToTrialLineup(this.trialInfo);
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
        queryParams: { upsellTrigger: 'advancedDashboard' },
      });
    }

    this.segment.track('upsell_dashboard_clicked', {
      // @ts-expect-error
      widget_type: this.args.trackingWidgetType,
      upsell_type: 'advanced_filters',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::Layout': typeof OverviewWidgetsLayoutComponent;
  }
}
