/* import __COLOCATED_TEMPLATE__ from './secure-account.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface PopupSecuritySecureAccountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PopupSecuritySecureAccountComponent extends Component<PopupSecuritySecureAccountSignature> {
  @service declare userManager: Services['userManager'];
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: PopupSecuritySecureAccountSignature['Args']) {
    super(owner, args);
    this.segment.track('profile_email-update_information_displayed');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Popup::Security::UpdateEmail::SecureAccount': typeof PopupSecuritySecureAccountComponent;
  }
}
