/* import __COLOCATED_TEMPLATE__ from './indicator.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { reads } from 'macro-decorators';

import { validatePeriod } from 'qonto/components/overview/chart/period-selector/custom-period/date-picker';
// @ts-expect-error
import { PERIOD_KEYS } from 'qonto/constants/overview';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { getCustomPeriodSamplingRate, IndicatorTimevalue, utcToZonedDate } from 'qonto/utils/chart';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const CASHFLOW_TYPES = ['inflows', 'outflows'];
const BALANCE_TYPES = ['balance', 'net-change'];
const INDICATOR_TYPES = [...BALANCE_TYPES, ...CASHFLOW_TYPES];

const WIDGET_TRACKING_EVENTS = {
  balance: 'balance_indicator',
  'net-change': 'change_in_cash_indicator',
  inflows: 'inflow_indicator',
  outflows: 'outflow_indicator',
};

interface OverviewWidgetsIndicatorSignature {
  // The arguments accepted by the component
  Args: {
    isError?: boolean;
    isLoading?: boolean;
    isUpdating?: boolean;
    isAdvancedFiltersMode?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OverviewWidgetsIndicatorComponent extends Component<OverviewWidgetsIndicatorSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare userManager: Services['userManager'];

  // @ts-expect-error
  @reads('organizationManager.membership.id') membershipId;
  // @ts-expect-error
  @reads('userManager.currentUser.timezone') timezone;

  // @ts-expect-error
  @tracked _advancedFilters;
  // @ts-expect-error
  @tracked _selectedPeriod;
  didComponentMount = false;

  // @ts-expect-error
  periodStorageKey = `${this.args.type}-indicator-period-${this.membershipId}`;
  // @ts-expect-error
  filtersStorageKey = `${this.args.type}-indicator-filters-${this.membershipId}`;
  periods = [
    {
      interval: 'current_month',
      // @ts-expect-error
      label: this.intl.t('overview.month-to-date'),
      key: 'month_to_date',
      startDate: dayjs().startOf('month').toDate(),
      endDate: dayjs().endOf('day').toDate(),
    },
    {
      interval: 'current_quarter',
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.quarter-to-date'),
      key: 'quarter_to_date',
      startDate: dayjs().startOf('quarter').toDate(),
      endDate: dayjs().endOf('day').toDate(),
    },
    {
      interval: 'current_year',
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.year-to-date'),
      key: 'year_to_date',
      startDate: dayjs().startOf('year').toDate(),
      endDate: dayjs().endOf('day').toDate(),
    },
    {
      interval: 'last_30_days',
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.last-thirty-days'),
      rate: 'daily',
    },
    {
      interval: 'last_3_months',
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.last-three-months'),
      rate: 'weekly',
    },
    {
      interval: 'last_12_months',
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.last-twelve-months'),
      rate: 'monthly',
    },
  ];

  // @ts-expect-error
  trackingWidgetType = WIDGET_TRACKING_EVENTS[this.args.type];

  constructor(owner: unknown, args: OverviewWidgetsIndicatorSignature['Args']) {
    super(owner, args);

    assert(
      'OverviewWidgetsIndicatorComponent requires a valid type',
      // @ts-expect-error
      INDICATOR_TYPES.includes(args.type)
    );

    this.initPersistedSettings();

    // Unless scheduled at least to the `actions` queue, this would raise an
    // `autotracking.mutation-after-consumption` deprecation, which is an error
    // in Ember 4 and above.
    schedule('actions', () => {
      if (!this.args.isError) {
        // @ts-expect-error
        this.selectPeriod(this.selectedPeriod);
      }
    });
  }

  get isCashflowType() {
    // @ts-expect-error
    return CASHFLOW_TYPES.includes(this.args.type);
  }

  get cannotAccessAdvancedOverview() {
    return this.abilities.cannot('access advanced overview');
  }

  get originDate() {
    return this.organizationManager.organization.createdAt;
  }

  get rate() {
    return this.selectedPeriod.rate || getCustomPeriodSamplingRate(this.selectedPeriod);
  }

  get advancedFilters() {
    // @ts-expect-error
    return this.args.advancedFilters || this._advancedFilters;
  }

  get bankAccounts() {
    // @ts-expect-error
    return this.args.bankAccounts ? [this.args.bankAccounts] : undefined;
  }

  get aggregations() {
    let property = 'display_at';
    let interval = this.rate;

    return [
      {
        name: `${property}_${interval}`,
        type: 'time',
        data: {
          property,
          interval,
        },
      },
    ];
  }

  get statistics() {
    // @ts-expect-error
    let { name } = this.aggregations[0];
    // @ts-expect-error
    let statistics = this.args.statistics;

    return statistics?.data && statistics.data[name];
  }

  get timeseries() {
    if (!(this.args.isLoading || this.args.isUpdating)) {
      return this.statistics?.map(
        // @ts-expect-error
        timevalue => new IndicatorTimevalue(timevalue, this.args.type, this.timezone)
      );
    }
  }

  get selectedPeriod() {
    if (this._selectedPeriod) {
      return this._selectedPeriod;
      // @ts-expect-error
    } else if (this.persistedPeriod) {
      // @ts-expect-error
      return this._lookupPeriod(this.persistedPeriod);
    }

    return this.periods[0];
  }

  // @ts-expect-error
  get bounds() {
    // @ts-expect-error
    let bounds = this.args.statistics?.meta?.bounds;
    if (bounds) {
      let { min, max } = bounds;

      return {
        min: utcToZonedDate(min, this.timezone),
        max: utcToZonedDate(max, this.timezone),
      };
    }
  }

  get keyIndicator() {
    // @ts-expect-error
    switch (this.args.type) {
      case 'balance':
        return this.timeseries?.[this.timeseries.length - 1].data?.value || 0;
      case 'net-change':
        return (
          this.statistics?.[this.statistics.length - 1].data.ending_balance -
          this.statistics?.[0].data.starting_balance
        );
      default:
        // @ts-expect-error
        return this.timeseries?.reduce((total, { data }) => total + data.value, 0);
    }
  }

  initPersistedSettings() {
    // @ts-expect-error
    let persistedPeriod = JSON.parse(safeLocalStorage.getItem(this.periodStorageKey));
    // @ts-expect-error
    let persistedFilters = JSON.parse(safeLocalStorage.getItem(this.filtersStorageKey))?.filters;

    // avoid regression on old persisted data (cf. https://getqonto.atlassian.net/browse/BUG-6747)
    if (['last_30_days', 'last_3_months', 'last_12_months'].includes(persistedPeriod?.interval)) {
      delete persistedPeriod.key;
    }

    let hasCustomPeriod = persistedPeriod?.key === PERIOD_KEYS.CUSTOM_PERIOD;
    let hasInvalidCustomPeriod =
      hasCustomPeriod && !validatePeriod(persistedPeriod?.startDate, persistedPeriod?.endDate);
    let shouldResetPeriod =
      (hasCustomPeriod && this.cannotAccessAdvancedOverview) || hasInvalidCustomPeriod;

    if (shouldResetPeriod) {
      this._persistPeriod(this.periods[0]);
      persistedPeriod = this.periods[0];
    }

    // @ts-expect-error
    let includesTeamFilter = persistedFilters?.expressions?.some(group =>
      // @ts-expect-error
      group.expressions.some(({ property }) => property === 'team_id')
    );
    let shouldResetFilters =
      this.cannotAccessAdvancedOverview ||
      (includesTeamFilter && this.abilities.cannot('filter team'));

    if (shouldResetFilters) {
      persistedFilters = undefined;
      this._persistFilters(persistedFilters);
    }

    // @ts-expect-error
    this.persistedPeriod = persistedPeriod;
    this._advancedFilters = persistedFilters;
  }

  @action
  reload() {
    this.refreshWidgetTask
      .perform(this.selectedPeriod, this.advancedFilters)
      .catch(ignoreCancelation);
  }

  @action
  // @ts-expect-error
  selectPeriod(period, closeDropdown) {
    closeDropdown?.();
    this.updatePeriod(period);
    this.refreshWidgetTask.perform(period, this.advancedFilters).catch(ignoreCancelation);
  }

  @action
  // @ts-expect-error
  updatePeriod(period) {
    if (period) {
      let isPeriodUpdate = JSON.stringify(this.selectedPeriod) !== JSON.stringify(period);

      if (!this.args.isAdvancedFiltersMode && isPeriodUpdate) {
        this._persistPeriod(period);
        this._trackPeriodUpdate(period);
      }

      this._selectedPeriod = period;
    }
  }

  @action
  // @ts-expect-error
  triggerFilters(filters) {
    this.updateFilters(filters);
    this.refreshWidgetTask.perform(this.selectedPeriod, filters).catch(ignoreCancelation);
  }

  @action
  // @ts-expect-error
  updateFilters(filters) {
    if (filters !== null) {
      if (!this.args.isAdvancedFiltersMode) {
        this._persistFilters(filters);
        this._trackFiltersUpdate(this.advancedFilters, filters);
      }

      this._advancedFilters = filters;
    }
  }

  @action
  // @ts-expect-error
  onSubmitAdvancedFilters(filters) {
    // @ts-expect-error
    this.args.onSubmitAdvancedFilters(filters, this.selectedPeriod);
  }

  setBankAccountsTask = dropTask(async () => {
    await this.refreshWidgetTask
      .perform(this.selectedPeriod, this.advancedFilters)
      .catch(ignoreCancelation);
  });

  setAdvancedFiltersTask = dropTask(async () => {
    let advancedFiltersModal = this.modals.open(
      'overview/filters/indicator',
      {
        isFullScreenModal: true,
        // @ts-expect-error
        bankAccounts: this.args.bankAccounts,
        advancedFilters: this._advancedFilters,
        // @ts-expect-error
        indicatorType: this.args.type,
        // @ts-expect-error
        widgetTitle: this.args.title,
      },
      { focusTrapOptions: { clickOutsideDeactivates: false } }
    );

    try {
      let widgetSettings = await advancedFiltersModal;

      // @ts-expect-error
      if (widgetSettings) {
        let { filters, period } = widgetSettings;

        this.updatePeriod(period);
        this.updateFilters(filters);
        this.refreshWidgetTask.perform(period, filters).catch(ignoreCancelation);
      }
    } catch (error) {
      ignoreCancelation(error);
    } finally {
      // @ts-expect-error
      if (!advancedFiltersModal.result) {
        // @ts-expect-error
        advancedFiltersModal.close();
      }
    }
  });

  refreshWidgetTask = dropTask(async (period = this.selectedPeriod, filters) => {
    // @ts-expect-error
    if (!this.args.isLoading && this.args.onUpdate) {
      // @ts-expect-error
      if (this.args.type === 'balance' || this.args.type === 'net-change') {
        try {
          // @ts-expect-error
          await this.args.onUpdate(this.aggregations, period, this.bankAccounts);
        } catch (error) {
          this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));

          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 401) {
            this.sentry.captureException(error);
          }
        }
      } else {
        // @ts-expect-error
        let side = this.args.type === 'inflows' ? 'credit' : 'debit';
        try {
          // @ts-expect-error
          await this.args.onUpdate(this.aggregations, period, side, filters, this.bankAccounts);
        } catch (error) {
          this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));

          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 401) {
            this.sentry.captureException(error);
          }
        }
      }
    }
  });

  // @ts-expect-error
  _trackPeriodUpdate(period) {
    this.segment.track('dashboard_widget_update', {
      widget_type: this.trackingWidgetType,
      period_set_to: period.key || period.interval,
    });
  }

  // @ts-expect-error
  _trackFiltersUpdate(previousFilters, currentFilters) {
    let isFiltersUpdate = JSON.stringify(previousFilters) !== JSON.stringify(currentFilters);
    let updateType = 'updated';

    if (isFiltersUpdate) {
      if (!currentFilters) {
        updateType = 'removed';
      } else if (!previousFilters) {
        updateType = 'activated';
      }
      this.segment.track('dashboard_widget_update', {
        widget_type: this.trackingWidgetType,
        advanced_filters: updateType,
      });
    }
  }

  // @ts-expect-error
  _persistPeriod(period) {
    safeLocalStorage.setItem(this.periodStorageKey, JSON.stringify(period));
  }

  // @ts-expect-error
  _persistFilters(filters) {
    safeLocalStorage.setItem(this.filtersStorageKey, JSON.stringify({ filters }));
  }

  // @ts-expect-error
  _lookupPeriod({ interval, key, startDate, endDate }) {
    return key === PERIOD_KEYS.CUSTOM_PERIOD
      ? {
          label: this.intl.t('overview.period-selection.custom-period'),
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key,
        }
      : this.periods.find(period => (key ? period.key === key : period.interval === interval));
  }

  triggerRerenderTask = restartableTask(async () => {
    /**
     * Here, we need to check if the component is mounted or not.
     * We need to trigger the task only when the component props are updated, not when we mount
     * the component for the first time (or when it reload)
     */
    if (!this.didComponentMount) {
      this.didComponentMount = true;
    } else {
      this.didComponentMount = false;
      await this.setBankAccountsTask.perform();
    }
    // @ts-expect-error
    return this.args.bankAccounts;
  });

  // @ts-expect-error
  lastBankAccount = trackedTask(this, this.triggerRerenderTask, () => [this.args.bankAccounts]);
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::Indicator': typeof OverviewWidgetsIndicatorComponent;
  }
}
