/* import __COLOCATED_TEMPLATE__ from './delete.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface PaymentMethodDeleteModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaymentMethodDeleteModalComponent extends Component<PaymentMethodDeleteModalSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get translations() {
    return {
      title: this.intl.t('subscription.payment-methods.delete.title'),
      description: this.intl.t('subscription.payment-methods.delete.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('subscription.payment-methods.delete.confirm'),
    };
  }

  get data() {
    return { ...this.translations, confirmTask: this.confirmTask };
  }

  confirmTask = dropTask(async () => {
    this.segment.track('payment-methods_delete_confirmed');
    // @ts-expect-error
    let { confirmTask, paymentMethodId } = this.args.data;

    await confirmTask.linked().perform(paymentMethodId);
    // @ts-expect-error
    this.args.close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentMethods::Modals::Delete': typeof PaymentMethodDeleteModalComponent;
  }
}
