/* import __COLOCATED_TEMPLATE__ from './mapping-column.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface MappingColumnSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class MappingColumnComponent extends Component<MappingColumnSignature> {
  get selectedValue() {
    // @ts-expect-error
    return this.args.mapping?.[this.args.column];
  }

  get selectedOption() {
    // @ts-expect-error
    return this.args.options.find(o => o.id === this.selectedValue) ?? {};
  }

  get isMapped() {
    return Boolean(this.selectedValue);
  }

  get data() {
    // @ts-expect-error
    return this.args.rows.slice(0, 5).map(row => row[this.args.column]);
  }

  @action
  // @ts-expect-error
  onChange(option) {
    // @ts-expect-error
    this.args.onMappingUpdate(this.args.column, option.id);
  }

  @action
  onClear() {
    // @ts-expect-error
    this.args.onMappingUpdate(this.args.column, undefined);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Invoices::Mapping::MappingColumn': typeof MappingColumnComponent;
  }
}
