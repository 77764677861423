/* import __COLOCATED_TEMPLATE__ from './power-of-attorney-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

interface PowerOfAttorneyFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PowerOfAttorneyFormComponent extends Component<PowerOfAttorneyFormSignature> {
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  @tracked showFileRequiredError = false;

  fileMaxFileSize = 30 * 1e6; // 30MB

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  files = new TrackedArray([]);

  get fileDropzoneLabel() {
    return this.intl.t('labels.upload-message', {
      maxSize: formatFileSize(this.intl, this.fileMaxFileSize),
    });
  }

  get subtitle() {
    return this.intl.t('on-behalf.power-of-attorney-form.subtitle', {
      legalCountry: this.legalCountry,
      htmlSafe: true,
      faqUrl: this.zendeskLocalization.getLocalizedArticle(4359530),
    });
  }

  @action
  handleFormChange() {
    // @ts-expect-error
    this.args.onChange?.({ files: this.files });
  }

  @action
  // @ts-expect-error
  handleDeleteFile(file) {
    // @ts-expect-error
    let index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.handleFormChange();
  }

  @action
  // @ts-expect-error
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
    });
  }

  @action
  // @ts-expect-error
  handleAddFile(file) {
    this.showFileRequiredError = false;
    // @ts-expect-error
    this.files.push(file);
    this.handleFormChange();
  }

  submitTask = dropTask(async () => {
    if (!this.files.length) {
      this.showFileRequiredError = true;
      // @ts-expect-error
      this.error = this.intl.t('on-behalf.power-of-attorney-form.errors.empty');
      return;
    }

    // @ts-expect-error
    await this.args.onFormSubmitTask.linked().perform({ files: this.files });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerOfAttorney::PowerOfAttorneyForm': typeof PowerOfAttorneyFormComponent;
  }
}
