/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface PowerSelectAccountsFooterSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PowerSelectAccountsFooterComponent extends Component<PowerSelectAccountsFooterSignature> {
  @service declare router: Services['router'];

  @action
  // @ts-expect-error
  hoverActions(event) {
    // @ts-expect-error
    this.args.hoverActions?.(event);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Accounts::Footer': typeof PowerSelectAccountsFooterComponent;
  }
}
