export default {
  "notifications-settings": "clR",
  "opened": "clQ",
  "icon": "cle",
  "header": "clB",
  "header-toggle": "clE",
  "avatar-image": "cld",
  "title": "clI",
  "icon--right": "clt",
  "toggle-container": "clM",
  "toggle": "clP"
};
