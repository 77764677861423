/* import __COLOCATED_TEMPLATE__ from './related-invoice-placeholder.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RelatedInvoicePlaceholderSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const RelatedInvoicePlaceholder = templateOnlyComponent<RelatedInvoicePlaceholderSignature>();

export default RelatedInvoicePlaceholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::RelatedInvoicePlaceholder': typeof RelatedInvoicePlaceholder;
  }
}
