export default {
  "card": "cIj",
  "focus": "cIW",
  "title-format": "cIu",
  "header": "ctS",
  "headings": "ctc",
  "title": "ctq title-4",
  "main": "ctZ title-3",
  "footer": "ctR"
};
