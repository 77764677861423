/* import __COLOCATED_TEMPLATE__ from './modal-transition.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Motion, rAF, Tween, wait } from 'ember-animated';
import { easeIn, easeOut } from 'ember-animated/easings/cosine';
import linear from 'ember-animated/easings/linear';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { modifier } from 'ember-modifier';
import window from 'ember-window-mock';

class Freeze extends Motion {
  *animate() {
    let { duration } = this;
    // @ts-expect-error
    this.tween = new Tween(0, 1, duration, linear);
    // @ts-expect-error
    while (!this.tween.done) {
      yield rAF();
    }
  }
}

class LockScroll extends Motion {
  *animate() {
    let { duration } = this;
    // @ts-expect-error
    this.tween = new Tween(0, 1, duration, linear);
    let initialOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    // @ts-expect-error
    while (!this.tween.done) {
      if (window.scrollX || window.scrollY) {
        window.scrollTo(0, 0);
      }
      yield rAF();
    }
    document.body.style.overflow = initialOverflow;
  }
}

interface ModalTransitionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ModalTransitionComponent extends Component<ModalTransitionSignature> {
  @service declare router: Services['router'];
  @service declare menu: Services['menu'];

  lockScroll = modifier(element => {
    let scrollToTop = () => element.scrollTo(0, 0);
    element.addEventListener('scroll', scrollToTop);
    return () => element.removeEventListener('scroll', scrollToTop);
  });

  state = {
    // @ts-expect-error
    id: this.args.id || this.router.currentRouteName,
    // @ts-expect-error
    role: this.args.role,
  };

  @action
  // @ts-expect-error
  *transition({ insertedSprites, removedSprites, duration }) {
    // @ts-expect-error
    let insertedModals = insertedSprites.filter(sprite => sprite.owner.value.role === 'modal');
    // @ts-expect-error
    let removedModals = removedSprites.filter(sprite => sprite.owner.value.role === 'modal');
    // @ts-expect-error
    let removedBackdrops = removedSprites.filter(sprite => sprite.owner.value.role === 'backdrop');

    // @ts-expect-error
    if (this.args.allowedDestinations?.includes(this.router.currentRouteName)) {
      // @ts-expect-error
      removedBackdrops.forEach(sprite => {
        new Freeze(sprite).run();
      });
    }

    // @ts-expect-error
    insertedModals.forEach(sprite => {
      sprite.startTranslatedBy(0, window.innerHeight);
      sprite.applyStyles({ 'z-index': 'var(--z-index-above)' });
      move(sprite, { easing: easeOut });
      fadeIn(sprite, { easing: easeOut });
      new LockScroll(sprite).run();
    });

    // @ts-expect-error
    removedModals.forEach(sprite => {
      sprite.endTranslatedBy(0, window.innerHeight);
      sprite.applyStyles({ 'z-index': 'var(--z-index-above)' });
      move(sprite, { easing: easeIn });
      fadeOut(sprite, { easing: easeIn });
    });

    if (insertedModals.length) {
      yield wait(duration);
      if (this.menu.isVisible) {
        this.menu.hide();
      }
    } else if (removedModals.length) {
      if (!this.menu.isVisible) {
        this.menu.show();
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ModalTransition: typeof ModalTransitionComponent;
  }
}
