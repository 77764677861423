/* import __COLOCATED_TEMPLATE__ from './link-card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LinkCardSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const LinkCard = templateOnlyComponent<LinkCardSignature>();

export default LinkCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LinkCard: typeof LinkCard;
  }
}
