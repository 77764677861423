/* import __COLOCATED_TEMPLATE__ from './category.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CategorySignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Category = templateOnlyComponent<CategorySignature>();

export default Category;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Nrc::Sidebar::Category': typeof Category;
  }
}
