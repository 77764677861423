/* import __COLOCATED_TEMPLATE__ from './loading-state.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LoadingStateSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const LoadingState = templateOnlyComponent<LoadingStateSignature>();

export default LoadingState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LoadingState: typeof LoadingState;
  }
}
