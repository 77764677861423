/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { Header } from 'qonto/react/components/nrc/table/header';

interface TableSignature {
  Args: {
    isLoading?: boolean;
    isCompleted?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class Table extends Component<TableSignature> {
  header = Header;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Nrc::Table': typeof Table;
  }
}
