export default {
  "header-cell": "cFO",
  "method-col": "cFm",
  "expiration-col": "cFp",
  "status-col": "cFx",
  "amount-col": "cFw",
  "with-sidebar": "cFN",
  "header-content": "cFv caption-bold",
  "empty": "cFo",
  "align-right": "cFn"
};
