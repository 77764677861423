/* import __COLOCATED_TEMPLATE__ from './mandate-select-trigger.hbs'; */
import Component from '@glimmer/component';

import { ObfuscatedIban } from 'qonto/react/components/direct-debit-collections';

interface PowerSelectMandatesMandateSelectTriggerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PowerSelectMandatesMandateSelectTriggerComponent extends Component<PowerSelectMandatesMandateSelectTriggerSignature> {
  ObfuscatedIban = ObfuscatedIban;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Mandates::MandateSelectTrigger': typeof PowerSelectMandatesMandateSelectTriggerComponent;
  }
}
