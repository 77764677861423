export default {
  "row": "cFi",
  "cell": "cFA body-1",
  "row-cancelled": "cFY",
  "cell-amount": "cFh",
  "empty": "cFf",
  "align-right": "cFK",
  "debitor-container": "cFG",
  "debitor-info": "cFr",
  "debitor-name": "cFb body-1",
  "with-sidebar": "cFy",
  "method-col": "cFU",
  "resource-number": "cFj caption",
  "resource-icon": "cFW",
  "avatar": "cFu"
};
