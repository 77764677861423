/* import __COLOCATED_TEMPLATE__ from './all.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const REVEAL_BALANCE_KEY = `reveal-history-balance`;

interface OverviewWidgetsAllSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewWidgetsAllComponent extends Component<OverviewWidgetsAllSignature> {
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare userManager: Services['userManager'];
  @service declare abilities: Services['abilities'];
  @tracked showBalance = true;

  constructor(owner: unknown, args: OverviewWidgetsAllSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { id } = this.userManager.currentUser;
    let item = safeLocalStorage.getItem(`${REVEAL_BALANCE_KEY}-${id}`);

    if (item !== null) {
      this.showBalance = JSON.parse(item);
    }
  }

  @action
  toggleBalanceVisibility() {
    // @ts-expect-error
    let { id } = this.userManager.currentUser;
    this.showBalance = !this.showBalance;

    // @ts-expect-error
    safeLocalStorage.setItem(`${REVEAL_BALANCE_KEY}-${id}`, this.showBalance);

    let trackingEventName = this.showBalance
      ? 'dashboard_balance_reveal'
      : 'dashboard_balance_hide';
    this.segment.track(trackingEventName);
  }

  get origin() {
    return this.router.currentRoute.name || DEFAULT_ORIGIN_URL;
  }

  get showOverviewCharts() {
    return this.abilities.can('see overview charts cash-flow');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::All': typeof OverviewWidgetsAllComponent;
  }
}
