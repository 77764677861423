/* import __COLOCATED_TEMPLATE__ from './wrapper.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface WrapperSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Wrapper = templateOnlyComponent<WrapperSignature>();

export default Wrapper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Tooltip::Builder::Wrapper': typeof Wrapper;
  }
}
