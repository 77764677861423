/* import __COLOCATED_TEMPLATE__ from './common.hbs'; */
import { action, get } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeHighlight } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { ACCOUNT_STATUS } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

// @ts-expect-error
const sortByDateAscending = (a, b) => new Date(a.createdAt) - new Date(b.createdAt);

interface PageHeaderCommonSignature {
  // The arguments accepted by the component
  Args: {
    isBeta?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PageHeaderCommonComponent extends Component<PageHeaderCommonSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare userManager: Services['userManager'];
  @service declare segment: Services['segment'];

  @tracked showHistoryBalance = true;

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('organization.primaryAccount.balanceCurrency') currency;
  // @ts-expect-error
  @reads('organization.activeAccounts') activeAccounts;
  // @ts-expect-error
  @reads('organization.sortedBankAccounts') sortedAccounts;
  @reads('organization.activeSortedRemuneratedAndCurrentAccounts')
  // @ts-expect-error
  sortedAscActiveRemuneratedAndCurrentAccounts;
  // @ts-expect-error
  @reads('organization.activeSortedCurrentAccounts') activeSortedCurrentAccounts;
  // @ts-expect-error
  @reads('organization.remuneratedAccounts') remuneratedAccounts;

  // @ts-expect-error
  @reads('args.shouldHideClosedAccounts') shouldHideClosedAccounts;
  // @ts-expect-error
  @reads('args.shouldHideClosedRemuneratedAccounts') shouldHideClosedRemuneratedAccounts;
  // @ts-expect-error
  @reads('args.shouldShowWaitingRemuneratedAccounts') shouldShowWaitingRemuneratedAccounts;
  // @ts-expect-error
  @reads('args.isRemuneratedAccountsHidden') isRemuneratedAccountsHidden;
  // @ts-expect-error
  @reads('args.hideExternalAccounts') hideExternalAccounts;

  @tracked
  allAccountsOption = {
    id: '',
    icon: 'icon_layers_outlined',
    // @ts-expect-error
    name: this.intl.t('bank_accounts.header.all-accounts'),
    // @ts-expect-error
    safeName: this.intl.t('bank_accounts.header.all-accounts'),
    // @ts-expect-error
    balance: this.organization.balance,
    // @ts-expect-error
    authorizedBalance: this.organization.bankAccountsBalance,
    // @ts-expect-error
    processingBalance: this.organization.processingBalance,
  };

  // @ts-expect-error
  localStorageRevealBalanceKey = `reveal-history-balance-${this.userManager.currentUser.id}`;

  badgeHighlight = BadgeHighlight;

  constructor(owner: unknown, args: PageHeaderCommonSignature['Args']) {
    super(owner, args);

    let item = safeLocalStorage.getItem(this.localStorageRevealBalanceKey);
    if (item !== null) {
      this.showHistoryBalance = JSON.parse(item);
    }
  }

  get hasAllAccountsOption() {
    // @ts-expect-error
    if (this.args.multiAccounts) return false;
    // @ts-expect-error
    return this.args.hasAllAccountsOption ?? true;
  }

  get showAccountsFilter() {
    return (
      this.abilities.can('read bankAccount') &&
      // @ts-expect-error
      this.args.showAccountsFilter &&
      // @ts-expect-error
      this.sortedAccounts.filter(account =>
        // @ts-expect-error
        this.args.shouldHideClosedAccounts ? account.status === ACCOUNT_STATUS.ACTIVE : true
      ).length > 1
    );
  }

  get selectedAccounts() {
    let bankAccountIds = this.router.currentRoute?.queryParams['bank-accounts'];

    let filteredAccounts = this.sortedAccounts.filter(
      // @ts-expect-error
      it => bankAccountIds?.includes(it.id) || it.slug === this.router.currentRoute?.params?.slug
    );

    // @ts-expect-error
    if (!filteredAccounts.length) return this.args.multiAccounts ? [] : [this.allAccountsOption];
    return filteredAccounts;
  }

  get authorizedBalance() {
    return this.selectedAccounts.length
      ? // @ts-expect-error
        this.selectedAccounts.reduce((acc, account) => acc + account.authorizedBalance, 0)
      : this.organization.bankAccountsBalance;
  }

  get isBalanceDefined() {
    return this.selectedAccounts.length
      ? // @ts-expect-error
        this.selectedAccounts.every(account => account.authorizedBalance !== undefined)
      : true;
  }

  get balance() {
    return this.selectedAccounts.length
      ? // @ts-expect-error
        this.selectedAccounts.reduce((acc, account) => acc + account.balance, 0)
      : this.organization.balance;
  }

  get processingBalance() {
    let processingBalance = this.selectedAccounts.length
      ? // @ts-expect-error
        this.selectedAccounts.reduce((acc, account) => acc + account.processingBalance, 0)
      : this.organization.processingBalance;

    if (processingBalance !== 0) {
      processingBalance = -processingBalance;
    }
    return processingBalance;
  }

  get tooltipMessage() {
    let { intl, currency } = this;

    let settled = intl.formatNumber(this.balance, { style: 'currency', currency });
    let processing = intl.formatNumber(this.processingBalance, { style: 'currency', currency });

    return intl.t('bank_accounts.header.balance.tooltip', { settled, processing, htmlSafe: true });
  }

  get closedSortedCurrentAccounts() {
    // @ts-expect-error
    if (this.args.shouldHideClosedAccounts) return [];

    return this.organization.closedSortedCurrentAccounts;
  }

  get closedSortedDescCurrentAccounts() {
    // @ts-expect-error
    return this.closedSortedCurrentAccounts.sort((a, b) => b.createdAt - a.createdAt);
  }

  get sortedDescClosedRemuneratedAndCurrentAccounts() {
    // @ts-expect-error
    if (this.args.shouldHideClosedAccounts) return [];

    let currentAccounts = [];
    let remuneratedAccounts = [];
    let closedAccounts = this.sortedAccounts
      // @ts-expect-error
      .filter(({ status }) => status === ACCOUNT_STATUS.CLOSED)
      // @ts-expect-error
      .sort((a, b) => b.createdAt - a.createdAt);

    for (let account of closedAccounts) {
      if (!account.isRemunerated) {
        currentAccounts.push(account);
      } else {
        remuneratedAccounts.push(account);
      }
    }
    return currentAccounts.concat(remuneratedAccounts);
  }

  get options() {
    let allAccountsOption = this.hasAllAccountsOption ? [this.allAccountsOption] : [];

    let sortedAccounts = [
      ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
      ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
    ];

    if (this.isRemuneratedAccountsHidden) {
      sortedAccounts = [...this.activeSortedCurrentAccounts, ...this.closedSortedCurrentAccounts];
    } else if (this.shouldHideClosedRemuneratedAccounts) {
      sortedAccounts = [
        ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
        ...this.closedSortedDescCurrentAccounts,
      ];
    } else if (this.shouldShowWaitingRemuneratedAccounts) {
      let waitingRemuneratedAccounts = this.remuneratedAccounts
        // @ts-expect-error
        .filter(({ status }) => status === ACCOUNT_STATUS.WAITING)
        .sort(sortByDateAscending);

      sortedAccounts = [
        ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
        ...waitingRemuneratedAccounts,
        ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
      ];
    } else {
      sortedAccounts = [
        ...this.sortedAscActiveRemuneratedAndCurrentAccounts,
        ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
      ];
    }

    return [...allAccountsOption, ...sortedAccounts];
  }

  get waitingRemuneratedAccounts() {
    if (!this.shouldShowWaitingRemuneratedAccounts) return [];
    return (
      this.remuneratedAccounts
        // @ts-expect-error
        .filter(({ status }) => status === ACCOUNT_STATUS.WAITING)
        .sort(sortByDateAscending)
    );
  }

  get aggregatedAccounts() {
    let accounts = [
      ...this.activeAccounts,
      ...this.waitingRemuneratedAccounts,
      ...this.sortedDescClosedRemuneratedAndCurrentAccounts,
    ];

    if (this.isRemuneratedAccountsHidden) {
      accounts = accounts.filter(({ isRemunerated }) => !isRemunerated);
    } else if (this.shouldHideClosedRemuneratedAccounts) {
      accounts = accounts.filter(
        ({ status, isRemunerated }) => !(status === ACCOUNT_STATUS.CLOSED && isRemunerated)
      );
    }

    if (this.hideExternalAccounts) {
      accounts = accounts.filter(({ isExternalAccount }) => !isExternalAccount);
    }

    return [...(this.hasAllAccountsOption ? [this.allAccountsOption] : []), ...accounts];
  }

  get showFooter() {
    // @ts-expect-error
    return this.args.showAccountsFooter && this.abilities.can('import external-account');
  }

  @action
  // @ts-expect-error
  updateAccount({ id, createdAt, slug }) {
    let index = this.options.map(item => get(item, 'id')).indexOf(id);

    this.segment.track('bank_account_picker_account_selected', { id, index, createdAt });

    // @ts-expect-error
    if (this.args.updateAccount) {
      // @ts-expect-error
      return this.args.updateAccount(slug);
    }

    this.router.transitionTo({
      queryParams: {
        'bank-accounts': id,
        page: 1,
      },
    });
  }

  @action
  // @ts-expect-error
  onUpdateAccounts(accounts) {
    // @ts-expect-error
    let ids = accounts.map(account => account.id);
    let showBankAccountsParams = ids.length > 0 && ids.length < this.aggregatedAccounts.length;

    this.router.transitionTo({
      queryParams: {
        'bank-accounts': showBankAccountsParams ? ids : undefined,
        page: 1,
      },
    });
  }

  @action
  toggleBalanceVisibility() {
    this.showHistoryBalance = !this.showHistoryBalance;

    // @ts-expect-error
    safeLocalStorage.setItem(this.localStorageRevealBalanceKey, this.showHistoryBalance);

    let trackingEventName = this.showHistoryBalance
      ? 'history_balance_reveal'
      : 'history_balance_hide';
    this.segment.track(trackingEventName);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PageHeader::Common': typeof PageHeaderCommonComponent;
  }
}
