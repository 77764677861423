/* import __COLOCATED_TEMPLATE__ from './share-panel.hbs'; */
import Component from '@glimmer/component';

import { Checkbox, TextAreaField } from '@repo/design-system-kit';

interface InvoiceSubscriptionsFormEmailDetailsSharePanelSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSubscriptionsFormEmailDetailsSharePanelComponent extends Component<InvoiceSubscriptionsFormEmailDetailsSharePanelSignature> {
  checkbox = Checkbox;
  textAreaField = TextAreaField;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::Form::EmailDetails::SharePanel': typeof InvoiceSubscriptionsFormEmailDetailsSharePanelComponent;
  }
}
