/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SearchField } from '@repo/design-system-kit';
import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { restartableTask, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { InContextIntegrationsSideDrawerIntegrationTile } from 'qonto/react/components/in-context-integrations/side-drawer/integration/tile';

import styles from './list.strict-module.css';

interface InContextIntegrationsSideDrawerIntegrationListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InContextIntegrationsSideDrawerIntegrationListComponent extends Component<InContextIntegrationsSideDrawerIntegrationListSignature> {
  inContextIntegrationsSideDrawerIntegrationTile = InContextIntegrationsSideDrawerIntegrationTile;

  searchField = SearchField;
  styles = styles;
  emptyStatesInform = EmptyStatesInform;

  @service declare segment: Services['segment'];

  // @ts-expect-error
  @tracked searchedValue = this.args.query || '';
  @tracked scrolledTop = true;

  updateIntegrationListTask = restartableTask(async value => {
    this.searchedValue = value;

    await timeout(DEBOUNCE_MS);
    // @ts-expect-error
    this.args.updateSearchValue(value);
    // @ts-expect-error
    this.segment.track('ICI_search_clicked', { page: this.args.page });
  });

  @action
  // @ts-expect-error
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::SideDrawer::Integration::List': typeof InContextIntegrationsSideDrawerIntegrationListComponent;
  }
}
