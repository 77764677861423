/* import __COLOCATED_TEMPLATE__ from './invoices-supplier.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask } from 'ember-concurrency';

import { FILTERS, INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { WidgetInvoices } from 'qonto/react/components/widget/overview/invoices';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface OverviewWidgetsInvoicesSupplierSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewWidgetsInvoicesSupplier extends Component<OverviewWidgetsInvoicesSupplierSignature> {
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];

  widgetInvoices = WidgetInvoices;

  constructor(owner: unknown, args: OverviewWidgetsInvoicesSupplierSignature['Args']) {
    super(owner, args);
    this.fetchSupplierInvoicesTask.perform().catch(ignoreCancelation);
  }

  fetchSupplierInvoicesTask = restartableTask(async () => {
    try {
      let invoices = await this.store.query('supplier-invoice', {
        page: 1,
        per_page: 3,
        filter: {
          status: [INVOICE_STATUSES.toReview, INVOICE_STATUSES.awaitingPayment],
        },
        sort_by: `${FILTERS.DUE_DATE}:asc`,
      });

      return invoices;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      throw error;
    }
  });

  @action
  reload() {
    this.fetchSupplierInvoicesTask.perform().catch(ignoreCancelation);
  }

  get isError() {
    return this.fetchSupplierInvoicesTask.last?.isError;
  }

  get isLoading() {
    return this.fetchSupplierInvoicesTask.isRunning;
  }

  get invoices() {
    return this.fetchSupplierInvoicesTask.lastSuccessful?.value ?? [];
  }
}
