/* import __COLOCATED_TEMPLATE__ from './error.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ErrorSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Error = templateOnlyComponent<ErrorSignature>();

export default Error;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Error': typeof Error;
  }
}
