import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface ModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ModalComponent extends Component<ModalSignature> {
  @service declare modals: Services['modals'];

  constructor(owner: unknown, args: ModalSignature['Args']) {
    super(owner, args);
    // No need to catch here, the task should be canceled when the component is destroyed
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.openModalTask.perform();
  }

  willDestroy() {
    this.openModalTask.cancelAll();
    // @ts-expect-error
    super.willDestroy(...arguments);
  }

  openModalTask = dropTask(async () => {
    // @ts-expect-error
    let { modalComponent, disableClickOutside, ...data } = this.args;
    let modal;
    let result;
    let focusTrapOptions = { clickOutsideDeactivates: !disableClickOutside };
    try {
      modal = this.modals.open(modalComponent, data, { focusTrapOptions });
      result = await modal;
    } finally {
      // @ts-expect-error
      modal.close();
      // @ts-expect-error
      data.onClose(result);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Modal: typeof ModalComponent;
  }
}
