/* import __COLOCATED_TEMPLATE__ from './legend.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LegendSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Legend = templateOnlyComponent<LegendSignature>();

export default Legend;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'QState::Legend': typeof Legend;
  }
}
