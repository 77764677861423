/* import __COLOCATED_TEMPLATE__ from './indicator.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

interface TooltipIndicatorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TooltipIndicatorComponent extends Component<TooltipIndicatorSignature> {
  @service declare intl: Services['intl'];

  titleTranslations = {
    // @ts-expect-error
    daily: this.intl.t('overview.dates.today'),
    // @ts-expect-error
    weekly: this.intl.t('overview.dates.current-week'),
    // @ts-expect-error
    monthly: this.intl.t('overview.dates.current-month'),
  };

  amountLabelTranslations = {
    balance: {
      // @ts-expect-error
      daily: this.intl.t('overview.balance.end-of-day'),
      // @ts-expect-error
      weekly: this.intl.t('overview.balance.end-of-week'),
      // @ts-expect-error
      monthly: this.intl.t('overview.balance.end-of-month'),
      // @ts-expect-error
      current: this.intl.t('overview.balance.current'),
    },
    // @ts-expect-error
    'net-change': this.intl.t('overview.net-change.amount'),
    // @ts-expect-error
    inflows: this.intl.t('overview.amount'),
    // @ts-expect-error
    outflows: this.intl.t('overview.amount'),
  };

  get hasCount() {
    // @ts-expect-error
    return Number.isFinite(this.args.sample?.count);
  }

  get isCurrent() {
    // @ts-expect-error
    let { time } = this.args.sample;

    // @ts-expect-error
    switch (this.args.rate) {
      case 'daily':
        return dayjs().isSame(time, 'day');
      case 'weekly':
        return dayjs().isSame(time, 'week');
      case 'monthly':
        return dayjs().isSame(time, 'month');
    }
  }

  get title() {
    // @ts-expect-error
    let { rate, sample } = this.args;
    let { time } = sample;

    if (this.isCurrent) {
      // @ts-expect-error
      return this.titleTranslations[rate];
    } else {
      switch (rate) {
        case 'daily':
          return dateToken({
            date: time,
            // @ts-expect-error
            locale: this.intl.primaryLocale,
            token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
          });
        case 'weekly':
          return this._formatWeek(time);
        case 'monthly':
          return dateToken({
            date: time,
            // @ts-expect-error
            locale: this.intl.primaryLocale,
            token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
          });
      }
    }
  }

  get amountLabel() {
    // @ts-expect-error
    if (this.args.type === 'balance') {
      return this.isCurrent
        ? this.amountLabelTranslations.balance.current
        : // @ts-expect-error
          this.amountLabelTranslations.balance[this.args.rate];
    } else {
      // @ts-expect-error
      return this.amountLabelTranslations[this.args.type];
    }
  }

  get amountValue() {
    // @ts-expect-error
    let { time, value } = this.args.sample;

    return dayjs(time).isAfter(dayjs()) ? '--' : value;
  }

  get countValue() {
    // @ts-expect-error
    let { time, count } = this.args.sample;

    return dayjs(time).isAfter(dayjs()) ? '--' : count;
  }

  // @ts-expect-error
  _formatWeek(time) {
    let startDay = dateToken({
      // @ts-expect-error
      date: dayjs(time).startOf('isoWeek').toDate(),
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });

    let endDay = dateToken({
      // @ts-expect-error
      date: dayjs(time).endOf('isoWeek').toDate(),
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });

    return this.intl.t('overview.dates.week-of', { startDay, endDay });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Tooltip::Indicator': typeof TooltipIndicatorComponent;
  }
}
