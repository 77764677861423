/* import __COLOCATED_TEMPLATE__ from './creation-form-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import normalize from 'qonto/utils/normalize-string';
import { getServiceTypeFromUnitCode } from 'qonto/utils/products';
import { getSupportedCurrencies, getSupportedUnits } from 'qonto/utils/receivable-invoicing';

const IGNORE_ERROR_STATUSES = [422];

interface ProductCreationModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ProductCreationModalComponent extends Component<ProductCreationModalSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  textAreaField = TextAreaField;

  @service declare intl: Services['intl'];
  @service declare errors: Services['errors'];
  @service declare store: Services['store'];
  @service declare modals: Services['modals'];

  // @ts-expect-error
  @reads('args.data.product') product;

  // @ts-expect-error
  @tracked selectedUnit;
  // @ts-expect-error
  @tracked unitList = getSupportedUnits(this.intl);

  constructor(owner: unknown, args: ProductCreationModalSignature['Args']) {
    super(owner, args);
    window.addEventListener('keydown', this.handleEscape);
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    window.removeEventListener('keydown', this.handleEscape);
  }

  get currencies() {
    return getSupportedCurrencies(this.intl);
  }

  get shouldCheckForDuplicates() {
    return (
      this.product.title &&
      this.product.vatRate &&
      this.product.unitPrice?.value &&
      this.product.unitPrice?.currency
    );
  }

  get supportedUnits() {
    return getSupportedUnits(this.intl);
  }

  @action
  // @ts-expect-error
  getErrorMessage({ attribute, node, index }) {
    let hasError = {
      link: Boolean(this.product.errors.get(`links/${index}/${node}`)?.length),
      title: Boolean(this.product.errors.get('title')?.length),
      unitPrice: Boolean(this.product.errors.get('unit_price/value')?.length),
      vatRate: Boolean(this.product.errors.get('vatRate')?.length),
    };

    // @ts-expect-error
    return hasError[attribute]
      ? this.intl.t('receivable-invoices.invoice-creation.errors.required-field')
      : null;
  }

  @action
  addLink() {
    this.product.links = [...this.product.links, { url: '', title: '' }];
  }

  @action
  // @ts-expect-error
  removeLink(index) {
    this.product.links = this.product.links.toSpliced(index, 1);
  }

  @action
  // @ts-expect-error
  updateLink({ index, node }, value) {
    let link = this.product.links.at(index);
    link[node] = value;

    this.product.links = [...this.product.links];

    this.product.errors.remove(`links/${index}/${node}`);
  }

  @action
  // @ts-expect-error
  normalizeLinkUrl(index) {
    let urlInput = this.product.links.at(index).url;
    if (!urlInput) return;

    /**
     * @todo: This can be refactored to use URL.parse() after updating core-js to 3.38.0
     */
    try {
      let normalizedUrl = new URL(urlInput);
      if (normalizedUrl.protocol !== 'https:') {
        this.product.set(`links.${index}.url`, urlInput.replace(/(.*):\/\//, 'https://'));
      }
    } catch {
      this.product.set(`links.${index}.url`, `https://${urlInput}`);
    }
  }

  @action
  // @ts-expect-error
  handleAmountUpdate(amount) {
    this.product.setProperties({
      unitPrice: {
        ...this.product.unitPrice,
        value: amount?.toFixed(2),
      },
    });
    this.product.errors.remove('unit_price/value');
  }

  @action
  // @ts-expect-error
  handleUnitSelection(unit) {
    this.selectedUnit = unit;
    this.product.unit = unit.unitCode;
    this.product.type = getServiceTypeFromUnitCode(unit.unitCode);
  }

  @action
  // @ts-expect-error
  handleUnitInputChange(value) {
    this.product.unit = value;
    this.unitList = this.supportedUnits.filter(unit =>
      unit.keywords.find(word => normalize(word).includes(normalize(value)))
    );
    this.selectedUnit = this.unitList.find(unit => unit.unitCode === value);
  }

  @action
  onUnitInputClose() {
    this.unitList = this.supportedUnits;
    this.selectedUnit = this.unitList.find(unit => unit.unitCode === this.product.unit);

    // Do not show the dropdown if the unit is custom
    if (this.product.unit && !this.selectedUnit) {
      this.unitList = [];
    }
  }

  @action
  handleSave() {
    this.handleSaveProductTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error =>
        !IGNORE_ERROR_STATUSES.includes(error.status) ? this.errors.handleError(error) : null
      );
  }

  // @ts-expect-error
  @action handleEscape({ key }) {
    if (key === 'Escape') {
      this.onCloseTask.perform().catch(ignoreCancelation);
    }
  }

  saveProductTask = dropTask(async close => {
    await this.product.save();
    close?.();
    // @ts-expect-error
    this.args.close();

    // @ts-expect-error
    if (this.args.data.onSaveSuccess) {
      // @ts-expect-error
      this.args.data.onSaveSuccess?.(this.product);
    }
  });

  confirmDuplicateCreationTask = dropTask(async close => {
    await this.saveProductTask.perform(close);
    // @ts-expect-error
    this.args.data.onConfirmedDuplicate?.();
  });

  discardDuplicateTask = dropTask(async close => {
    await close?.();
    // @ts-expect-error
    this.args.data.onDiscardedDuplicate?.();
  });

  handleSaveProductTask = dropTask(async () => {
    if (this.shouldCheckForDuplicates) {
      let { isDuplicate } = await this.checkForDuplicatesTask.perform();
      if (isDuplicate) {
        await this.modals.open('popup/confirmation', {
          title: this.intl.t('products.creation-form.duplicate-modal.title'),
          description: this.intl.t('products.creation-form.duplicate-modal.description'),
          cancel: this.intl.t('products.creation-form.duplicate-modal.cta.cancel'),
          confirm: this.intl.t('products.creation-form.duplicate-modal.cta.create'),
          confirmTask: this.confirmDuplicateCreationTask,
          cancelTask: this.discardDuplicateTask,
        });
      } else {
        // @ts-expect-error
        await this.saveProductTask.perform();
      }
    } else {
      // @ts-expect-error
      await this.saveProductTask.perform();
    }
  });

  checkForDuplicatesTask = dropTask(async () => {
    let results = await this.store.query('product', {
      filter: {
        title: this.product.title,
        vat_rate: this.product.vatRate,
        'unit_price.value': this.product.unitPrice.value,
        'unit_price.currency': this.product.unitPrice.currency,
      },
    });

    let isDuplicate = Boolean(
      this.product.isNew
        ? results.length
        : results.filter(({ id }) => id !== this.product.id).length
    );

    return { isDuplicate };
  });

  onCloseTask = dropTask(async () => {
    let isExiting = true;
    if (this.product.hasDirtyAttributes) {
      let decision = await this.modals.open('popup/confirmation', {
        title: this.intl.t('receivable-invoices.customer-creation.exit-modal.title'),
        description: this.intl.t('receivable-invoices.customer-creation.exit-modal.description'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('receivable-invoices.customer-creation.exit-modal.confirm.cta'),
      });

      // @ts-expect-error
      isExiting = decision === 'confirm';
    }

    if (isExiting) {
      // @ts-expect-error
      this.args.data.onClose?.();
      // @ts-expect-error
      this.args.close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Products::CreationFormModal': typeof ProductCreationModalComponent;
  }
}
