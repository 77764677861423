/* import __COLOCATED_TEMPLATE__ from './mandate-type-select.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface MandateTypeSelectSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MandateTypeSelectComponent extends Component<MandateTypeSelectSignature> {
  @service declare intl: Services['intl'];

  mandateTypes = [
    // @ts-expect-error
    { code: false, label: this.intl.t('mandates.new.type.single') },
    // @ts-expect-error
    { code: true, label: this.intl.t('mandates.new.type.recurrent') },
  ];

  get selectedType() {
    // @ts-expect-error
    return this.mandateTypes.find(({ code }) => code === this.args.mandate.recurrent);
  }

  // @ts-expect-error
  @action handleOnSelect({ code }) {
    // @ts-expect-error
    this.args.onChange(code);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandates::MandateTypeSelect': typeof MandateTypeSelectComponent;
  }
}
