/* import __COLOCATED_TEMPLATE__ from './insurance-conditions.hbs'; */
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { InsuranceClaimLink } from 'qonto/react/cards/components/insurance-assistance/insurance-claim-link';
import { TravelAssistanceAbroad } from 'qonto/react/cards/components/insurance-assistance/travel-assistance-abroad';

interface InsuranceConditionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceConditionsComponent extends Component<InsuranceConditionsSignature> {
  insuranceClaimLink = InsuranceClaimLink;
  travelAssistanceAbroad = TravelAssistanceAbroad;

  CARD_LEVELS = CARD_LEVELS;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceAssistance::InsuranceConditions': typeof InsuranceConditionsComponent;
  }
}
