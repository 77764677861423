export default {
  "sidebar": "cdN",
  "top-section": "cdv",
  "title": "cdo",
  "primary-action": "cdn",
  "edit-icon": "cdi",
  "close-icon": "cdA",
  "box-wrapper": "cdY",
  "bottom-action": "cdh",
  "related-invoices": "cdf",
  "related-invoices-title": "cdK mb-16 caption-bold",
  "box": "cdG",
  "box-element": "cdr",
  "cancel-text": "cdb",
  "box-header": "cdy",
  "row": "cdU",
  "greyed-out": "cdj",
  "struck-through": "cdW",
  "share-mandate-link": "cdu"
};
