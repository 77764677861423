/* import __COLOCATED_TEMPLATE__ from './loading.hbs'; */
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';

import { SkeletonLoader } from '@repo/design-system-kit';
import { dropTask, waitForQueue } from 'ember-concurrency';

import ComboChartComponent from 'qonto/components/overview/chart/combo-chart';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

// @ts-expect-error
import styles from './loading.module.css';

export default class CashflowLoadingComponent extends ComboChartComponent {
  // @ts-expect-error
  @service sentry;
  styles = styles;
  placeholderLine = SkeletonLoader.Line;
  defaultYMax = 50;
  // @ts-expect-error
  isAnimated = false;
  chartId = `chart-container-${guidFor(this)}`;
  series = [
    [19, 17],
    [22, 20],
    [20, 17],
    [29, 35],
    [39, 31],
    [30, 29],
    [28, 24],
    [45, 34],
    [25, 30],
    [40, 36],
    [34, 36],
    [19, 17],
  ];

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.setGraphTask.perform().catch(ignoreCancelation);
  }

  setGraphTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');
      // @ts-expect-error
      this.chartContainer = document.getElementById(this.chartId);
      // @ts-expect-error
      this._setRenderer(this.chartContainer);
      this._setScales(this.timeseries);
      // @ts-expect-error
      this._setYGridlines(this.yScale);
      // @ts-expect-error
      this._drawBars(this.timeseries, this.xScale, this.yScale);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  // @ts-expect-error
  _getFlowBarsData(_, index) {
    // @ts-expect-error
    return this.series[index].map((value, isOutflows) => ({
      value,
      type: isOutflows ? 'outflows' : 'inflows',
    }));
  }
}
