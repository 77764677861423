/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { task } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface MandatesEditSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MandatesEditComponent extends Component<MandatesEditSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @tracked enableMandateValidations = null;

  constructor(owner: unknown, args: MandatesEditSignature['Args']) {
    super(owner, args);
    let organization = this.organizationManager.organization;
    // @ts-expect-error
    this.args.mandate.setProperties({
      organization,
    });
  }

  // @ts-expect-error
  closeModal(reset) {
    if (reset) {
      // @ts-expect-error
      this.args.mandate.rollbackAttributes();
    }
    // @ts-expect-error
    this.args.cancel();
  }

  // @ts-expect-error
  @action handleCloseModal(reset) {
    this.closeModal(reset);
  }

  saveUpdatedMandateTask = task(async mandate => {
    try {
      await mandate.save();

      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.mandate_updated'));

      this.closeModal(false);
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      let message = '';
      if (error instanceof InvalidError) {
        // @ts-expect-error
        let { code, detail } = error.errors[0].detail;
        if (code === 'invalid_format') {
          message = this.intl.t('mandates.errors.creditor-identifier-invalid-format');
        } else if (code === 'umr_taken') {
          message = this.intl.t('mandates.errors.umr-taken');
        } else {
          message = detail;
        }
      } else {
        message = this.intl.t('toasts.mandate_failed_update');
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }

      this.toastFlashMessages.toastError(message);
    }
  });

  validationToConfirmationTask = task(async () => {
    // @ts-expect-error
    this.enableMandateValidations = false;

    // @ts-expect-error
    let { validations: mandateValidation } = await this.args.mandate.validate({
      on: ['creditorName', 'creditorIdentifier', 'umr'],
    });
    // @ts-expect-error
    this.enableMandateValidations = true;

    if (mandateValidation.isValid) {
      // @ts-expect-error
      await this.saveUpdatedMandateTask.perform(this.args.mandate);
    }
  });

  handleFormSubmitTask = task(async () => {
    // @ts-expect-error
    await this.sensitiveActions.runTask.perform(this.validationToConfirmationTask);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Mandates::Edit': typeof MandatesEditComponent;
  }
}
