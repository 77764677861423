/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/invoice-subscriptions';

interface InvoiceSubscriptionsTabsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSubscriptionsTabs extends Component<InvoiceSubscriptionsTabsSignature> {
  get activeTab() {
    // @ts-expect-error
    let { status } = this.args;
    let statuses = (status || '').split(',');
    let isCompleted = [STATUS.FINISHED, STATUS.CANCELED].includes(statuses[0]);
    return isCompleted ? 'completed' : 'processing';
  }

  get totalCount() {
    // @ts-expect-error
    let stats = this.args.stats?.lastSuccessful?.value?.created;

    if (!stats) {
      return {
        processing: 0,
        completed: 0,
      };
    }

    let { active, scheduled, suspended, total } = stats;

    let processing = active + scheduled + suspended;

    return {
      processing,
      completed: total - processing,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::Tabs': typeof InvoiceSubscriptionsTabs;
  }
}
