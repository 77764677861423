export default {
  "integration-item": "cQK",
  "icon-container": "cQG",
  "content": "cQr",
  "title-container": "cQb",
  "new-badge": "cQy",
  "title": "cQU",
  "description": "cQj",
  "no-attributes": "cQW",
  "connect-cta": "cQu"
};
