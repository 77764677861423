/* import __COLOCATED_TEMPLATE__ from './compact-header.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { PROVIDERS_MAP } from 'qonto/constants/insurance-hub';

interface InsuranceHubProductCompactHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceHubProductCompactHeaderComponent extends Component<InsuranceHubProductCompactHeaderSignature> {
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];

  @action
  onClick() {
    // @ts-expect-error
    let { hasActiveContract, product, onClick } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.segment.track('insurance-hub-offer-details_subscription-button_clicked', {
      country,
      isSubscribed: Boolean(hasActiveContract),
      product,
      provider: PROVIDERS_MAP[country].providerName,
    });

    onClick?.();
  }

  @action
  managePolicy() {
    // @ts-expect-error
    let { product } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.segment.track(
      'insurance-hub-offer-details_subscription-button_manage-policy-dropdown_clicked',
      {
        country,
        product,
        provider: PROVIDERS_MAP[country].providerName,
      }
    );
    this.router.transitionTo('insurance-hub.policies');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Product::CompactHeader': typeof InsuranceHubProductCompactHeaderComponent;
  }
}
