/* import __COLOCATED_TEMPLATE__ from './pagopa-attachment.hbs'; */
import Component from '@glimmer/component';

import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';

interface PagopaAttachmentSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PagopaAttachment extends Component<PagopaAttachmentSignature> {
  errorState = ErrorState;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PagopaAttachment: typeof PagopaAttachment;
  }
}
