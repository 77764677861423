/* import __COLOCATED_TEMPLATE__ from './confirm-revoke-member.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface ConfirmRrevokeMemberModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConfirmRrevokeMemberModal extends Component<ConfirmRrevokeMemberModalSignature> {
  @service declare errors: Services['errors'];

  confirmTask = dropTask(async (...args) => {
    try {
      // @ts-expect-error
      await this.args.data.confirmTask.linked().perform(...args);
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Member::Modal::ConfirmRevokeMember': typeof ConfirmRrevokeMemberModal;
  }
}
