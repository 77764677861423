export default {
  "option": "cgo",
  "account-description": "cgn",
  "account-identification": "cgi",
  "account-avatar": "cgA",
  "account-name": "cgY body-2",
  "account-balance": "cgh body-2",
  "external-accounts-disclaimer": "cgf",
  "status": "cgK body-2",
  "failed-connection": "cgG"
};
