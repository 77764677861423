/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface InContextIntegrationsSideDrawerBucketItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class InContextIntegrationsSideDrawerBucketItemComponent extends Component<InContextIntegrationsSideDrawerBucketItemSignature> {
  @service declare theme: Services['theme'];

  get icon() {
    // @ts-expect-error
    let { isCurrentThemeDark } = this.theme;
    // @ts-expect-error
    let { item } = this.args;

    return isCurrentThemeDark ? item.darkLogo : item.lightLogo;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::SideDrawer::Bucket::Item': typeof InContextIntegrationsSideDrawerBucketItemComponent;
  }
}
