/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';

import { ProviderLogo } from 'qonto/react/components/insurance-hub/provider-logo';

interface InsurancesHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsurancesHeaderComponent extends Component<InsurancesHeaderSignature> {
  badgeHighlight = BadgeHighlight;
  providerLogo = ProviderLogo;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Header': typeof InsurancesHeaderComponent;
  }
}
