/* import __COLOCATED_TEMPLATE__ from './activity-tag-select.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

// @ts-expect-error
import filterActivityTagCodes from 'qonto/utils/filter-activity-tag-codes';
// @ts-expect-error
import { sortByKey } from 'qonto/utils/sort-by-keys';

interface ActivityTagSelectSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ActivityTagSelectComponent extends Component<ActivityTagSelectSignature> {
  @service declare intl: Services['intl'];
  searchField = 'label';

  get errorMessage() {
    // @ts-expect-error
    let { errors } = this.args;
    return errors?.[0]?.message || errors;
  }

  get selectedActivityTagObject() {
    // @ts-expect-error
    let { value } = this.args;
    value = Array.isArray(value) ? value[0] : value; // value can be wrapped in array
    // @ts-expect-error
    return this.filteredActivityTagObjects.find(({ code }) => code === value);
  }

  @cached
  get filteredActivityTagObjects() {
    // @ts-expect-error
    let { transactionTypes, transactionType } = this.args;
    let filteredCodes = filterActivityTagCodes(transactionTypes, transactionType);

    // @ts-expect-error
    return filteredCodes.map(code => this._createActivityTagObject(code)).sort(sortByKey('label'));
  }

  @action
  // @ts-expect-error
  handleUpdate(activityTagObject) {
    let code = activityTagObject?.code;
    // @ts-expect-error
    return this.args.update(code);
  }

  // @ts-expect-error
  _createActivityTagObject(code) {
    if (!code) {
      return null;
    }

    let label = this.intl.t(`activities.${code}`);
    return { code, label };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::ActivityTagSelect': typeof ActivityTagSelectComponent;
  }
}
