/* import __COLOCATED_TEMPLATE__ from './cashflow.hbs'; */
import { schedule } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SkeletonLoader } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { PERIOD_KEYS } from 'qonto/constants/overview';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { PERIOD_RATES } from 'qonto/react/models/cash-flow-period';
import { CashflowTimevalue, utcToZonedDate } from 'qonto/utils/chart';
// @ts-expect-error
import { isPeriodWithinInterval } from 'qonto/utils/date';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

// @ts-expect-error
import styles from './cashflow.module.css';

interface CashflowSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isUpdating?: boolean;
    isError?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CashflowComponent extends Component<CashflowSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare abilities: Services['abilities'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare userManager: Services['userManager'];

  styles = styles;
  placeholderLine = SkeletonLoader.Line;

  // @ts-expect-error
  @tracked _selectedPeriod;
  // @ts-expect-error
  @reads('userManager.currentUser.timezone') timezone;
  // @ts-expect-error
  @reads('organizationManager.membership.id') membershipId;
  didComponentMount = false;

  periods = [
    {
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.last-twelve-months'),
      key: 'last_12_months',
      interval: 'last_12_months',
    },
    {
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.year-to-date'),
      key: 'year_to_date',
      interval: 'current_year',
    },
  ];

  constructor(owner: unknown, args: CashflowSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.storageKey = `cashflow-period-${this.membershipId}`;

    this._fetchPersistedPeriod();
    schedule('actions', () => {
      this.refreshWidgetTask.perform().catch(ignoreCancelation);
    });
  }

  get frequencyInterval() {
    // @ts-expect-error
    if (this.args.selectedFrequency === PERIOD_RATES.QUARTERLY) return 'quarter';

    return 'month';
  }

  get currentPeriod() {
    return { startDate: dayjs().startOf(this.frequencyInterval).toDate(), endDate: new Date() };
  }

  get aggregations() {
    return [
      {
        name: 'display_at_monthly',
        type: 'time',
        data: {
          property: 'display_at',
          interval: 'monthly',
        },
      },
    ];
  }

  get bankAccounts() {
    // @ts-expect-error
    return this.args.bankAccounts ? [this.args.bankAccounts] : undefined;
  }

  get selectedPeriod() {
    return this._selectedPeriod || this.periods[0];
  }

  get isCurrentPeriodDisplayed() {
    let isPresetPeriod = this.periods
      .map(period => period.interval)
      .includes(this.selectedPeriod.interval);
    let isWithinCustomPeriod =
      this.selectedPeriod.key === PERIOD_KEYS.CUSTOM_PERIOD &&
      isPeriodWithinInterval(this.currentPeriod, {
        start: this.selectedPeriod.startDate,
        end: this.selectedPeriod.endDate,
      });
    return isPresetPeriod || isWithinCustomPeriod;
  }

  get statistics() {
    // @ts-expect-error
    let { name } = this.aggregations[0];
    // @ts-expect-error
    let base = this.args.statistics;
    // @ts-expect-error
    let comparison = this.args.comparisonStatistics;
    // @ts-expect-error
    let isEmpty = statistics =>
      // @ts-expect-error
      !statistics || statistics.every(statistics => !Object.keys(statistics).length);

    return {
      // @ts-expect-error
      base: isEmpty(base) ? undefined : base?.map(({ data }) => data && data[name]),
      comparison: isEmpty(comparison)
        ? undefined
        : // @ts-expect-error
          comparison?.map(({ data }) => data && data[name]),
    };
  }

  get timeseries() {
    if (!(this.args.isLoading || this.args.isUpdating) && this.statistics.base) {
      let [credits, debits, balances] = this.statistics.base;
      let comparisonCredit, comparisonDebit;

      if (this.statistics.comparison) {
        let [[credit], [debit]] = this.statistics.comparison;
        comparisonCredit = credit;
        comparisonDebit = debit;
      }

      let formattedTimeseries = this._formatTimeseries(
        credits,
        debits,
        balances,
        comparisonCredit,
        comparisonDebit,
        this.timezone
      );

      return formattedTimeseries;
    }
  }

  get originDate() {
    return this.organizationManager.organization.createdAt;
  }

  updatePeriodTask = dropTask(async (period, closeDropdown) => {
    closeDropdown?.();
    if (period) {
      this._selectedPeriod = this._formatPeriod(period);
      this.persistPeriod(period);
      await this.refreshWidgetTask.perform(period).catch(ignoreCancelation);
      this.segment.track('dashboard_widget_update', {
        widget_type: 'combo_chart',
        period_set_to: period.key,
      });
    }
  });

  updateBankAccountsTask = dropTask(async () => {
    await this.refreshWidgetTask.perform().catch(ignoreCancelation);
  });

  refreshWidgetTask = dropTask(async (period = this.selectedPeriod) => {
    let comparisonPeriod = this.isCurrentPeriodDisplayed
      ? { interval: 'comparison_for_current_month' }
      : undefined;

    if (!(this.args.isLoading || this.args.isUpdating)) {
      try {
        // @ts-expect-error
        await this.args.onUpdate(this.aggregations, period, this.bankAccounts, comparisonPeriod);
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));

        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 401) {
          this.sentry.captureException(error);
        }
      }
    }
  });

  // @ts-expect-error
  _formatTimeseries(credits, debits, balances, lastMonthCredit, lastMonthDebit, timezone) {
    if (credits && debits && balances) {
      let startTime = utcToZonedDate(balances[0].start_date);
      let baseArray = Array.from({ length: balances.length }).map((_, index) => ({
        time: dayjs(startTime).add(index, this.frequencyInterval).toDate(),
      }));

      return baseArray.map(({ time }, index) => {
        let balance = balances[index] || this._getLastAvailableBalance(balances, time);
        let inflows = credits[index];
        let outflows = debits[index];
        let isCurrentPeriodBalance = balance.start_date === this.currentPeriod.startDate.getTime();

        let previousInflows = isCurrentPeriodBalance ? lastMonthCredit : credits[index - 1];
        let previousOutflows = isCurrentPeriodBalance ? lastMonthDebit : debits[index - 1];

        return new CashflowTimevalue(
          inflows,
          outflows,
          { ...balance, start_date: time },
          previousInflows,
          previousOutflows,
          timezone
        );
      });
    }
  }

  // @ts-expect-error
  _getLastAvailableBalance(balances, time) {
    return {
      ...balances.at(-1),
      start_date: time.getTime(),
      end_date: dayjs(time).endOf('month').valueOf(),
    };
  }

  // @ts-expect-error
  persistPeriod(period) {
    // @ts-expect-error
    safeLocalStorage.setItem(this.storageKey, JSON.stringify(period));
  }

  _fetchPersistedPeriod() {
    // @ts-expect-error
    let persistedPeriod = JSON.parse(safeLocalStorage.getItem(this.storageKey));
    let shouldResetPeriod =
      this.abilities.cannot('access advanced overview') &&
      persistedPeriod?.period?.key === PERIOD_KEYS.CUSTOM_PERIOD;

    if (shouldResetPeriod) {
      this._selectedPeriod = this.periods[0];
      this.persistPeriod(this.selectedPeriod);
    } else {
      this._selectedPeriod = this._formatPeriod(persistedPeriod);
    }
  }

  // @ts-expect-error
  _formatPeriod(period) {
    if (period) {
      let matchingPeriod = this.periods.find(localPeriod => localPeriod.key === period.key);
      period.label =
        matchingPeriod?.label || this.intl.t('overview.period-selection.custom-period');
      period.startDate = period.startDate ? new Date(period.startDate) : undefined;
      period.endDate = period.endDate ? new Date(period.endDate) : undefined;
      return period;
    }
  }

  triggerRerenderTask = restartableTask(async () => {
    /**
     * Here, we need to check if the component is mounted or not.
     * We need to trigger the task only when the component props are updated, not when we mount
     * the component for the first time (or when it reload)
     */
    if (!this.didComponentMount) {
      this.didComponentMount = true;
    } else {
      this.didComponentMount = false;
      await this.updateBankAccountsTask.perform();
    }
    // @ts-expect-error
    return this.args.bankAccounts;
  });

  // @ts-expect-error
  lastBankAccount = trackedTask(this, this.triggerRerenderTask, () => [this.args.bankAccounts]);
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::Cashflow': typeof CashflowComponent;
  }
}
