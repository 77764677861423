/* import __COLOCATED_TEMPLATE__ from './warning-membership.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface PopupSecurityUpdateEmailWarningMembershipSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PopupSecurityUpdateEmailWarningMembershipComponent extends Component<PopupSecurityUpdateEmailWarningMembershipSignature> {
  lottiePlayer = LottiePlayer;

  @service declare modals: Services['modals'];
  @service declare userManager: Services['userManager'];
  @service declare sentry: Services['sentry'];

  activateTask = dropTask(async () => {
    // @ts-expect-error
    let { close } = this.args;
    // @ts-expect-error
    let { userHasPassword, requestOTPTask } = this.args.data;
    // @ts-expect-error
    let { pendingEmail } = this.userManager.currentUser;

    try {
      await requestOTPTask.perform(pendingEmail, true);
    } catch (error) {
      close();
      // @ts-expect-error
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }

      return;
    }

    close();
    await this.modals.open('popup/security/email-verification', {
      userHasPassword,
      newEmailAddress: pendingEmail,
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Popup::Security::UpdateEmail::WarningMembership': typeof PopupSecurityUpdateEmailWarningMembershipComponent;
  }
}
