export default {
  "body": "cMG",
  "members": "cMr",
  "members-results": "cMb",
  "members-list": "cMy",
  "members-title": "cMU caption-bold",
  "member": "cMj",
  "empty-illustration": "cMW",
  "empty-title": "cMu title-3",
  "empty-description": "cPS body-2",
  "invitable-members": "cPc",
  "details": "cPq",
  "pagination-footer": "cPZ"
};
