/* import __COLOCATED_TEMPLATE__ from './month.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

import { CashflowPeriodRate, PERIOD_RATES } from 'qonto/react/models/cash-flow-period';

interface OverviewChartTooltipMonthSignature {
  Args: {
    isCurrent?: boolean;
    displayForecasts?: boolean;
    monthlyCashflow?: any;
    selectedFrequency?: CashflowPeriodRate;
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class OverviewChartTooltipMonthComponent extends Component<OverviewChartTooltipMonthSignature> {
  @service declare intl: Services['intl'];

  get monthlyCashflow() {
    let monthlyCashflow = this.args.monthlyCashflow;
    if (dayjs(monthlyCashflow.time).isAfter(Date.now()) && !this.args.displayForecasts) {
      return Object.keys(monthlyCashflow).forEach(key => {
        monthlyCashflow[key] = undefined;
      });
    } else {
      return monthlyCashflow;
    }
  }

  get title() {
    if (!this.monthlyCashflow) return '';

    let d = dayjs(this.monthlyCashflow.time).toDate();
    if (this.args.selectedFrequency === PERIOD_RATES.YEARLY) {
      return `${dayjs(d).year()}`;
    }

    if (this.args.selectedFrequency === PERIOD_RATES.QUARTERLY) {
      return `Q${dayjs(d).quarter()} ${dayjs(d).year()}`;
    }

    return dateToken({
      date: d.toISOString(),
      locale: this.intl.primaryLocale ?? 'en',
      token: 'month-year-l',
    });
  }

  get monthlyTotalTranslation() {
    if (this.args.selectedFrequency === PERIOD_RATES.YEARLY) {
      return this.args.isCurrent
        ? this.intl.t('overview.year-to-date')
        : this.intl.t('overview.yearly-total');
    }

    if (this.args.selectedFrequency === PERIOD_RATES.QUARTERLY) {
      return this.args.isCurrent
        ? this.intl.t('overview.quarter-to-date')
        : this.intl.t('overview.quarterly-total');
    }

    return this.args.isCurrent
      ? this.intl.t('overview.month-to-date')
      : this.intl.t('overview.monthly-total');
  }

  get period() {
    let labels = {
      monthly: this.intl.t('cash-flow.frequency-selector.month'),
      quarterly: this.intl.t('cash-flow.frequency-selector.quarter'),
      yearly: this.intl.t('cash-flow.frequency-selector.year'),
    };

    return this.args.selectedFrequency ? labels[this.args.selectedFrequency] : labels.monthly;
  }

  get startOfPeriod() {
    return this.intl.t('overview.start-of-period', { period: this.period });
  }

  get endOfPeriod() {
    if (this.args.isCurrent && !this.args.displayForecasts) {
      return this.intl.t('overview.current');
    }

    return this.intl.t('overview.end-of-period', { period: this.period });
  }

  get vatCopy() {
    if (this.monthlyCashflow.vatDue < 0) {
      return this.intl.t('cash-flow.graph.period-tooltip.vat.debit.title');
    }
    return this.intl.t('cash-flow.graph.period-tooltip.vat.credit.title');
  }

  get startingBalance() {
    if (this.isFutureTimeframe) {
      return this.monthlyCashflow.forecastedStartingBalance;
    }
    return this.monthlyCashflow.startingBalance;
  }

  get endingBalance() {
    if ((this.isCurrentTimeframe || this.isFutureTimeframe) && this.args.displayForecasts) {
      return this.monthlyCashflow.forecastedEndingBalance;
    }
    return this.monthlyCashflow.endingBalance;
  }

  get projectedNetChange() {
    return this.monthlyCashflow.projectedNetChange
      ? Number(this.monthlyCashflow.projectedNetChange)
      : this.monthlyCashflow.projectedNetChange;
  }

  get projectedEndingBalance() {
    return this.monthlyCashflow.projectedEndingBalance
      ? Number(this.monthlyCashflow.projectedEndingBalance)
      : this.monthlyCashflow.projectedEndingBalance;
  }

  get netChange() {
    if (this.isCurrentTimeframe && this.args.displayForecasts) {
      return this.monthlyCashflow.forecastedCurrentNetChange;
    }

    if (this.isFutureTimeframe && this.args.displayForecasts) {
      return this.monthlyCashflow.forecastedNetChange;
    }

    return this.monthlyCashflow.netChange;
  }

  get isCurrentTimeframe() {
    return this.monthlyCashflow.isCurrent;
  }

  get isFutureTimeframe() {
    return this.monthlyCashflow.isFuture;
  }

  get isPastTimeframe() {
    return !this.isCurrentTimeframe && !this.isFutureTimeframe;
  }

  get showCurrentForecastIndicator() {
    return this.args.displayForecasts && this.isCurrentTimeframe;
  }

  get showFutureForecastIndicator() {
    return this.args.displayForecasts && this.isFutureTimeframe;
  }

  get showEndBalance() {
    if (this.args.isCurrent && !this.args.displayForecasts) {
      return false;
    }
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Tooltip::Month': typeof OverviewChartTooltipMonthComponent;
  }
}
