/* import __COLOCATED_TEMPLATE__ from './quick-actions.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface QuickActionsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const QuickActions = templateOnlyComponent<QuickActionsSignature>();

export default QuickActions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentMethods::QuickActions': typeof QuickActions;
  }
}
