export default {
  "container": "cID",
  "mapped": "cIV",
  "header": "cIL",
  "title": "cIT caption",
  "select-option-label": "cIz",
  "content": "cIa",
  "item": "cIH body-2",
  "empty": "cIO"
};
