/* import __COLOCATED_TEMPLATE__ from './q-state.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface QStateSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const QState = templateOnlyComponent<QStateSignature>();

export default QState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QState: typeof QState;
  }
}
