/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
import { notifyPropertyChange } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { restartableTask, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

interface NotificationSettingsOptionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class NotificationSettingsOptionComponent extends Component<NotificationSettingsOptionSignature> {
  checkbox = Checkbox;

  @service declare intl: Services['intl'];

  translations = {
    'account.name': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.name'),
    },
    'account.activity': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.activity'),
    },
    'account.activity_general': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.activity_general'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.account.activity_general_description'),
    },
    'account.monthly_invoice_reporting': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.activity_invoices'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.account.activity_invoices_description'),
    },
    'account.activity_matching': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.activity_matching'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.account.activity_matching_description'),
    },
    'account.activity_reporting': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.activity_reporting'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.account.activity_reporting_description'),
    },
    'account.activity_transaction': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.activity_transaction'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.account.activity_transaction_description'),
    },
    'account.activity_comments': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.activity_comments.title'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.account.activity_comments.description'),
    },
    'account.transaction_failed': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.transaction_failed'),
    },
    'account.transaction_successful': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.account.transaction_successful'),
    },
    'budgets.name': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.budgets.name'),
    },
    'budgets.budgets': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.budgets.title'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.budgets.description'),
    },
    'card.activity': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.card.activity'),
    },
    'card.activity_general': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.card.activity_general'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.card.activity_general_description'),
    },
    'card.activity_transaction': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.card.activity_transaction'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.card.activity_transaction_description'),
    },
    'card.name': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.card.name'),
    },
    'card.payment_failed': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.card.payment_failed'),
    },
    'card.payment_successful': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.card.payment_successful'),
    },
    'card.settings': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.card.settings'),
    },
    'check.name': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.check.name'),
    },
    'check.check': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.check.check'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.check.check_description'),
    },
    'company.activity': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.company.activity'),
    },
    'company.name': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.company.name'),
    },
    'company.team': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.company.team'),
    },
    'request.name': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.request.name'),
    },
    'request.transfer': {
      // @ts-expect-error
      title: this.intl.t('users.subscriptions.request.transfer'),
      // @ts-expect-error
      description: this.intl.t('users.subscriptions.request.transfer_description'),
    },
  };

  get notificationOptions() {
    let key = 'account.name';

    // @ts-expect-error
    if (this.args.catCode && this.args.option.notCode) {
      // @ts-expect-error
      key = `${this.args.catCode}.${this.args.option.notCode}`;
    }

    // @ts-expect-error
    return this.translations[key];
  }

  handleUpdateTask = restartableTask(async attribute => {
    // @ts-expect-error
    this.args.option.devices[attribute] = !this.args.option.devices[attribute];
    // @ts-expect-error
    notifyPropertyChange(this.args.option.devices, attribute);
    await timeout(DEBOUNCE_MS);
    // @ts-expect-error
    await this.args.option.save({
      adapterOptions: {
        // @ts-expect-error
        headers: { 'X-Qonto-Organization-ID': this.args.organization.id },
      },
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsSettings::Option': typeof NotificationSettingsOptionComponent;
  }
}
