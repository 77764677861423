/* import __COLOCATED_TEMPLATE__ from './label-list-icon.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

interface LabelListIconSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class LabelListIconComponent extends Component<LabelListIconSignature> {
  // @ts-expect-error
  @reads('args.labelList.name') name;

  get style() {
    // @ts-expect-error
    let color = this.args.labelList.color;

    return htmlSafe(`background-color: ${color}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LabelListIcon: typeof LabelListIconComponent;
  }
}
