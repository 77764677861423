/* import __COLOCATED_TEMPLATE__ from './balance.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import buildQuery from 'qonto/utils/statistics';

interface OverviewStatisticsBalanceSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewStatisticsBalanceComponent extends Component<OverviewStatisticsBalanceSignature> {
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  @tracked balances = [];

  get isLoading() {
    return this.queryBalanceTask.isRunning && this.queryBalanceTask.performCount === 1;
  }

  get isUpdating() {
    return this.queryBalanceTask.isRunning && this.queryBalanceTask.performCount > 1;
  }

  get isError() {
    return this.getBalanceTask.last?.isError;
  }

  queryBalanceTask = dropTask(async (aggregations, period, bankAccounts) => {
    try {
      await this.getBalanceTask.linked().perform(aggregations, period, bankAccounts);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(errorInfo.error);
      }
    }
  });

  getBalanceTask = dropTask(async (aggregations, period, bankAccounts) => {
    let organizationId = this.organizationManager.organization.id;
    let query = buildQuery(organizationId, aggregations, period, undefined, bankAccounts);

    // @ts-expect-error
    this.balances = await this.store.adapterFor('transaction').getBalance(query);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Statistics::Balance': typeof OverviewStatisticsBalanceComponent;
  }
}
