/* import __COLOCATED_TEMPLATE__ from './multiple.hbs'; */
import { action } from '@ember/object';
import { inject as service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox, Disclaimer } from '@repo/design-system-kit';

interface PowerSelectAccountsMultipleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PowerSelectAccountsMultiple extends Component<PowerSelectAccountsMultipleSignature> {
  @service declare intl: Services['intl'];
  @tracked _selectedAccounts = [];
  @tracked _initialAccounts = [];

  checkbox: typeof Checkbox = Checkbox;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  constructor(owner: unknown, args: PowerSelectAccountsMultipleSignature['Args']) {
    super(owner, args);
    // Initialize internal state with mapped accounts
    // @ts-expect-error
    this._selectedAccounts = this._mapAccounts(
      // @ts-expect-error
      !this.args.selectedAccounts?.length ? this.args.accounts : this.args.selectedAccounts
    );
  }

  get triggerDisplay() {
    let count = this._selectedAccounts.length;
    // @ts-expect-error
    if (count === 0 || count === this.args.accounts.length) {
      return this.intl.t('bank_accounts.header.all-accounts');
    }

    if (count === 1) {
      // @ts-expect-error
      return this._selectedAccounts[0].value;
    }

    return this.intl.t('bank_accounts.header.accounts', { count: this._selectedAccounts.length });
  }

  get selectedAccounts() {
    return this._selectedAccounts;
  }

  get accounts() {
    // @ts-expect-error
    return this._mapAccounts(this.args.accounts ?? []);
  }

  get allAccountSelected() {
    // @ts-expect-error
    return this._selectedAccounts.length === this.args.accounts.length;
  }

  @action
  onOpen() {
    this._initialAccounts = [...this._selectedAccounts];
    // @ts-expect-error
    this.args.onOpen?.();
  }

  @action
  // @ts-expect-error
  onChange(change) {
    // @ts-expect-error
    let keys = change.map(account => account.key);
    // @ts-expect-error
    let dupes = keys.filter((id, index) => keys.indexOf(id) !== index);
    // @ts-expect-error
    this._selectedAccounts = change.filter(account => !dupes.includes(account.key));
  }

  @action
  // @ts-expect-error
  onChangeSelectAll(checked) {
    // @ts-expect-error
    this._selectedAccounts = checked ? this.accounts : [];
  }

  @action
  onUpdateAccounts() {
    // @ts-expect-error
    let initialAccountIds = this._initialAccounts.map(account => account.key);
    // @ts-expect-error
    let selectedAccountIds = this.selectedAccounts.map(account => account.key);

    let addedAccounts = selectedAccountIds.filter(id => !initialAccountIds.includes(id));
    let removedAccounts = initialAccountIds.filter(id => !selectedAccountIds.includes(id));

    if (!addedAccounts.length && !removedAccounts.length) return;

    // @ts-expect-error
    let selectedAccounts = this.args.accounts.filter(account =>
      selectedAccountIds.includes(account.id)
    );

    // @ts-expect-error
    this.args.onUpdateAccounts(selectedAccounts);
  }

  _mapAccounts(accounts = []) {
    return accounts.map(
      ({
        id,
        name,
        status,
        safeName,
        logo,
        icon,
        authorizedBalance,
        balanceCurrency,
        hasFailedConnection,
      }) => ({
        id,
        key: id,
        value: name,
        status,
        safeName,
        logo,
        icon,
        authorizedBalance,
        balanceCurrency,
        hasFailedConnection,
      })
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Accounts::Multiple': typeof PowerSelectAccountsMultiple;
  }
}
