/* import __COLOCATED_TEMPLATE__ from './mfa-options.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { MFA_METHODS } from '@qonto/qonto-sca/constants/mfa';
import { dropTask } from 'ember-concurrency';

interface PopupSecurityMfaOptionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PopupSecurityMfaOptionsComponent extends Component<PopupSecurityMfaOptionsSignature> {
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare userManager: Services['userManager'];

  selectMethodTask = dropTask(async option => {
    try {
      // @ts-expect-error
      await this.args.data.selectMethodTask.perform(option);
    } catch (error) {
      this.toastFlashMessages.toastError(
        // @ts-expect-error
        this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
      );
    }
  });

  titleTranslationMap = {
    // @ts-expect-error
    [MFA_METHODS.SMS]: this.intl.t('users.profile.modals.mfa-options.options.by_sms.title'),
    // @ts-expect-error
    [MFA_METHODS.EMAIL]: this.intl.t('users.profile.modals.mfa-options.options.by_email.title'),
  };

  subtitleTranslationMap = {
    // @ts-expect-error
    [MFA_METHODS.SMS]: this.intl.t('users.profile.modals.mfa-options.options.by_sms.subtitle', {
      // @ts-expect-error
      phoneNumber: this.userManager.currentUser.phoneNumber,
    }),
    // @ts-expect-error
    [MFA_METHODS.EMAIL]: this.intl.t('users.profile.modals.mfa-options.options.by_email.subtitle', {
      // @ts-expect-error
      email: this.userManager.currentUser.email,
    }),
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Popup::Security::MfaOptions': typeof PopupSecurityMfaOptionsComponent;
  }
}
