/* import __COLOCATED_TEMPLATE__ from './address.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

interface InvitationsNewUserAddressSignature {
  // The arguments accepted by the component
  Args: {
    isMobile?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvitationsNewUserAddressComponent extends Component<InvitationsNewUserAddressSignature> {
  @service declare intl: Services['intl'];

  /** @type string | undefined */
  // @ts-expect-error
  @tracked fiscalCodeError;

  // @ts-expect-error
  @reads('args.invite.address.didValidate') isAddressValidationEnabled;

  saveInviteTask = dropTask(async invite => {
    // @ts-expect-error
    this.args.invite.set('didValidate', false);
    // @ts-expect-error
    this.args.invite.address.set('didValidate', false);

    let { validations: addressValidation } = await invite.address.validate();

    // @ts-expect-error
    this.args.invite.set('didValidate', true);
    // @ts-expect-error
    this.args.invite.address.set('didValidate', true);

    let fiscalCodeValid = !this.needsFiscalCode || invite.italianFiscalCode;
    if (!fiscalCodeValid) {
      this.fiscalCodeError = this.intl.t('validations.errors.blank');
    }
    if (addressValidation.isValid && fiscalCodeValid) {
      try {
        // @ts-expect-error
        await this.args.saveInvite(invite);
      } catch (error) {
        // @ts-expect-error
        if (error.errors.some(e => e.detail?.source?.pointer === '/italian_fiscal_code')) {
          this.fiscalCodeError = this.intl.t('validations.errors.codice-fiscale-generic', {
            htmlSafe: true,
          });
        }
      }
    }
  });

  @action
  // @ts-expect-error
  handleSelectAddress(address, _, event) {
    // @ts-expect-error
    this.args.invite.address.setProperties({
      firstLine: address.street,
      zipcode: address.postalCode,
      city: address.city,
      country: address.countryCode?.toUpperCase(),
    });

    // Prevent form to be submitted on address select
    // `event` is keyboard event when triggered with keyboard and power-select obj when selected with mouse: https://github.com/cibernox/ember-power-select/pull/1386
    event.preventDefault?.();
  }

  @action
  // @ts-expect-error
  updateFiscalCode(fiscalCode) {
    // @ts-expect-error
    this.args.invite.set('italianFiscalCode', fiscalCode);
    this.fiscalCodeError = undefined;
  }

  get needsFiscalCode() {
    // @ts-expect-error
    return this.args.invite.organizationLegalCountry === 'IT';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvitationsNewUser::Address': typeof InvitationsNewUserAddressComponent;
  }
}
