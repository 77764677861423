/* import __COLOCATED_TEMPLATE__ from './invoice-tab.hbs'; */
import Component from '@glimmer/component';

import { TextAreaField } from '@repo/design-system-kit';

interface InvoiceSettingsFormInvoiceTabSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsFormInvoiceTabComponent extends Component<InvoiceSettingsFormInvoiceTabSignature> {
  textAreaField = TextAreaField;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::InvoiceTab': typeof InvoiceSettingsFormInvoiceTabComponent;
  }
}
