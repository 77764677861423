/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { EmptyStatesInform } from '@repo/domain-kit/pricing';

import { Placeholder } from 'qonto/react/components/payment-links/table/placeholder';

interface TableSignature {
  Element: null;
  Args: {
    isLoading?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class PaymentLinksTableComponent extends Component<TableSignature> {
  placeholder = Placeholder;

  emptyStatesInform = EmptyStatesInform;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Table': typeof PaymentLinksTableComponent;
  }
}
