/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeStatus, Disclaimer } from '@repo/design-system-kit';

// @ts-expect-error
import { INSURANCE_CONTRACT_STATUS, PROVIDERS_MAP } from 'qonto/constants/insurance-hub';
import { ProviderLogo } from 'qonto/react/components/insurance-hub/provider-logo';
import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';
import {
  getInsuranceContractPaymentFrequency,
  getInsuranceContractStatusProps,
  // @ts-expect-error
} from 'qonto/utils/insurance-hub/insurance-contract';

interface InsuranceHubSidebarOverviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceHubSidebarOverviewComponent extends Component<InsuranceHubSidebarOverviewSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  badgeStatus = BadgeStatus;
  CopyToClipboard = CopyToClipboard;
  providerLogo = ProviderLogo;

  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];

  get insuranceContractStatusProps() {
    // @ts-expect-error
    let { status } = this.args.insuranceContract;

    return getInsuranceContractStatusProps({ intl: this.intl, status });
  }

  get paymentFrequency() {
    // @ts-expect-error
    let { paymentFrequency } = this.args.insuranceContract;

    return getInsuranceContractPaymentFrequency({
      intl: this.intl,
      frequency: paymentFrequency,
    });
  }

  get hasInsuranceProviderData() {
    // @ts-expect-error
    return this.args.insuranceProvider?.name && this.args.insuranceProvider?.logo;
  }

  get disclaimerLevel() {
    // @ts-expect-error
    let { status } = this.args.insuranceContract;

    switch (status) {
      case INSURANCE_CONTRACT_STATUS.ACTIVE:
      case INSURANCE_CONTRACT_STATUS.PENDING_OTHERS:
      case INSURANCE_CONTRACT_STATUS.EXPIRED:
        return 'info';
      case INSURANCE_CONTRACT_STATUS.ACTION_REQUIRED:
      case INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT:
        return 'warning';
      default:
        return 'info';
    }
  }

  get canManagePolicy() {
    // @ts-expect-error
    let insuranceCreatorMembershipId = this.args.insuranceContract.membership.id;
    let currentMembershipId = this.organizationManager.membership.id;
    return insuranceCreatorMembershipId === currentMembershipId;
  }

  get disclaimerText() {
    // @ts-expect-error
    let { status, expirationDate, membership } = this.args.insuranceContract;

    if (!this.canManagePolicy) {
      return this.intl.t('insurance-hub.policies.details.disclaimer.unauthorized-user', {
        name: membership.get('fullName'),
      });
    }

    switch (status) {
      case INSURANCE_CONTRACT_STATUS.ACTION_REQUIRED:
        return this.intl.t('insurance-hub.policies.details.disclaimer.action-required.eligibility');
      case INSURANCE_CONTRACT_STATUS.PENDING_PAYMENT:
        return this.intl.t('insurance-hub.policies.details.disclaimer.action-required.payment');
      case INSURANCE_CONTRACT_STATUS.ACTIVE:
        return this.intl.t('insurance-hub.policies.details.disclaimer.active');
      case INSURANCE_CONTRACT_STATUS.PENDING_OTHERS:
        return this.intl.t('insurance-hub.policies.details.disclaimer.scheduled');
      case INSURANCE_CONTRACT_STATUS.EXPIRED:
        return this.intl.t('insurance-hub.policies.details.disclaimer.expired', {
          expiryDate: dateToken({
            date: expirationDate,
            token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
            // @ts-expect-error
            locale: this.localeManager.locale,
          }),
        });
      default:
        return '';
    }
  }

  get url() {
    // @ts-expect-error
    let { serviceUrl, troubleshootingUrl } = this.args.insuranceContract;

    return troubleshootingUrl || serviceUrl;
  }

  get trackOnClickProps() {
    // @ts-expect-error
    let { contractId, status, uid } = this.args.insuranceContract;
    let country = this.organizationManager.organization.legalCountry;

    return {
      policy_number: contractId,
      product: uid,
      policy_status: status,
      partner: PROVIDERS_MAP[country].providerName,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Sidebar::Overview': typeof InsuranceHubSidebarOverviewComponent;
  }
}
