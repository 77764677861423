/* import __COLOCATED_TEMPLATE__ from './label-select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

// @ts-expect-error
import { sortByKey } from 'qonto/utils/sort-by-keys';

interface LabelSelectSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class LabelSelectComponent extends Component<LabelSelectSignature> {
  // @ts-expect-error
  placeholder = this.args.placeholder || 'placeholders.all';

  get sortedListLabels() {
    // @ts-expect-error
    return [...this.args.labelList.labels].sort(sortByKey('name'));
  }

  // @ts-expect-error
  @action handleUpdateLabel(label) {
    // @ts-expect-error
    return this.args.saveLabel(this.args.labelList, label);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LabelSelect: typeof LabelSelectComponent;
  }
}
