/* import __COLOCATED_TEMPLATE__ from './confirm-unrevoke-member.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer, LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ConfirmUnrevokeMemberModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConfirmUnrevokeMemberModal extends Component<ConfirmUnrevokeMemberModalSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  lottiePlayer = LottiePlayer;

  @service declare errors: Services['errors'];
  @service declare segment: Services['segment'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];

  confirmTask = dropTask(async () => {
    // @ts-expect-error
    let { confirmTask, membership } = this.args.data;

    try {
      await confirmTask.linked().perform(membership);
    } catch (error) {
      if (hasMFAError(error)) {
        throw error;
      }
      // @ts-expect-error
      if (error.status === 422 && error.errors?.[0]?.source?.pointer === '/data/attributes/email') {
        this.toastFlashMessages.toastError(
          this.intl.t('members.revoke.errors.unrevoke-duplicated-member')
        );
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
    // @ts-expect-error
    this.args.close();
  });

  get showUnrevokeManagerDisclaimer() {
    // @ts-expect-error
    let { membership } = this.args.data;
    let isManager = Boolean(membership.customPermissions);
    if (!isManager) {
      return false;
    }

    return !membership.hasActiveAllowedBankAccounts;
  }

  @action
  confirm() {
    // @ts-expect-error
    this.sensitiveActions.runTask.perform(this.confirmTask).catch(error => {
      this.errors.handleError(error);
    });
  }

  @action
  reactivateMember() {
    this.segment.track('upsell_reactivate-paid-member_clicked', {
      // @ts-expect-error
      price_plan: this.args.data.pricePlanCode,
    });

    // @ts-expect-error
    this.args.close();

    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'plans',
      queryParams: { lineup: PRICE_PLAN_LINEUPS.TEAMS },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Member::Modal::ConfirmUnrevokeMember': typeof ConfirmUnrevokeMemberModal;
  }
}
