/* import __COLOCATED_TEMPLATE__ from './paginated-table.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import { QPageSelectorPlaceholder } from 'qonto/react/components/q-page-selector/placeholder';
import { QPageSelector } from 'qonto/react/shared/components/q-page-selector';

interface PaginatedTableSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaginatedTableComponent extends Component<PaginatedTableSignature> {
  qPageSelector = QPageSelector;

  qPageSelectorPlaceholder = QPageSelectorPlaceholder;

  // @ts-expect-error
  placeholderRows = this.args.placeholderRows ?? 8;
  // @ts-expect-error
  showNavLoading = this.args.showNavLoading ?? true;

  get shouldShowFooter() {
    // @ts-expect-error
    let { total_count, per_page } = this.args?.navigation ?? {};

    return total_count > per_page || total_count > 25;
  }

  get sortField() {
    // @ts-expect-error
    return this.args.sortBy?.split(':')[0];
  }

  get sortOrder() {
    // @ts-expect-error
    let { sortBy } = this.args;

    return sortBy ? sortBy.split(':')[1] : DEFAULT_SORT_ORDER;
  }

  @action
  // @ts-expect-error
  onScroll(isScrolledTop) {
    // @ts-expect-error
    this.args.onScroll?.(isScrolledTop);
  }

  @action
  // @ts-expect-error
  handleSortBy(name) {
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      // @ts-expect-error
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    // @ts-expect-error
    return this.args.handleSortBy?.(`${name}:${sortOrder}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PaginatedTable: typeof PaginatedTableComponent;
  }
}
