/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { INVOICE_SUBSCRIPTION_STATUS_COLORS, STATUS } from 'qonto/constants/invoice-subscriptions';
import { Frequency } from 'qonto/react/components/invoice-subscriptions/frequency';

interface InvoiceSubscriptionsTableRowSignature {
  // The arguments accepted by the component
  Args: {
    isSelected?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class InvoiceSubscriptionsTableRow extends Component<InvoiceSubscriptionsTableRowSignature> {
  frequency = Frequency;

  tag = Tag;

  @service declare intl: Services['intl'];

  get #statusCopy() {
    return {
      [STATUS.SCHEDULED]: this.intl.t('recurring-invoices.statuses.scheduled'),
      [STATUS.ACTIVE]: this.intl.t('recurring-invoices.statuses.active'),
      [STATUS.FINISHED]: this.intl.t('recurring-invoices.statuses.completed'),
      [STATUS.CANCELED]: this.intl.t('recurring-invoices.statuses.canceled'),
      [STATUS.SUSPENDED]: this.intl.t('recurring-invoices.statuses.suspended'),
    };
  }

  get isGreyedOut() {
    // @ts-expect-error
    return [STATUS.CANCELED, STATUS.SUSPENDED].includes(this.args.item.status);
  }

  get status() {
    // @ts-expect-error
    let { status } = this.args.item;

    return {
      displayStatus: this.#statusCopy[status],
      // @ts-expect-error
      color: INVOICE_SUBSCRIPTION_STATUS_COLORS[status],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::Table::Row': typeof InvoiceSubscriptionsTableRow;
  }
}
