/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PlaceholderSignature {
  Element: HTMLTableRowElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Placeholder = templateOnlyComponent<PlaceholderSignature>();

export default Placeholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Nrc::Table::Placeholder': typeof Placeholder;
  }
}
