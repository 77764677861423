export default {
  "details-box": "clo",
  "picto": "cln",
  "picto-status": "cli",
  "ghost": "clA",
  "primary-info": "clY",
  "date": "clh",
  "account-infobox": "clf",
  "declined-disclaimer": "clK",
  "nrc-download": "clG"
};
