export default {
  "wrapper": "cJe",
  "cashflow-status": "cJB",
  "status": "cJE",
  "estimated": "cJd",
  "vat-due-amount": "cJI title-3",
  "vat-tooltip": "cJt",
  "icon-info": "cJM",
  "charts-wrapper": "cJP",
  "chart-error": "cJl",
  "chart-controls": "cJX",
  "amount-loading": "cJC"
};
