/* import __COLOCATED_TEMPLATE__ from './collapsible-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { ContentCategory } from 'qonto/react/receivable-invoices/components/invoice-settings/content-category';
import { FEATURES } from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';

interface InvoiceSettingsCollapsibleFormSignature {
  // The arguments accepted by the component
  Args: {
    settings: any;
    showPopup: (feature: string) => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsCollapsibleFormComponent extends Component<InvoiceSettingsCollapsibleFormSignature> {
  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  badge = Badge;
  contentCategory = ContentCategory;

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial('ar_advanced_customization') || this.hasInitialTrial
    );
  }

  get badgeType() {
    return this.hasAvailableFreeTrial ? 'tryForFree' : 'upgrade';
  }

  @action
  showCustomizationPopup() {
    this.segment.track('content_customization-clicked', { availability: 'not-available' });
    this.args.showPopup(FEATURES.CUSTOMIZATION);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::CollapsibleForm': typeof InvoiceSettingsCollapsibleFormComponent;
  }
}
