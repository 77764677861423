/* import __COLOCATED_TEMPLATE__ from './donut.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface OverviewChartDonutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewChartDonutComponent extends Component<OverviewChartDonutSignature> {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked tooltipTarget;
  // @ts-expect-error
  @tracked tooltipData;

  get hasData() {
    // Empty state != no data at all
    // @ts-expect-error
    return Array.isArray(this.args.data);
  }

  get dataLabels() {
    // @ts-expect-error
    let { data, groupByType } = this.args;

    // @ts-expect-error
    return data.map(({ name }) => {
      if (name === 'other') {
        return this.intl.t('overview.other');
      } else if (name === 'uncategorized') {
        if (groupByType.key === 'initiator_id') {
          return this.intl.t('overview.uncategorized.team-member');
        } else if (groupByType.key === 'team_id') {
          return this.intl.t('overview.uncategorized.team');
        } else if (groupByType.key === 'label_lists') {
          return this.intl.t('overview.uncategorized.custom-label', {
            customLabelCategory: groupByType.label,
          });
        }
      }
      return name;
    });
  }

  get isCustomLabelListUncategorized() {
    // @ts-expect-error
    let { data, groupByType } = this.args;

    return groupByType?.key === 'label_lists' && data?.[0].name === 'uncategorized';
  }

  get dataLocalClasses() {
    // @ts-expect-error
    let { data } = this.args;
    let localeClassesMap = {
      other: 'donut-chart-legend-other-item',
      uncategorized: 'donut-chart-legend-uncategorized-item',
    };

    // @ts-expect-error
    return data.map(({ name }) => localeClassesMap[name] || 'donut-chart-legend-item');
  }

  @action
  // @ts-expect-error
  handleHighlight(target, data) {
    this.tooltipTarget = target;
    this.tooltipData = data;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Donut': typeof OverviewChartDonutComponent;
  }
}
