export default {
  "wrapper": "cex",
  "content": "cew",
  "back": "ceN btn btn--tertiary",
  "heading": "cev",
  "heading-content": "ceo",
  "logo": "cen",
  "description": "cei body-2",
  "cta": "ceA"
};
