/* import __COLOCATED_TEMPLATE__ from './sidebar-box-placeholder.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SidebarBoxPlaceholderSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SidebarBoxPlaceholder = templateOnlyComponent<SidebarBoxPlaceholderSignature>();

export default SidebarBoxPlaceholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::SidebarBoxPlaceholder': typeof SidebarBoxPlaceholder;
  }
}
