export default {
  "row": "cVI",
  "cell": "cVt",
  "title": "cVM",
  "internal-note": "cVP",
  "unit": "cVl",
  "price": "cVX",
  "quick-actions": "cVC",
  "missing-details-container": "cVk",
  "missing-details-label": "cVJ body-2",
  "warning-icon": "cVs",
  "empty": "cVF",
  "checkbox": "cVg",
  "align-checkbox": "cVD"
};
