/* import __COLOCATED_TEMPLATE__ from './bank-accounts-multiple-select.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BankAccountsMultipleSelectSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const BankAccountsMultipleSelect = templateOnlyComponent<BankAccountsMultipleSelectSignature>();

export default BankAccountsMultipleSelect;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::BankAccountsMultipleSelect': typeof BankAccountsMultipleSelect;
  }
}
