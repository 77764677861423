/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ItemCardDropdownSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ItemCardDropdownComponent extends Component<ItemCardDropdownSignature> {
  @action
  // @ts-expect-error
  toggleActionsDropdown(showActionsMenu) {
    if (showActionsMenu) {
      // @ts-expect-error
      this.args.onOpen?.();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ItemCard::Dropdown': typeof ItemCardDropdownComponent;
  }
}
