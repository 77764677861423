/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FiltersSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Filters = templateOnlyComponent<FiltersSignature>();

export default Filters;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Filters': typeof Filters;
  }
}
