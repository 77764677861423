/* import __COLOCATED_TEMPLATE__ from './subtitle.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SubtitleSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Subtitle = templateOnlyComponent<SubtitleSignature>();

export default Subtitle;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Subtitle': typeof Subtitle;
  }
}
