import { registerDestructor } from '@ember/destroyable';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface NavigationGuardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

/**
 * When this component is rendered, it is guarding from unintended navigation events
 * like page refresh or redirection by showing a confirmation prompt.
 *
 * ? You can pass following arguments:
 * - `allowRouterTransitions` - will not guard against ember transitions
 * - `allowBrowserUnload` - will not guard against browser refresh/close
 *
 * https://gitlab.qonto.co/tech/divein/-/blob/master/text/2021/2021-10-19-FRONTEND-navigation-guard.md
 */
export default class NavigationGuardComponent extends Component<NavigationGuardSignature> {
  @service('navigation-guard')
  declare navigationGuardService: Services['navigationGuardService'];

  constructor(owner: unknown, args: NavigationGuardSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let guardFn = this.args.guardFn ?? (() => true);

    // @ts-expect-error
    this.navigationGuardService.useNativeModal(this.args.useNativeModal ?? true);
    // @ts-expect-error
    this.navigationGuardService.addGuard(guardFn, {
      // @ts-expect-error
      allowRouterTransitions: this.args.allowRouterTransitions,
      // @ts-expect-error
      allowBrowserUnload: this.args.allowBrowserUnload,
    });

    // @ts-expect-error
    registerDestructor(this, () => this.navigationGuardService.removeGuard(guardFn));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    NavigationGuard: typeof NavigationGuardComponent;
  }
}
