/* import __COLOCATED_TEMPLATE__ from './label-tag.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LabelTagSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const LabelTag = templateOnlyComponent<LabelTagSignature>();

export default LabelTag;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LabelTag: typeof LabelTag;
  }
}
