/* import __COLOCATED_TEMPLATE__ from './single.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const EXTERNAL_ACCOUNTS_EXCLUDED_DISCLAIMER = {
  isExternalAccountsDisclaimer: true,
  disabled: true,
};

interface PowerSelectAccountsSingleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PowerSelectAccountsSingle extends Component<PowerSelectAccountsSingleSignature> {
  // @ts-expect-error
  @tracked state;
  // @ts-expect-error
  @tracked lastHighlighted;

  constructor(owner: unknown, args: PowerSelectAccountsSingleSignature['Args']) {
    super(owner, args);
    assert(
      'You must pass an `onUpdateAccount` action to <PowerSelect::Accounts::Single>',
      // @ts-expect-error
      this.args.onUpdateAccount
    );
    assert(
      'You must pass a `selectedAccount` to <PowerSelect::Accounts::Single>',
      // @ts-expect-error
      this.args.selectedAccount
    );
    assert(
      'You must pass an `accounts` array to <PowerSelect::Accounts::Single>',
      // @ts-expect-error
      this.args.accounts
    );
  }

  @action
  // @ts-expect-error
  registerAPI(state) {
    if (state) {
      this.state = state;
    }
  }

  @action
  // @ts-expect-error
  hoverActions(e) {
    if (!this.state) return;

    if (e.type === 'mouseenter') {
      this.lastHighlighted = this.state.highlighted;
      this.state.actions.highlight(null);
    }

    if (e.type === 'mouseleave') {
      this.state.actions.highlight(this.lastHighlighted);
    }
  }

  get accounts() {
    // @ts-expect-error
    let accounts = this.args.accounts;

    // @ts-expect-error
    if (this.args.showExternalAccountsDisclaimer) {
      accounts.push(EXTERNAL_ACCOUNTS_EXCLUDED_DISCLAIMER);
    }

    return accounts;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Accounts::Single': typeof PowerSelectAccountsSingle;
  }
}
