export default {
  "custom-period-wrapper": "cCG",
  "fadeIn": "cCr",
  "custom-period-description": "cCb body-2",
  "header": "cCy",
  "arrow-left": "cCU",
  "body": "cCj",
  "period-wrapper": "cCW",
  "back-button": "cCu",
  "footer": "ckS"
};
