export default {
  "navigation-dropdown": "cPH",
  "expanded": "cPO",
  "trigger": "cPm",
  "trigger-title-icon": "cPp",
  "collapsed": "cPx",
  "responsive": "cPw",
  "logo": "cPN",
  "active": "cPv",
  "trigger-title": "cPo",
  "content": "cPn",
  "content-container": "cPi",
  "dropdown-icon": "cPA",
  "link": "cPY"
};
