export default {
  "bucket-item": "cQm",
  "button": "cQp",
  "content": "cQx",
  "bucket-icon": "cQw",
  "bucket-title-container": "cQN",
  "bucket-new-badge": "cQv",
  "bucket-title": "cQo",
  "arrow-icon": "cQn"
};
