/* import __COLOCATED_TEMPLATE__ from './importing-popover.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ImportingPopoverSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ImportingPopoverComponent extends Component<ImportingPopoverSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  constructor(owner: unknown, args: ImportingPopoverSignature['Args']) {
    super(owner, args);
    this.onPreviewFile = this.onPreviewFile.bind(this);
  }

  get uploadingState() {
    // @ts-expect-error
    let { files = [] } = this.args;

    let { filesFailed, filesUploading, filesUploaded } = files.reduce(
      // @ts-expect-error
      (counts, file) => {
        if (file.hasErrors) {
          counts.filesFailed++;
        } else if (file.isUploading || file.isProcessing) {
          counts.filesUploading++;
        } else {
          counts.filesUploaded++;
        }
        return counts;
      },
      { filesFailed: 0, filesUploading: 0, filesUploaded: 0 }
    );
    if (filesUploading > 0) {
      return this.getFileStatusCopy('in-progress', filesUploading);
    } else if (filesFailed) {
      return this.getFileStatusCopy('errors', filesFailed);
    } else {
      return this.getFileStatusCopy('complete', filesUploaded);
    }
  }

  // @ts-expect-error
  getFileStatusCopy(status, count) {
    // @ts-expect-error
    let tag = this.args.copies[status];
    return this.intl.t(tag, { count });
  }

  @action
  handleClose() {
    // @ts-expect-error
    this.args.onClose?.();
  }

  // @ts-expect-error
  onPreviewFile(file) {
    // @ts-expect-error
    this.args.onPreviewFile?.(file);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Popover::ImportingPopover': typeof ImportingPopoverComponent;
  }
}
