/* import __COLOCATED_TEMPLATE__ from './month-dropdown.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { eachMonthOfInterval } from 'qonto/utils/date';

interface CustomPeriodMonthDropdownSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CustomPeriodMonthDropdown extends Component<CustomPeriodMonthDropdownSignature> {
  get months() {
    // @ts-expect-error
    return eachMonthOfInterval(new Date(this.args.originDate), new Date()).reverse();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::PeriodSelector::CustomPeriod::MonthDropdown': typeof CustomPeriodMonthDropdown;
  }
}
