export default {
  "card": "cXa",
  "header": "cXH",
  "avatar": "cXO",
  "title": "cXm",
  "subtitle": "cXp",
  "label": "cXx",
  "item": "cXw body-2",
  "header-icon": "cXN",
  "mt-8": "cXv"
};
