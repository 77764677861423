/* import __COLOCATED_TEMPLATE__ from './navigation-dropdown.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

// @ts-expect-error
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import { NavigationDropdownFooter } from 'qonto/react/components/navigation-dropdown/footer';

interface NavigationDropdownSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class NavigationDropdownComponent extends Component<NavigationDropdownSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];

  @tracked dropdownOpen = false;

  Footer = NavigationDropdownFooter;

  // @ts-expect-error
  @reads('organizationManager.organization') currentOrganization;

  @action
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  get shouldShowOrganizationName() {
    // @ts-expect-error
    return this.args.state === DUAL_NAV_STATES.RESPONSIVE;
  }

  get toggleOrganizationAriaLabel() {
    return this.intl.t('dual-nav.aria-labels.toggle-organization', {
      organizationName: this.currentOrganization.name,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    NavigationDropdown: typeof NavigationDropdownComponent;
  }
}
