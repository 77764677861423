/* import __COLOCATED_TEMPLATE__ from './cashflow-jumbo.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { SkeletonLoader } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { CashflowFrequencyToInterval } from 'qonto/react/models/cash-flow-period';
import { buildCashflowTimevalue } from 'qonto/utils/chart/display';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import CashflowComponent from './cashflow';
// @ts-expect-error
import styles from './cashflow-jumbo.module.css';
export default class CashflowJumboComponent extends CashflowComponent {
  // @ts-expect-error
  @service abilities;
  // @ts-expect-error
  @service intl;
  @tracked selectedDatapoint = undefined;

  styles = styles;
  placeholderBlock = SkeletonLoader.Block;

  get maxDisplayedMonths() {
    // @ts-expect-error
    return this.args.maxDisplayedMonths;
  }

  get indexOffset() {
    // @ts-expect-error
    return this.args.indexOffset;
  }

  @tracked isSticky = false;

  setupStickyObserver() {
    const observer = new IntersectionObserver(
      ([e]) => {
        // @ts-expect-error
        this.isSticky = e?.intersectionRatio < 0.25;
      },
      { threshold: [0.25] }
    );

    // Wait for element to be in DOM
    requestAnimationFrame(() => {
      const element = document.querySelector('[data-cashflow-jumbo]');
      if (element) {
        observer.observe(element);
      }
    });
  }

  get isCurrentPeriodSelected() {
    if (!this.selectedDatapoint) return true;

    // @ts-expect-error
    let frequency = this.args.selectedFrequency || 'monthly';
    // @ts-expect-error
    let frequencyInterval = CashflowFrequencyToInterval[frequency];

    // @ts-expect-error
    return dayjs(this.selectedDatapoint.time).isSame(dayjs(), frequencyInterval);
  }

  get cashflowTimeseries() {
    if (this.args.isLoading || this.args.isUpdating || !this.statistics.base) return;

    if (this.abilities.cannot('assign category')) return this.timeseries;

    let [inflows, outflows, balances] = this.statistics.base;
    let baseArray = Array.from({ length: balances.length });

    return baseArray.map((_, index) => {
      let balance = balances[index];
      let isCurrent = dayjs(balance.start_date).isSame(dayjs(), this.frequencyInterval);
      let isFuture = dayjs(balance.start_date).isAfter(dayjs(), this.frequencyInterval);

      return buildCashflowTimevalue(
        inflows[index],
        outflows[index],
        balance,
        this.timezone,
        isCurrent,
        isFuture
      );
    });
  }

  get availableDatapointValue() {
    if (!this.cashflowTimeseries) return;
    // @ts-expect-error
    if (this.selectedDatapoint) return this.selectedDatapoint.data;

    // @ts-expect-error
    return this.cashflowTimeseries.at(-1).data;
  }

  get balance() {
    return this.availableDatapointValue?.endingBalance;
  }

  get balanceLabel() {
    return this.isCurrentPeriodSelected
      ? this.intl.t('cash-flow.balance.current-header')
      : this.intl.t('cash-flow.balance.selected-period-header');
  }

  get vatDue() {
    return this.availableDatapointValue?.vatDue;
  }

  get vatLabel() {
    return this.isCurrentPeriodSelected
      ? this.intl.t('cash-flow.vat.current-header')
      : this.intl.t('cash-flow.vat.selected-period-header');
  }

  get vatTooltip() {
    let credit = this.intl.formatNumber(this.availableDatapointValue?.vatCredit, {
      currency: 'EUR',
      style: 'currency',
    });
    let debit = this.intl.formatNumber(this.availableDatapointValue.vatDebit, {
      currency: 'EUR',
      style: 'currency',
    });
    return this.intl.t('cash-flow.vat.tooltip', {
      htmlSafe: true,
      credit,
      debit,
    });
  }

  /**
   * Displayed timeseries
   */
  get chartTimeseries() {
    if (!this.cashflowTimeseries) return;

    let offset = this.indexOffset;
    let cutoff = offset + this.maxDisplayedMonths;
    let chartTimeseries = [...this.cashflowTimeseries].slice(offset, cutoff);

    return chartTimeseries;
  }

  /**
   * Chart timeseries + next available timevalue
   */
  get balanceTimeseries() {
    if (!this.cashflowTimeseries) return;

    let timeseriesLastIndex = this.cashflowTimeseries.length - 1;
    let cutoff = this.indexOffset + this.maxDisplayedMonths;
    let lastBalanceIndex = cutoff < timeseriesLastIndex ? cutoff + 1 : timeseriesLastIndex;
    let lastBalancePoint = this.cashflowTimeseries.at(lastBalanceIndex);

    // @ts-expect-error
    let balanceTimeseries = [...this.chartTimeseries, lastBalancePoint];

    return balanceTimeseries;
  }

  constructor() {
    // @ts-expect-error
    super(...arguments);
    // @ts-expect-error
    this.storageKey = `cashflow-jumbo-period-${this.membershipId}`;
    this.setupStickyObserver();
  }

  @action
  // @ts-expect-error
  onSavePeriod(period, closeDropdown) {
    // @ts-expect-error
    this.args.onResetOffset();

    closeDropdown?.();
    if (!period) return;

    this._selectedPeriod = this._formatPeriod(period);
    this.persistPeriod(period);

    this.segment.track('cash-flow_filter_clicked', {
      filtertype: 'period',
    });
    this.refreshWidgetTask.perform(period).catch(ignoreCancelation);
  }

  // @ts-expect-error
  @action onMonthClicked(index, selectedDatapoint) {
    // @ts-expect-error
    this.args.onSetFocus(index);

    // @ts-expect-error
    if (this.args.selectedFrequency) {
      this.selectedDatapoint = selectedDatapoint;
    }
  }
}
