/* import __COLOCATED_TEMPLATE__ from './combo.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import buildQuery from 'qonto/utils/statistics';

interface OverviewStatisticsComboSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OverviewStatisticsComboComponent extends Component<OverviewStatisticsComboSignature> {
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  @tracked statistics = [];
  @tracked comparisonStatistics = [];

  get isLoading() {
    return this.queryStatisticsTask.isRunning && this.queryStatisticsTask.performCount === 1;
  }

  get isUpdating() {
    return this.queryStatisticsTask.isRunning && this.queryStatisticsTask.performCount > 1;
  }

  get isError() {
    return this.getStatisticsTask.last?.isError;
  }

  queryStatisticsTask = dropTask(
    async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
      try {
        await this.getStatisticsTask
          .linked()
          .perform(aggregations, basePeriod, bankAccounts, comparisonPeriod);
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(errorInfo.error);
        }
      }
    }
  );

  getStatisticsTask = dropTask(async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
    // @ts-expect-error
    let adapter = this.store.adapterFor('transaction');
    let organizationId = this.organizationManager.organization.id;

    // @ts-expect-error
    let isPeriod = period => period?.interval || period?.startDate || period?.endDate;

    // @ts-expect-error
    let getQuery = (period, side) =>
      buildQuery(organizationId, aggregations, period, side, bankAccounts);

    // @ts-expect-error
    let getStatistics = period => [
      // @ts-expect-error
      adapter.getCashflow(getQuery(period, 'credit')),
      // @ts-expect-error
      adapter.getCashflow(getQuery(period, 'debit')),
      // @ts-expect-error
      adapter.getBalance(getQuery(period)),
    ];

    // @ts-expect-error
    this.statistics = await all(getStatistics(basePeriod));

    if (isPeriod(comparisonPeriod)) {
      // @ts-expect-error
      this.comparisonStatistics = await all(getStatistics(comparisonPeriod));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Statistics::Combo': typeof OverviewStatisticsComboComponent;
  }
}
