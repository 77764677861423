/* import __COLOCATED_TEMPLATE__ from './desktop-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { DateField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import TEMPLATE_VALUES from 'qonto/constants/env-helper';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import { addDocumentFileToModel, removeDocumentFileFromModel } from 'qonto/utils/handle-documents';

import styles from './desktop-form.strict-module.css';

const USER_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'gender',
  'birthdate',
  'birthCity',
  'birthCountry',
];

export const INVITE_FIELDS = ['proofOfIdentity', 'nationality'];

interface InvitationsNewUserInfoDesktopFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvitationsNewUserInfoDesktopFormComponent extends Component<InvitationsNewUserInfoDesktopFormSignature> {
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @reads('args.user.didValidate') enableValidations;
  // @ts-expect-error
  @reads('args.invite.didValidate') enableInviteValidations;

  dateField = DateField;
  styles = styles;

  get documentsDropzoneText() {
    let { intl } = this;

    return htmlSafe(
      intl.t('join-team.about-you.add-poi.drop-zone.label', {
        maxSize: formatFileSize(intl, TEMPLATE_VALUES.avatarMaxSize),
      })
    );
  }

  get firstName() {
    // @ts-expect-error
    return this.args.user.firstName || this.args.invite.firstName;
  }

  get lastName() {
    // @ts-expect-error
    return this.args.user.lastName || this.args.invite.lastName;
  }

  get isItalianOrganization() {
    // @ts-expect-error
    return this.args.invite.organizationLegalCountry === 'IT';
  }

  get birthday() {
    // @ts-expect-error
    let birthdate = this.args.user.birthdate;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  get userHasBirthdate() {
    // @ts-expect-error
    let { user } = this.args;
    // if user already has a birthday disable the field.
    return user.birthdate && !user.changedAttributes()['birthdate'];
  }

  get birthdateErrorMessage() {
    // @ts-expect-error
    let { user } = this.args;
    return this.enableValidations ? user.validations.attrs.birthdate.message : null;
  }

  get birthCityErrorMessage() {
    // @ts-expect-error
    let { user } = this.args;
    // @ts-expect-error
    return this.enableValidations || this.enableBirthCityValidations
      ? user.validations.attrs.birthCity.message
      : null;
  }

  submitUserTask = dropTask(async (invite, user) => {
    // @ts-expect-error
    this.args.invite.set('didValidate', false);
    // @ts-expect-error
    this.args.user.set('didValidate', false);

    let { validations: userValidation } = await user.validate({ on: USER_FIELDS });
    let { validations: inviteValidation } = await invite.validate({ on: INVITE_FIELDS });

    // @ts-expect-error
    this.args.invite.set('didValidate', true);
    // @ts-expect-error
    this.args.user.set('didValidate', true);

    if (userValidation.isValid && inviteValidation.isValid) {
      try {
        // @ts-expect-error
        await this.args.submitUser(invite, user);
        this.segment.track('join_team_personal_information_filled', {
          role: invite.role,
          invitation_id: invite.id,
        });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
  });

  @action
  // @ts-expect-error
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
    });
  }

  @action
  // @ts-expect-error
  handleAddDocument(model, docType, file) {
    addDocumentFileToModel(this.store, model, docType, file);
  }

  @action
  // @ts-expect-error
  handleRemoveFileFromDocument(model, docType, file) {
    removeDocumentFileFromModel(this.store, model, docType, file);
  }

  @action
  // @ts-expect-error
  updateBirthdate(value) {
    // As we now use DateFieldV2, the value we receive from the component
    // is formatted as a YYYY-MM-DD string date
    if (value) {
      // @ts-expect-error
      this.args.user.set('birthdate', value.toString());
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvitationsNewUser::Info::DesktopForm': typeof InvitationsNewUserInfoDesktopFormComponent;
  }
}
