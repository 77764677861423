/* import __COLOCATED_TEMPLATE__ from './pagopa-kyb-pending-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PagopaKybPendingModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PagopaKybPendingModal = templateOnlyComponent<PagopaKybPendingModalSignature>();

export default PagopaKybPendingModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PagopaKybPendingModal: typeof PagopaKybPendingModal;
  }
}
