/* import __COLOCATED_TEMPLATE__ from './q-cropper-preview.hbs'; */
import { guidFor } from '@ember/object/internals';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task, waitForQueue } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface QCropperPreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QCropperPreview extends Component<QCropperPreviewSignature> {
  // @ts-expect-error
  @tracked previewHeight;
  // @ts-expect-error
  @tracked previewWidth;
  cropContainer = `crop-container-${guidFor(this)}`;

  constructor(owner: unknown, args: QCropperPreviewSignature['Args']) {
    super(owner, args);
    this.setPreviewDimensionsTask.perform().catch(ignoreCancelation);
  }

  setPreviewDimensionsTask = task(async () => {
    await waitForQueue('afterRender');
    let element = document.getElementById(this.cropContainer);
    // @ts-expect-error
    let { crop } = this.args;
    if (crop) {
      let { width, height } = crop.cropData;
      let aspectRatio = width / height;
      // @ts-expect-error
      this.previewWidth = element.getBoundingClientRect().width;
      this.previewHeight = this.previewWidth / aspectRatio;
    }
  });

  get previewStyle() {
    // @ts-expect-error
    let { crop } = this.args;
    if (crop) {
      let { cropData, imageData } = crop;
      let { width, x, y } = cropData;
      let scaleRatio = width / this.previewWidth;

      let cropWidth = `${imageData.naturalWidth / scaleRatio}px`;
      let cropHeight = `${imageData.naturalHeight / scaleRatio}px`;
      let cropMarginLeft = `${-(x / scaleRatio)}px`;
      let cropMarginTop = `${-(y / scaleRatio)}px`;

      return htmlSafe(
        [
          `width: ${cropWidth}`,
          `height: ${cropHeight}`,
          `margin-left: ${cropMarginLeft}`,
          `margin-top: ${cropMarginTop}`,
        ].join(';')
      );
    }
  }

  get wrapperStyle() {
    return htmlSafe(`height:${this.previewHeight}px`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QCropperPreview: typeof QCropperPreview;
  }
}
