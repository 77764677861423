export default {
  "card": "cXC",
  "header": "cXk",
  "avatar": "cXJ",
  "title": "cXs",
  "subtitle": "cXF",
  "label": "cXg",
  "item": "cXD body-2",
  "header-icon": "cXV",
  "dropdown-menu": "cXL",
  "menu-item": "cXT body-2",
  "delete": "cXz"
};
