/* import __COLOCATED_TEMPLATE__ from './accounts.hbs'; */
import Component from '@glimmer/component';

interface PowerSelectAccountsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PowerSelectAccountsComponent extends Component<PowerSelectAccountsSignature> {
  get selectedAccount() {
    // @ts-expect-error
    return this.args.selectedAccounts.at(0);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Accounts': typeof PowerSelectAccountsComponent;
  }
}
