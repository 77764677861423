/* import __COLOCATED_TEMPLATE__ from './illustration.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface QStateIllustrationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QStateIllustration extends Component<QStateIllustrationSignature> {
  lottiePlayer = LottiePlayer;

  @service declare sentry: Services['sentry'];

  @action
  // @ts-expect-error
  handleError(error) {
    if (Boolean(error.status) && error.status >= 400 && error.status < 500) {
      this.sentry.captureException(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'QState::Illustration': typeof QStateIllustration;
  }
}
