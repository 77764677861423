/* import __COLOCATED_TEMPLATE__ from './custom-period.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

// @ts-expect-error
import { PERIOD_KEYS } from 'qonto/constants/overview';

interface CustomPeriodSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CustomPeriodComponent extends Component<CustomPeriodSignature> {
  @service declare intl: Services['intl'];

  @tracked startDate;
  @tracked _endDate;
  @tracked isPristine = true;

  constructor(owner: unknown, args: CustomPeriodSignature['Args']) {
    super(owner, args);
    this.startDate =
      // @ts-expect-error
      this.args.selectedPeriod?.key === PERIOD_KEYS.CUSTOM_PERIOD
        ? // @ts-expect-error
          this.args.selectedPeriod.startDate
        : dayjs().startOf('day').toDate();
    // @ts-expect-error
    this._endDate = this.args.selectedPeriod?.endDate;
  }

  get endDate() {
    if (this._endDate) {
      if (dayjs(this.startDate).isAfter(this._endDate)) {
        return dayjs(this.startDate).add(1, 'day').endOf('day').toDate();
      } else {
        return dayjs(this._endDate).endOf('day').toDate();
      }
      // @ts-expect-error
    } else if (this.args.type !== 'date-picker' && this.startDate) {
      return dayjs(this.startDate).add(11, 'month').endOf('month').toDate();
    }
  }

  set endDate(date) {
    this._endDate = date;
  }

  get period() {
    let { startDate, endDate } = this;

    if (startDate && endDate) {
      return {
        label: this.intl.t('overview.period-selection.custom-period'),
        key: PERIOD_KEYS.CUSTOM_PERIOD,
        startDate,
        endDate,
      };
    }
  }

  get isDisabled() {
    return !this.startDate || !this.endDate || this.isPristine;
  }

  @action
  // @ts-expect-error
  onSelectCustomPeriod(startDate) {
    // we must reset the cached _endDate, otherwise it will never update
    this.endDate = undefined;
    this.onSelectStartDate(startDate);
  }

  @action
  // @ts-expect-error
  onSelectStartDate(date) {
    this.startDate = date;
    this.isPristine = false;
  }

  @action
  // @ts-expect-error
  onSelectEndDate(date) {
    this.endDate = date;
    this.isPristine = false;
  }

  @action
  // @ts-expect-error
  onApply(event) {
    event.preventDefault();
    // @ts-expect-error
    if (!this.period || !this.args.onSave) return;

    // @ts-expect-error
    this.args.onSave(this.period, this.args.close);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::PeriodSelector::CustomPeriod': typeof CustomPeriodComponent;
  }
}
