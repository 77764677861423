/* import __COLOCATED_TEMPLATE__ from './notifications-settings.hbs'; */
import { notifyPropertyChange } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Toggle } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, subscriptionNamespace } from 'qonto/constants/hosts';
import {
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_CATEGORIES_ORDER,
  // @ts-expect-error
} from 'qonto/constants/notifications';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

interface NotificationsSettingsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class NotificationsSettings extends Component<NotificationsSettingsSignature> {
  @service declare networkManager: Services['networkManager'];
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];

  toggle = Toggle;

  @tracked opened = false;

  transition = fade;

  get organization() {
    // @ts-expect-error
    return this.args.organization;
  }

  get subscriptions() {
    return this.organization.subscriptions;
  }

  get notificationCategories() {
    let categories = {};

    // @ts-expect-error
    this.subscriptions.forEach(subscription => {
      let { catCode, code } = subscription;

      if (
        catCode === NOTIFICATION_CATEGORIES.BUDGETS &&
        (this.abilities.cannot('access budget') || this.abilities.cannot('read details budget'))
      ) {
        return;
      }

      if (
        catCode === NOTIFICATION_CATEGORIES.CHECK &&
        !this.organization.get('features').includes('checks')
      ) {
        return;
      }

      if (
        code === 'account_activity_matching' &&
        this.abilities.cannot('access email forward attachment')
      ) {
        return;
      }

      if (
        code === 'account_activity_comments' &&
        !variation('feature--boolean-redesigned-transactions-table')
      ) {
        return;
      }

      // @ts-expect-error
      if (!categories[catCode]) {
        // @ts-expect-error
        categories[catCode] = [];
      }
      // @ts-expect-error
      categories[catCode].push(subscription);
    });

    return this.sortCategories(categories);
  }

  // @ts-expect-error
  sortCategories(categories) {
    let sortedCategories = {};

    // @ts-expect-error
    NOTIFICATION_CATEGORIES_ORDER.forEach(category => {
      if (categories[category]) {
        // @ts-expect-error
        sortedCategories[category] = categories[category];
      }
    });

    return sortedCategories;
  }

  get hasSubscriptions() {
    // @ts-expect-error
    let subscription = this.subscriptions.find(subscription => {
      let { email, push } = subscription.devices;
      return email || push;
    });

    return Boolean(subscription);
  }

  onHeaderClick = dropTask(async organization => {
    this.opened = !this.opened;

    if (this.opened) {
      await Promise.all([
        this.loadSubscriptionsTask.perform(organization),
        this.store.findRecord('organization', organization.id),
      ]);
    }
  });

  saveSubscriptionTask = dropTask(async () => {
    await Promise.all(
      // @ts-expect-error
      this.subscriptions.map(subscription =>
        subscription.save({
          adapterOptions: {
            headers: { 'X-Qonto-Organization-ID': this.organization.id },
          },
        })
      )
    );
  });

  handleToggleUpdateTask = restartableTask(async () => {
    let value = !this.hasSubscriptions;
    // @ts-expect-error
    this.subscriptions.forEach(subscription => {
      subscription.devices.email = value;
      subscription.devices.push = value;
    });

    // @ts-expect-error
    notifyPropertyChange(this.args.organization, 'subscriptions');

    await timeout(DEBOUNCE_MS);

    await this.saveSubscriptionTask.perform();
  });

  loadSubscriptionsTask = dropTask(async organization => {
    let subscriptionsPayload = await this.networkManager.request(
      `${apiBaseURL}/${subscriptionNamespace}/subscriptions`,
      {
        headers: { 'X-Qonto-Organization-ID': organization.id },
      }
    );

    // @ts-expect-error
    subscriptionsPayload.subscriptions.forEach(subscription => {
      subscription.organization_id = organization.id;
      delete subscription.membership_id;
    });

    this.store.pushPayload(subscriptionsPayload);

    return subscriptionsPayload;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    NotificationsSettings: typeof NotificationsSettings;
  }
}
