/* import __COLOCATED_TEMPLATE__ from './search-with-actions.hbs'; */
import Component from '@glimmer/component';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';

interface ProductsSearchWithActionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ProductsSearchWithActionsComponent extends Component<ProductsSearchWithActionsSignature> {
  searchField = SearchFieldWithDebounce;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Products::SearchWithActions': typeof ProductsSearchWithActionsComponent;
  }
}
