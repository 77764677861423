/* import __COLOCATED_TEMPLATE__ from './dropdown-transaction-volume.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { YEARLY_TRANSACTION_VOLUME_OPTIONS } from 'qonto/constants/business-finances';

interface DropdownTransactionVolumeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DropdownTransactionVolume extends Component<DropdownTransactionVolumeSignature> {
  @service declare intl: Services['intl'];

  get selectedValue() {
    // @ts-expect-error
    let code = this.args.organizationChangeRequest.yearlyTransactionVolume;

    if (code) {
      let name = this.yearlyTransactionVolumeOptionsTranslations[code];
      return { code, name };
    }
    return null;
  }

  get yearlyTransactionVolumeOptionsTranslations() {
    let [option1, option2, option3, option4, option5] = YEARLY_TRANSACTION_VOLUME_OPTIONS;
    return {
      [option1]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option1'
      ),
      [option2]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option2'
      ),
      [option3]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option3'
      ),
      [option4]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option4'
      ),
      [option5]: this.intl.t(
        'organizations.profile.company_profile.organization_edit.business_finances.yearly_transaction_volume.option5'
      ),
    };
  }

  get options() {
    // @ts-expect-error
    return YEARLY_TRANSACTION_VOLUME_OPTIONS.map(code => ({
      code,
      name: this.yearlyTransactionVolumeOptionsTranslations[code],
    }));
  }

  @action
  // @ts-expect-error
  handleUpdate(transactionVolumeObject) {
    // @ts-expect-error
    this.args.organizationChangeRequest.yearlyTransactionVolume = transactionVolumeObject.code;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationProfile::DropdownTransactionVolume': typeof DropdownTransactionVolume;
  }
}
