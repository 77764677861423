export default {
  "row": "clr",
  "terminated": "clb",
  "status-avatar": "cly",
  "amount": "clU body-1",
  "item-info": "clj",
  "date-cell": "clW",
  "cell": "clu body-2",
  "status-icon": "cXS",
  "amount-cell": "cXc",
  "item-name-cell": "cXq",
  "item-name-container": "cXZ"
};
