/* import __COLOCATED_TEMPLATE__ from './new-mandate-option.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface PowerSelectMandatesNewMandateOptionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PowerSelectMandatesNewMandateOptionComponent extends Component<PowerSelectMandatesNewMandateOptionSignature> {
  @action
  // @ts-expect-error
  onClick(e) {
    e.stopPropagation();
    // @ts-expect-error
    this.args.select.actions.select(null);
    // @ts-expect-error
    this.args.select.actions.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Mandates::NewMandateOption': typeof PowerSelectMandatesNewMandateOptionComponent;
  }
}
