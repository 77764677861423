/* import __COLOCATED_TEMPLATE__ from './back-button.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BackButtonSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const BackButton = templateOnlyComponent<BackButtonSignature>();

export default BackButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvitationsNewUser::BackButton': typeof BackButton;
  }
}
