/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { getTrackingNameAndProperties, LAYOUT, TYPES } from 'qonto/constants/empty-states/system';
// @ts-expect-error
import HRIS_INTEGRATION_STATUS from 'qonto/constants/hris-integration';
import { ROLES } from 'qonto/constants/membership';
import { QPageSelector } from 'qonto/react/shared/components/q-page-selector';

interface MemberListControllerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

/**
 * This controller is responsible to manage the list of memberships.
 */

export default class MemberListController extends Component<MemberListControllerSignature> {
  qPageSelector = QPageSelector;

  queryParams = ['page', 'perPage'];

  perPage = 25;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare linkManager: Services['linkManager'];

  // @ts-expect-error
  @tracked query;

  get paginatedMembers() {
    // @ts-expect-error
    return this.args.model.paginatedMembersTask.last?.value;
  }

  get isSoloPlan() {
    return this.subscriptionManager.getLimitByFeatureCode('additional_users') === 1;
  }

  get inviteButtonText() {
    if (this.isSoloPlan) {
      return this.intl.t('btn.invite_an_accountant');
    }

    if (this.organizationManager.membership.role === ROLES.MANAGER) {
      return this.intl.t('btn.invite_an_employee');
    }

    return this.intl.t('btn.invite_new_member');
  }

  get hasResults() {
    // @ts-expect-error
    return Boolean(this.args.model.paginatedMembersTask.last?.value?.length);
  }

  get hrisIsSyncing() {
    // @ts-expect-error
    if (this.args.model.hrisIntegration?.length) {
      // @ts-expect-error
      return this.args.model.hrisIntegration.every(
        // @ts-expect-error
        ({ status }) => status !== HRIS_INTEGRATION_STATUS.SYNCED
      );
    }

    return false;
  }

  get showResults() {
    if (this.hrisIsSyncing) {
      return false;
    }
    // @ts-expect-error
    return Boolean(this.query) || Boolean(this.args.model.paginatedMembersTask.last?.value?.length);
  }

  get emptyStateOptions() {
    return {
      title: this.intl.t('activities.members.empty-team.title'),
      subtitle: this.intl.t('activities.members.empty-team.description'),
      lottieSrc: '/lotties/empty-state/no-team.json',
      button: {
        label: this.inviteButtonText,
        callback: this.inviteTeamMember,
      },
    };
  }

  get totalCount() {
    // @ts-expect-error
    return this.args.model.paginatedMembersTask.last?.value?.meta?.total_count;
  }

  get totalPages() {
    // @ts-expect-error
    return this.args.model.paginatedMembersTask.last?.value?.meta?.total_pages;
  }

  get isLoading() {
    // @ts-expect-error
    return this.args.model.paginatedMembersTask.isRunning;
  }

  @action
  inviteTeamMember() {
    this.trackTeamInviteSelected('team_page');

    this.flowLinkManager.transitionTo({
      name: 'member-invite',
    });
  }

  @action
  // @ts-expect-error
  handleDestroyMember(member) {
    member.destroyRecord();
  }

  @action
  // @ts-expect-error
  trackTeamInviteSelected(origin) {
    this.segment.track('team_invite_selected', {
      origin,
    });
  }

  get filtersApplied() {
    let filters = this.router.currentRoute.queryParams;
    return Boolean(filters.query || filters.teamId);
  }

  get emptyStateRevampOptions() {
    let name = 'user-access';

    if (this.hrisIsSyncing) {
      return {
        title: this.intl.t('members.list.empty-state.hr-importing.title'),
        subtitle: this.intl.t('members.list.empty-state.hr-importing.subtitle'),
        lottieSrc: '/lotties/transfers/timeout.json',
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        layout: LAYOUT.INFORM,
      };
    }

    if (this.filtersApplied) {
      return {
        title: this.intl.t('empty-states.system.inform.i2.title'),
        subtitle: this.intl.t('empty-states.system.inform.i2.subtitle'),
        lottieSrc: '/lotties/empty-state/search-no-result.json',
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        layout: LAYOUT.INFORM,
      };
    }
    // @ts-expect-error
    if (!this.args.model.paginatedMembersTask.last?.value?.length) {
      // @ts-expect-error
      if (this.args.memberRoute === 'members.to-invite.member') {
        if (this.organizationManager.membership.manager) {
          return {
            title: this.intl.t('members.list.empty-state.no-invitable-members.title'),
            subtitle: this.intl.t('members.list.empty-state.no-invitable-members.subtitle'),
            lottieSrc: '/lotties/empty-state/no-team.json',
            tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
            layout: LAYOUT.INFORM,
          };
        }
        // @ts-expect-error
        let hrLink = this.linkManager.createLink({
          route: 'settings.connect-hub.categories',
          models: [this.organizationManager.organization.slug, 'hr'],
        });

        return {
          title: this.intl.t('members.list.empty-state.no-invitable-members.title'),
          subtitle: this.intl.t('members.list.empty-state.no-invitable-members.connect-subtitle', {
            // @ts-expect-error
            link: htmlSafe(
              `<a href="${hrLink}" class="body-link" data-test-hr-link>${this.intl.t(
                'members.list.empty-state.no-invitable-members.hr-link'
              )}</a>`
            ),
            htmlSafe: true,
          }),
          lottieSrc: '/lotties/empty-state/no-team.json',
          tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
          layout: LAYOUT.INFORM,
        };
      }
      // @ts-expect-error
      if (this.args.memberRoute === 'members.inactive.member') {
        return {
          title: this.intl.t('members.list.empty-state.no-inactive-members.title'),
          subtitle: this.intl.t('members.list.empty-state.no-inactive-members.subtitle'),
          lottieSrc: '/lotties/empty-state/no-team.json',
          tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
          layout: LAYOUT.INFORM,
        };
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Member::List': typeof MemberListController;
  }
}
