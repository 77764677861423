export default {
  "file-uploader": "cVO",
  "file-uploader-content": "cVm",
  "file-uploader-dropzone": "cVp",
  "fixedSize": "cVx",
  "two-column": "cVw",
  "file-uploader-label": "cVN body-1",
  "required-chip": "cVv",
  "animated-container": "cVo",
  "toggle-button": "cVn",
  "toggle-button-icon": "cVi"
};
