export default {
  "item-status": "cJW",
  "item-date": "cJu",
  "item-amount": "csS body-1",
  "item-amount-credit": "csc",
  "row": "csq",
  "icon": "csZ",
  "disabled": "csR",
  "icon-container": "csQ",
  "text-container": "cse",
  "text-row": "csB",
  "item-details": "csE",
  "counterparty-name": "csd",
  "column": "csI",
  "item-amount-disabled": "cst",
  "attachment-tooltip": "csM",
  "bank-account-avatar": "csP"
};
