/* import __COLOCATED_TEMPLATE__ from './storage.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const NEW_BADGE_STORAGE_KEY = 'new-badge-viewed';

interface NewBadgeStorageSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class NewBadgeStorageComponent extends Component<NewBadgeStorageSignature> {
  get viewedItems() {
    return new Set(JSON.parse(safeLocalStorage.getItem(NEW_BADGE_STORAGE_KEY) || '[]'));
  }

  // @ts-expect-error
  @tracked viewed = this.viewedItems.has(this.args.id);

  @action
  // @ts-expect-error
  markAsViewed(callback) {
    this.viewed = true;
    let viewedItems = this.viewedItems;
    // @ts-expect-error
    viewedItems.add(this.args.id);
    safeLocalStorage.setItem(NEW_BADGE_STORAGE_KEY, JSON.stringify(Array.from(viewedItems)));
    callback?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewBadge::Storage': typeof NewBadgeStorageComponent;
  }
}
