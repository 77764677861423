/* import __COLOCATED_TEMPLATE__ from './update-session.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface PopupSecurityUpdateEmailUpdateSessionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PopupSecurityUpdateEmailUpdateSessionComponent extends Component<PopupSecurityUpdateEmailUpdateSessionSignature> {
  lottiePlayer = LottiePlayer;

  @service declare userManager: Services['userManager'];
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: PopupSecurityUpdateEmailUpdateSessionSignature['Args']) {
    super(owner, args);
    this.segment.track('profile_email-update_success');
  }

  @action
  handleClick() {
    this.segment.track('profile_email-update_log-out');
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Popup::Security::UpdateEmail::UpdateSession': typeof PopupSecurityUpdateEmailUpdateSessionComponent;
  }
}
