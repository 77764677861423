/* import __COLOCATED_TEMPLATE__ from './login-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface LoginFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class LoginForm extends Component<LoginFormSignature> {
  spinner = Spinner;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare errors: Services['errors'];
  @service declare sentry: Services['sentry'];

  @tracked identification = '';
  @tracked password = '';
  @tracked emptyEmailError = null;
  @tracked emptyPasswordError = null;
  @tracked serverError = null;

  authenticateTask = dropTask(async (email, password) => {
    // Technically, this could be either a click or a keypress, because the action is wired to the
    // `submit` event on the form.
    this.segment.track('login_clicked', {
      authentication_method: 'email_pwd',
    });

    if (!email.length) {
      // @ts-expect-error
      this.emptyEmailError = this.intl.t('errors.empty_email_address');
      return;
    }

    if (!password.length) {
      // @ts-expect-error
      this.emptyPasswordError = this.intl.t('errors.empty_password');
      return;
    }

    try {
      // @ts-expect-error
      await this.args.authenticate(email, password);
    } catch (error) {
      this.segment.track('login_failed', {
        authentication_method: 'email_pwd',
      });

      // @ts-expect-error
      if (hasMFAError(error.responseJSON?.errors)) {
        // @ts-expect-error
        throw error.responseJSON;
      }

      // @ts-expect-error
      let { status } = error;
      if (status === 401) {
        // @ts-expect-error
        this.serverError = this.intl.t('errors.invalid_credentials');
        return;
      }

      if (status === 403) {
        // @ts-expect-error
        this.serverError = this.intl.t('errors.account_locked');
        return;
      }

      this.toastFlashMessages.toastError(
        // @ts-expect-error
        this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
      );

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  // @ts-expect-error
  handleUpdateIdentification(identification) {
    this.identification = identification;
    this.emptyEmailError = null;
    this.serverError = null;
  }

  @action
  // @ts-expect-error
  handleUpdatePassword(password) {
    this.password = password;
    this.emptyPasswordError = null;
    this.serverError = null;
  }

  get emptyPasswordOrServerError() {
    return this.emptyPasswordError ?? this.serverError;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LoginForm: typeof LoginForm;
  }
}
