/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { getEnabledLegalCountries, PROVIDERS_MAP } from 'qonto/constants/insurance-hub';
import { ProviderLogo } from 'qonto/react/components/insurance-hub/provider-logo';

interface InsuranceHubProductSidebarSignature {
  // The arguments accepted by the component
  Args: {
    showDisclaimer?: boolean;
    hasActiveContract?: boolean;
    price?: number;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InsuranceHubProductSidebarComponent extends Component<InsuranceHubProductSidebarSignature> {
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];

  providerLogo = ProviderLogo;

  get legalCountry() {
    // @ts-expect-error
    return this.organizationManager.organization.legalCountry || this.args.legalCountry;
  }

  get shouldDisplayDisclaimer() {
    return this.args.showDisclaimer && !this.args.hasActiveContract;
  }

  get shouldDisplayProviders() {
    if (variation('feature--boolean-insurance-hub-it')) {
      return this.legalCountry !== 'IT';
    }

    return true;
  }

  get legalArticle() {
    let { locale } = this.localeManager;

    if (!getEnabledLegalCountries().includes(this.legalCountry)) return;

    return (
      // @ts-expect-error
      PROVIDERS_MAP[this.legalCountry].legalArticle?.[locale] ||
      PROVIDERS_MAP[this.legalCountry].legalArticle.en
    );
  }

  get showLegalNoticeSuperscript() {
    return this.legalCountry === 'IT' && variation('feature--boolean-insurance-hub-it');
  }

  get amount() {
    return (
      this.intl.t('insurance-hub.details.sidebar.amount', {
        price: this.intl.formatNumber(this.args.price, {
          minimumFractionDigits: 0,
          currency: 'EUR',
          style: 'currency',
        }),
        // Asterisk to point at the legal notice for Italian market
      }) + (this.showLegalNoticeSuperscript ? '*' : '')
    );
  }

  get disclaimer() {
    let { organization } = this.organizationManager;
    let translationParams = {
      link: this.intl.t('insurance-hub.details.sidebar.link-text.legal-notice'),
    };

    switch (organization.legalCountry) {
      case 'FR':
        return {
          text: this.intl.t('insurance-hub.details.sidebar.legal-notice-fr', translationParams),
          key: 'insurance-hub.details.sidebar.legal-notice-fr',
        };
      default:
        return {
          text: this.intl.t('insurance-hub.details.sidebar.legal-notice-de', translationParams),
          key: 'insurance-hub.details.sidebar.legal-notice-de',
        };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Product::Sidebar': typeof InsuranceHubProductSidebarComponent;
  }
}
