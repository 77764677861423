/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { isNil } from 'es-toolkit';

interface ProductsTableItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ProductsTableItemComponent extends Component<ProductsTableItemSignature> {
  checkbox = Checkbox;

  get hasMissingDetails() {
    // @ts-expect-error
    return isNil(this.args.item.unitPrice?.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Products::Table::Item': typeof ProductsTableItemComponent;
  }
}
