/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface InvoiceSubscriptionsTableSignature {
  // The arguments accepted by the component
  Args: {
    isLocalEmpty?: boolean;
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSubscriptionsTableComponent extends Component<InvoiceSubscriptionsTableSignature> {
  // @ts-expect-error
  @action handleItemKeydown(document, event) {
    if (event.key === 'Enter') {
      // @ts-expect-error
      this.args.handleShowItem(document);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::Table': typeof InvoiceSubscriptionsTableComponent;
  }
}
