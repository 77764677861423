/* import __COLOCATED_TEMPLATE__ from './loading.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';

import { SkeletonLoader } from '@repo/design-system-kit';
// @ts-expect-error
import { bisectLeft } from 'd3-array';
// @ts-expect-error
import { interpolateNumber } from 'd3-interpolate';
import { dropTask, waitForQueue } from 'ember-concurrency';

import IndicatorChartComponent from 'qonto/components/overview/chart/indicator';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

// @ts-expect-error
import styles from './loading.module.css';

export default class IndicatorChartLoadingComponent extends IndicatorChartComponent {
  // @ts-expect-error
  @service sentry;
  styles = styles;
  placeholderLine = SkeletonLoader.Line;

  loaderValues = {
    0: 18000,
    3: 22500,
    5: 19500,
    8: 31500,
    11: 31500,
    15: 29500,
    18: 22500,
    21: 33000,
    30: 42500,
  };
  // @ts-expect-error
  isPastRange = true;
  animationDuration = 250;
  chartLoadingId = `chart-loading-container-${guidFor(this)}`;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.drawGraphTask.perform().catch(ignoreCancelation);
  }

  drawGraphTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');
      this.drawGraph(document.getElementById(this.chartLoadingId));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  // @ts-expect-error
  drawGraph(container) {
    this.chartContainer = container;

    let timeseries = this._getLoaderTimeseries();

    this._setRenderer(container);
    this._setScales(timeseries);

    // @ts-expect-error
    this._setYGridlines(this.yScale);
    // @ts-expect-error
    this._setXAxis(this.xScale, '');

    // @ts-expect-error
    this._drawBalanceArea(timeseries, this.centeredXScale, this.yScale);
    // @ts-expect-error
    this._drawBalanceLine(timeseries, this.centeredXScale, this.yScale);
  }

  _getYExtrema() {
    return [0, this.defaultYMax];
  }

  _getLoaderTimeseries() {
    let loaderKeys = Object.keys(this.loaderValues).map(index => Number(index));

    return Array.from({ length: 30 }).map((_, index) => {
      let loaderValuesIndex = bisectLeft(loaderKeys, index);
      let adjustedIndex = loaderValuesIndex ? loaderValuesIndex - 1 : 0;
      let valueIndex = loaderKeys[adjustedIndex];
      let nextIndex = loaderKeys[adjustedIndex + 1];

      let interpolator = interpolateNumber(
        // @ts-expect-error
        this.loaderValues[valueIndex],
        // @ts-expect-error
        this.loaderValues[nextIndex]
      );
      // @ts-expect-error
      let interpolationRatio = (index - valueIndex) / (nextIndex - valueIndex);

      return {
        time: new Date(new Date().getFullYear(), new Date().getMonth(), index),
        data: {
          value: interpolator(interpolationRatio),
        },
      };
    });
  }
}
