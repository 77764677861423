export default {
  "invitations-password-form": "cEd",
  "mobile-mode": "cEI",
  "logo": "cEt",
  "header": "cEM",
  "controls": "cEP",
  "footer": "cEl",
  "submit-cta": "cEX",
  "subtitle": "cEC"
};
