/* import __COLOCATED_TEMPLATE__ from './link-fields.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import ReceivableInvoiceUploadModel from 'qonto/models/receivable-invoices-upload';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface InvoiceSettingsFormTermsLinkFieldsSignature {
  // The arguments accepted by the component
  Args: {
    settings: Record<string, any>;
    termsAndConditionsFile: ReceivableInvoiceUploadModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvoiceSettingsFormTermsLinkFields extends Component<InvoiceSettingsFormTermsLinkFieldsSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service
  declare accountReceivableDocumentsUploadManager: Services['accountReceivableDocumentsUploadManager'];

  disclaimer: typeof Disclaimer.Inline = Disclaimer.Inline;

  termsLinkFieldsId = `${guidFor(this)}-terms-link-field`;

  @tracked showTermsLinkFields = Boolean(
    this.args.settings.tcLinkUrl || this.args.settings.tcLinkText
  );

  @tracked initialTermsAndConditionsFile = this.args.termsAndConditionsFile?.isDestroyed
    ? null
    : this.args.termsAndConditionsFile;

  constructor(owner: unknown, args: InvoiceSettingsFormTermsLinkFieldsSignature['Args']) {
    super(owner, args);

    this.accountReceivableDocumentsUploadManager.registerCallback({
      onUploadFinished: this.onUploadFinished,
    });
  }

  willDestroy() {
    super.willDestroy();

    this.accountReceivableDocumentsUploadManager.resetFiles();
  }

  get settings() {
    return this.args.settings;
  }

  get files() {
    return this.accountReceivableDocumentsUploadManager.files;
  }

  get file() {
    return this.files.at(-1);
  }

  get canRetry() {
    return this?.file?.hasErrors;
  }

  get shouldShowDropdown() {
    if (!variation('feature--boolean-ar-advanced-customization-attachment')) {
      return false;
    }

    let hasNoFileOrLink =
      !this.file && !this.showTermsLinkFields && !this.initialTermsAndConditionsFile;

    return hasNoFileOrLink || this.canRetry;
  }

  get shouldShowDisclaimer() {
    return !this.canRetry && (this.initialTermsAndConditionsFile || this.file);
  }

  @action
  handleAddTermsConditionsLink() {
    this.showTermsLinkFields = true;
    next(() => {
      let element = document.getElementById(this.termsLinkFieldsId);
      scrollIntoView(element);
    });
  }

  @action
  handleRemoveTermsConditionsLink() {
    this.showTermsLinkFields = false;
    this.args.settings.setProperties({
      tcLinkText: null,
      tcLinkUrl: null,
    });
  }

  @action
  handleFocusOutTcLinkUrl() {
    if (this.args.settings.tcLinkUrl) {
      this.args.settings.setProperties({
        tcLinkUrl: this._normalizeLinkUrl(this.args.settings.tcLinkUrl),
      });
    }
  }

  @action
  handleRemoveFile() {
    this.accountReceivableDocumentsUploadManager.resetFiles();
    this.settings.set('termsConditionsUpload', null);
  }

  @action
  handleRemoveInitialFile() {
    this.initialTermsAndConditionsFile?.unloadRecord();
    this.initialTermsAndConditionsFile = null;
  }

  @action
  onUploadFinished(record: ReceivableInvoiceUploadModel) {
    this.settings.set('termsConditionsUpload', record);
  }

  /**
   * @todo: This can be refactored to use URL.parse() after updating core-js to 3.38.0
   */
  // @ts-expect-error
  _normalizeLinkUrl(urlInput) {
    try {
      let normalizedUrl = new URL(urlInput);
      if (normalizedUrl.protocol !== 'https:') {
        return urlInput.replace(/(.*):\/\//, 'https://');
      }
      return urlInput;
    } catch {
      return `https://${urlInput}`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::TermsAndConditions::LinkFields': typeof InvoiceSettingsFormTermsLinkFields;
  }
}
