/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { restartableTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, insuranceHubNamespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { PROVIDERS_MAP } from 'qonto/constants/insurance-hub';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface InsuranceHubSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceHubSidebarComponent extends Component<InsuranceHubSidebarSignature> {
  @service declare modals: Services['modals'];
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  @action
  // @ts-expect-error
  onPreviewFile(attachment) {
    let {
      // @ts-expect-error
      insuranceContract: { id, contractId, type, status },
    } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.fetchAttachmentTask
      .perform(id, attachment.id)
      .then(response => {
        let { redirect_to: downloadUrl } = response;

        this.segment.track('insurance-hub_my-policies_policy_document_clicked', {
          policy_number: contractId,
          document_name: attachment.filename,
          product: type,
          policy_status: status,
          partner: PROVIDERS_MAP[country].providerName,
        });

        window.open(downloadUrl, '_blank');
      })
      .catch(ignoreCancelation);
  }

  fetchAttachmentTask = restartableTask(async (id, attachmentId) => {
    try {
      return await this.networkManager.request(
        `${apiBaseURL}/${insuranceHubNamespace}/insurances/insurance_contracts/${id}/attachments/${attachmentId}`
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Sidebar': typeof InsuranceHubSidebarComponent;
  }
}
