/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

interface PowerSelectAccountsMultipleFooterSignature {
  // The arguments accepted by the component
  Args: {
    isAllAccountSelected?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class PowerSelectAccountsMultipleFooter extends Component<PowerSelectAccountsMultipleFooterSignature> {
  checkbox = Checkbox;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Accounts::Multiple::Footer': typeof PowerSelectAccountsMultipleFooter;
  }
}
