export default {
  "donut-chart-container": "cCc",
  "donut-chart-wrapper": "cCq",
  "chart": "cCZ",
  "donut-chart-legend-container": "cCR caption",
  "donut-chart-legend-item-empty": "cCQ",
  "donut-chart-legend-bullet-empty": "cCe",
  "donut-chart-legend-bullet": "cCB",
  "donut-chart-legend-text-empty": "cCE",
  "donut-chart-legend-text": "cCd",
  "donut-chart-custom-label-uncategorized": "cCI",
  "custom-label-uncategorized-button": "cCt body-1",
  "donut-chart-legend": "cCM",
  "donut-chart-legend-item": "cCP",
  "donut-chart-legend-other-item": "cCl",
  "donut-chart-legend-uncategorized-item": "cCX",
  "donut-chart-legend-text-name": "cCC",
  "tooltip": "cCk"
};
