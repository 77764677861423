/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

// @ts-expect-error
import { getEmptyStateConfig } from 'qonto/constants/empty-states/insurance-hub';
import { TablePlaceholder } from 'qonto/react/components/insurance-hub/table/placeholder';

interface InsuranceHubTableSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
    isSidebarOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InsuranceHubTableComponent extends Component<InsuranceHubTableSignature> {
  placeholder = TablePlaceholder;

  @service declare emptyStates: Services['emptyStates'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  get legalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  get emptyStateOptions() {
    return this.emptyStates.getEmptyStateOptions({
      hasActiveFilterOrSearch: false,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      config: getEmptyStateConfig(this.intl, {
        ctaCallback: this.ctaCallback,
        legalCountry: this.legalCountry,
      }),
    });
  }

  @action
  ctaCallback() {
    this.segment.track('insurance-hub_empty-state_explore-insuraces-button_clicked');
    this.router.transitionTo('insurance-hub');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Table': typeof InsuranceHubTableComponent;
  }
}
