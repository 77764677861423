/* import __COLOCATED_TEMPLATE__ from './email-verification.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { authBaseURL } from 'qonto/constants/hosts';
// @ts-expect-error
import { ERRORS } from 'qonto/constants/sca';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface PopupSecurityEmailVerificationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PopupSecurityEmailVerificationComponent extends Component<PopupSecurityEmailVerificationSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare modals: Services['modals'];
  @service declare networkManager: Services['networkManager'];
  @service declare otpManager: Services['otpManager'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare scaManager: Services['scaManager'];
  @service declare sessionManager: Services['sessionManager'];
  @service declare userManager: Services['userManager'];
  @service declare sentry: Services['sentry'];
  @service declare errors: Services['errors'];

  // @ts-expect-error
  @tracked errorMessage;
  @service declare segment: Services['segment'];
  // @ts-expect-error
  @tracked verificationCode;

  isEmailFilledKnown = false;

  // @ts-expect-error
  @action updateVerificationCode(value) {
    this.verificationCode = value;
    if (this.errorMessage) {
      this.errorMessage = null;
    }
  }

  activateTask = dropTask(async () => {
    this.segment.track('profile_email-update_new-address_confirmed');
    let { verificationCode } = this;
    // @ts-expect-error
    let { close } = this.args;

    let response;
    try {
      // @ts-expect-error
      response = await this.sensitiveActions.runTask.perform(
        this.confirmNewEmailTask,
        verificationCode
      );
    } catch (error) {
      // @ts-expect-error
      let code = error?.errors?.[0]?.code;
      if (code === ERRORS.INVALID_CODE) {
        this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
        return;
      }
      await close();
      await this.modals.open('popup/security/sca/generic-error');
      throw error;
    }

    if (!response || response === 'cancel' || response === 'close') return;

    let result;
    if (response.current_user_auth_type) {
      let {
        current_user_auth_type: currentUserAuthType,
        current_user_auth_token: currentUserAuthToken,
      } = response;

      if (currentUserAuthType) {
        try {
          // @ts-expect-error
          result = await this.sensitiveActions.runTask.perform(this.validateNewUserTask, {
            verificationCode,
            currentUserAuthToken,
            currentUserAuthType,
          });
        } catch (error) {
          if (hasMFAError(error)) {
            this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
            return;
          }

          this.toastFlashMessages.toastError(
            // @ts-expect-error
            this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
          );

          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }

          // return if we encounter an error so the user can retry
          return;
        }
        this.isEmailFilledKnown = true;
      }
    }
    if (result === 'cancel') return;

    await close();
    // Handle the XConnect case
    // @ts-expect-error
    if (!this.userManager.currentUser.hasPassword) {
      Sentry.getCurrentScope().setUser(null);
      this.sessionManager.invalidate();
      return this.toastFlashMessages.toastInfo(this.intl.t('toasts.email_confirm_update'));
    }
    if (!this.isEmailFilledKnown) {
      this.segment.track('profile_email-update_success');
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.email_confirm_update'));
    } else {
      // @ts-expect-error
      this.userManager.redirectUponLogout = 'signin?isUpdateSessionModalShown=true';
      this.sessionManager.invalidate();
    }
  });

  confirmNewEmailTask = dropTask(async verificationCode => {
    // @ts-expect-error
    if (!this.isConfirmNewEmailHeader) {
      // @ts-expect-error
      this.scaManager.clearToken();
      // @ts-expect-error
      this.otpManager.otp.token = null;
      // @ts-expect-error
      this.isConfirmNewEmailHeader = true;
    }
    // @ts-expect-error
    let pendingEmail = this.userManager.currentUser.pendingEmail;
    let endpoint = `${authBaseURL}${'/v5/user/email_update/confirm_new_email'}`;

    let response = await this.networkManager.request(endpoint, {
      method: 'POST',
      data: JSON.stringify({
        pending_email_confirmation_code: verificationCode,
      }),
    });

    // @ts-expect-error
    this.userManager.currentUser.email = pendingEmail;
    return response;
  });

  validateNewUserTask = dropTask(
    async ({ verificationCode, currentUserAuthToken, currentUserAuthType }) => {
      // @ts-expect-error
      if (!this.isValidateNewUserHeader) {
        // @ts-expect-error
        this.scaManager.clearToken();
        // @ts-expect-error
        this.otpManager.otp.token = null;
        // @ts-expect-error
        this.isValidateNewUserHeader = true;
      }
      // @ts-expect-error
      let pendingEmail = this.userManager.currentUser.pendingEmail;
      let endpoint = `${authBaseURL}${'/v5/user/email_update/validate_new_user'}`;

      let response = await this.networkManager.request(endpoint, {
        method: 'POST',
        data: JSON.stringify({
          pending_email_confirmation_code: verificationCode,
          current_user_auth_token: currentUserAuthToken,
          current_user_auth_type: currentUserAuthType,
        }),
      });

      // @ts-expect-error
      this.userManager.currentUser.email = pendingEmail;
      return response;
    }
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Popup::Security::EmailVerification': typeof PopupSecurityEmailVerificationComponent;
  }
}
