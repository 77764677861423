/* import __COLOCATED_TEMPLATE__ from './bank-accounts-trigger.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BankAccountsTriggerSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const BankAccountsTrigger = templateOnlyComponent<BankAccountsTriggerSignature>();

export default BankAccountsTrigger;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::BankAccountsTrigger': typeof BankAccountsTrigger;
  }
}
