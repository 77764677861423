/* import __COLOCATED_TEMPLATE__ from './controls.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ControlsSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Controls = templateOnlyComponent<ControlsSignature>();

export default Controls;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Controls': typeof Controls;
  }
}
