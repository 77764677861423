/* import __COLOCATED_TEMPLATE__ from './loading.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

// @ts-expect-error
import styles from './loading.module.css';

interface OverviewWidgetsTransactionsLoadingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OverviewWidgetsTransactionsLoadingComponent extends Component<OverviewWidgetsTransactionsLoadingSignature> {
  styles = styles;
  placeholderBlock = SkeletonLoader.Block;
  placeholderLine = SkeletonLoader.Line;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::Transactions::Loading': typeof OverviewWidgetsTransactionsLoadingComponent;
  }
}
