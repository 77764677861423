/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import urlFromRouteInfo from 'qonto/utils/url-from-route-info';

interface OrgListItemLinkSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OrgListItemLinkComponent extends Component<OrgListItemLinkSignature> {
  @service declare router: Services['router'];

  get isActive() {
    // @ts-expect-error
    let { link } = this.args;

    if (!link || link.href) {
      return false;
    }

    let currentUrl = urlFromRouteInfo(this.router, this.router.currentRoute);
    return currentUrl.startsWith(`/organizations/${link.slug}/`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NavigationDropdown::OrgList::Item::Link': typeof OrgListItemLinkComponent;
  }
}
