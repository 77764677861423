/* import __COLOCATED_TEMPLATE__ from './bank-accounts-select.hbs'; */
import Component from '@glimmer/component';

interface BankAccountsSelectSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BankAccountsSelectComponent extends Component<BankAccountsSelectSignature> {
  get accounts() {
    // @ts-expect-error
    let { accounts, showExternalAccounts } = this.args;

    if (!accounts) return [];

    if (showExternalAccounts) {
      return accounts;
    }

    // @ts-expect-error
    return accounts.filter(account => !account.isExternalAccount);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::BankAccountsSelect': typeof BankAccountsSelectComponent;
  }
}
