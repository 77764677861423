/* import __COLOCATED_TEMPLATE__ from './section-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SectionItemSignature {
  Element: HTMLDivElement;
  Args: {
    size?: 'sm' | 'xs';
  };
  Blocks: {
    default: [];
  };
}

const SectionItem = templateOnlyComponent<SectionItemSignature>();

export default SectionItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Tooltip::Builder::SectionItem': typeof SectionItem;
  }
}
