/* import __COLOCATED_TEMPLATE__ from './attachments.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AttachmentsSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Attachments = templateOnlyComponent<AttachmentsSignature>();

export default Attachments;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Sidebar::Attachments': typeof Attachments;
  }
}
