/* import __COLOCATED_TEMPLATE__ from './q-file-uploader.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { isEqual } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import fade from 'ember-animated/transitions/fade';
import { UploadFile } from 'ember-file-upload';

const DEFAULT_EXTENSIONS = 'pdf,jpg,jpeg,png';
const DEFAULT_FILES_LIMIT = 5;
const DEFAULT_MAX_SIZE = 15 * 1e6;

interface QFileUploaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QFileUploaderComponent extends Component<QFileUploaderSignature> {
  @tracked isExpanded = false;
  @service declare segment: Services['segment'];
  transition = fade;

  @action
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.segment.track('transaction-details_show-more_clicked');
    }
  }

  // @ts-expect-error
  @action isInvoice(file) {
    return file?.documentType === 'invoice';
  }

  get sortedFiles() {
    // @ts-expect-error
    return (this.args.localFiles || []).sort((a, b) => {
      if (a.isProcessing || a instanceof UploadFile) return -1;
      if (b.isProcessing || b instanceof UploadFile) return 1;

      return b.createdAt - a.createdAt;
    });
  }

  get slicedFilesCollapsed() {
    return this.sortedFiles.slice(0, 3);
  }

  get slicedFilesExpanded() {
    return this.sortedFiles.slice(3);
  }

  get multipart() {
    // @ts-expect-error
    return this.args.multipart ?? false;
  }

  get extensions() {
    // @ts-expect-error
    return this.args.extensions ?? DEFAULT_EXTENSIONS;
  }

  get filesLimit() {
    // @ts-expect-error
    return this.args.filesLimit ?? DEFAULT_FILES_LIMIT;
  }

  get maxSize() {
    // @ts-expect-error
    return this.args.maxSize ?? DEFAULT_MAX_SIZE;
  }

  get minSize() {
    // @ts-expect-error
    return this.args.minSize ?? 0;
  }

  get bigLabel() {
    // @ts-expect-error
    return this.args.bigLabel ?? false;
  }

  get colors() {
    // @ts-expect-error
    return this.args.colors ?? true;
  }

  get disabled() {
    // @ts-expect-error
    return this.args.disabled ?? false;
  }

  get fixedSize() {
    // @ts-expect-error
    return this.args.fixedSize ?? false;
  }

  get multiple() {
    // @ts-expect-error
    return this.args.multiple ?? true;
  }

  get required() {
    // @ts-expect-error
    return this.args.required ?? false;
  }

  get requiredLocalFiles() {
    // @ts-expect-error
    return this.args.requiredLocalFiles ?? [];
  }

  get dropzoneComponent() {
    return this.multipart ? 'x-dropzone-multipart' : 'x-dropzone';
  }

  get fileComponent() {
    return this.multipart ? 'q-file-multipart' : 'q-file';
  }

  get deletable() {
    // @ts-expect-error
    return this.args.deletable ?? true;
  }

  get shouldShowDropdown() {
    // @ts-expect-error
    return file => {
      let isFileProcessing = file.isProcessing || file instanceof UploadFile;
      // @ts-expect-error
      return this.args.attachmentDropdown?.isVisible && !isFileProcessing && file.deletable;
    };
  }

  @action
  // @ts-expect-error
  isFileDeletable(file) {
    if (file.deletable === false) {
      return false;
    }
    return this.deletable;
  }

  @action
  // @ts-expect-error
  handleCancel(file) {
    // @ts-expect-error
    this.args.cancelLocalFile?.(file);
  }

  @action
  // @ts-expect-error
  isLocalFileRequired(file) {
    return (
      // @ts-expect-error
      Boolean(this.args.requiredAttachmentDisclaimer) ||
      // @ts-expect-error
      Boolean(this.requiredLocalFiles.find(requiredFile => isEqual(requiredFile, file)))
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QFileUploader: typeof QFileUploaderComponent;
  }
}
