/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { PERIOD_KEYS } from 'qonto/constants/bookkeeping';

interface MatchAttachmentPeriodSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MatchAttachmentPeriodSelectorComponent extends Component<MatchAttachmentPeriodSelectorSignature> {
  // @ts-expect-error
  @tracked dropDownState;
  @tracked customPeriodIsOpen = false;
  @service declare intl: Services['intl'];

  lastIndex = 0;

  get periodOptions() {
    return [
      {
        key: PERIOD_KEYS.THIS_MONTH,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.month'),
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.LAST_MONTH,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.last-month'),
        startDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      },
      {
        key: PERIOD_KEYS.CUSTOM_PERIOD,
        label: this.intl.t('bookkeeping.transactions.filters.option.period.custom.label'),
        startDate: null,
        endDate: null,
      },
    ];
  }

  get selectedIndex() {
    // @ts-expect-error
    return this.periodOptions.findIndex(option => option.key === this.args.selectedPeriod?.key);
  }

  @action
  // @ts-expect-error
  updateCustomPeriod({ startDate, endDate }) {
    let item = { ...this.customPeriodItem, startDate, endDate };
    // @ts-expect-error
    this.args.onChangePeriod(item);
    this.dropDownState?.actions.close();
  }

  @action
  cancelPeriodSelection() {
    this.closeCustomPeriod();
    // @ts-expect-error
    this.args.cancelPeriodSelection();
  }

  @action
  // @ts-expect-error
  selectItem(index) {
    let selectedItem = this.periodOptions[index];
    // @ts-expect-error
    if (this.isCustom(selectedItem.key)) {
      this.customPeriodIsOpen = true;
    } else {
      // @ts-expect-error
      this.args.onChangePeriod(selectedItem);
      this.dropDownState?.actions.close();
      this.resetLastIndex();
    }
  }

  @action
  getIndex() {
    return this.lastIndex++;
  }

  resetLastIndex() {
    this.lastIndex = 0;
  }

  @action
  closeCustomPeriod() {
    this.resetLastIndex();
    this.customPeriodIsOpen = false;
  }

  get customPeriodItem() {
    // @ts-expect-error
    return this.isCustom(this.args.selectedPeriod?.key)
      ? // @ts-expect-error
        this.args.selectedPeriod
      : this.periodOptions.find(option => this.isCustom(option.key));
  }

  // @ts-expect-error
  isCustom(key) {
    return key === PERIOD_KEYS.CUSTOM_PERIOD;
  }

  @action
  // @ts-expect-error
  registerDropdownState(dropDownState) {
    if (dropDownState) {
      this.closeCustomPeriod();
      this.resetLastIndex();
      this.dropDownState = dropDownState;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PeriodSelector::Selector': typeof MatchAttachmentPeriodSelectorComponent;
  }
}
