/* import __COLOCATED_TEMPLATE__ from './invoice-attachment.hbs'; */
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { bool } from 'macro-decorators';

import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';

interface InvoiceAttachmentSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvoiceAttachmentComponent extends Component<InvoiceAttachmentSignature> {
  errorState = ErrorState;

  // @ts-expect-error
  @bool('args.localFiles.0') hasFiles;

  get invoiceAttachmentDate() {
    // @ts-expect-error
    return dayjs(this.args.feeCreatedDate).endOf('month').add(1, 'day');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    InvoiceAttachment: typeof InvoiceAttachmentComponent;
  }
}
