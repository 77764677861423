export default {
  "header-cell": "cIS",
  "empty": "cIc",
  "header-content": "cIq caption-bold",
  "active": "cIZ",
  "align-right": "cIR",
  "first-col": "cIQ",
  "mid-col": "cIe",
  "status-col": "cIB"
};
