/* import __COLOCATED_TEMPLATE__ from './email-details.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface EmailDetailsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const EmailDetails = templateOnlyComponent<EmailDetailsSignature>();

export default EmailDetails;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::Form::EmailDetails': typeof EmailDetails;
  }
}
