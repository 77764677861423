/* import __COLOCATED_TEMPLATE__ from './item-card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ItemCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class ItemCardComponent extends Component<ItemCardSignature> {
  @service declare keyboardFocus: Services['keyboardFocus'];

  @tracked focused = false;

  @action
  // @ts-expect-error
  setFocused(focused) {
    this.focused = focused;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ItemCard: typeof ItemCardComponent;
  }
}
