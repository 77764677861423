/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

interface ProductsTableHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ProductsTableHeaderComponent extends Component<ProductsTableHeaderSignature> {
  checkbox = Checkbox;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Products::Table::Header': typeof ProductsTableHeaderComponent;
  }
}
