export default {
  "name-wrapper": "cgq",
  "preset-name": "cgZ",
  "invoices-entry-period-container": "cgR",
  "transactions-entry-period-container": "cgQ",
  "period-wrapper": "cge",
  "period-btn": "cgB",
  "cancel-placeholder": "cgE",
  "period-cancel-btn": "cgd",
  "transactions-entry-container": "cgI",
  "invoices-entry-container": "cgt",
  "dropdown": "cgM",
  "dropdown-opened": "cgP",
  "dropdown-closed": "cgl"
};
