/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import Component from '@glimmer/component';

import { EmptyStatesInform } from '@repo/domain-kit/pricing';

interface InContextIntegrationsSideDrawerBucketListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InContextIntegrationsSideDrawerBucketListComponent extends Component<InContextIntegrationsSideDrawerBucketListSignature> {
  emptyStatesInform = EmptyStatesInform;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::SideDrawer::Bucket::List': typeof InContextIntegrationsSideDrawerBucketListComponent;
  }
}
