export default {
  "header": "cFg",
  "top-section": "cFD",
  "close": "cFV btn btn--icon-only btn--tertiary",
  "container": "cFL",
  "picto": "cFT",
  "details": "cFz",
  "expiration-date": "cFa",
  "payment-method": "cFH"
};
